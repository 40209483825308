import React, { useState, useRef, useEffect } from 'react'
import { CgTag } from 'react-icons/cg'
import * as Portal from '@radix-ui/react-portal'
import { sortBy } from 'lodash'
import { usePopper } from 'common/hooks/usePopper'
import Tag from 'common/components/Tag/Tag'
import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { SuggestionBox } from '../SuggestionBox/SuggestionBox'
import { TagAddConfirmation } from './TagAddConfirmation'
import { getStandardizedName } from 'common/utils/stringUtils'
import { validFirebaseCharRegex } from 'common/utils/fileUploading/validators'

const keyCodes = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ESCAPE: 27,
  TAB: 9,
}

const getSuggestedTags = (textInput, suggestions, activeTags) => {
  const tagInputStd = getStandardizedName(textInput)

  let filtered = suggestions.filter((tag) => {
    const suggTagStd = getStandardizedName(tag)

    const hasText = textInput.length >= 1
    const tagAlreadyActive = activeTags?.includes(tag)
    const matchesText = suggTagStd?.includes(tagInputStd)

    if (hasText && !tagAlreadyActive && matchesText) {
      return true
    } else {
      return false
    }
  })

  const sorted = sortBy(filtered, (tag) => {
    const suggTagStd = getStandardizedName(tag)
    return suggTagStd.startsWith(tagInputStd) ? 0 : 1
  })

  return sorted
}

function TagInput({
  activeTags,
  tagsList = [],
  setActiveTags,
  onKeyUp,
  controlledInputRef,
  isCreateInput = true,
  placeholder = 'Add tag',
  id = 'tags',
  name = 'tags',
  type = 'tag',
  maxLength = 100,
  disabled = false,
  autoFocus = false,
}) {
  const [tagInputValue, setTagInputValue] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [createConfirmMessage, setCreateConfirmMessage] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)

  const uncontrolledInputRef = useRef()
  let inputRef = controlledInputRef ? controlledInputRef : uncontrolledInputRef

  const tagInputStd = getStandardizedName(tagInputValue)

  const suggestedTags = getSuggestedTags(tagInputValue, tagsList, activeTags)

  const handleAddTagOrShowConfirm = () => {
    const matchingActiveTags = activeTags.filter((tag) => getStandardizedName(tag) === tagInputStd)
    const matchingTagsList = tagsList.filter((tag) => getStandardizedName(tag) === tagInputStd)

    if (
      !activeTags.includes(tagInputValue.trim()) &&
      !tagsList.includes(tagInputValue.trim()) &&
      (matchingActiveTags.length > 0 || matchingTagsList.length > 0)
    ) {
      setCreateConfirmMessage(tagInputValue)
      setShowSuggestions(false)
      return
    }

    if (!activeTags.includes(tagInputValue.trim())) {
      setActiveTags([...activeTags, tagInputValue.trim()])
      setTagInputValue('')
    }
  }

  const handleTagInputKeyDown = (e) => {
    // We need event to prop cause we need to handle ENTER press higher to jump on next input
    // e.stopPropagation()
    const validFirebaseChar = validFirebaseCharRegex.test(e.key)
    if (!validFirebaseChar) {
      e.preventDefault()
    }

    if ((e.keyCode === keyCodes.ARROW_DOWN || e.keyCode === keyCodes.TAB) && suggestedTags.length > 0) {
      e.preventDefault()
      e.stopPropagation()
      setSelectedIndex((selectedIndex + 1) % suggestedTags.length)
    }

    if (e.keyCode === keyCodes.ARROW_UP) {
      e.preventDefault()
      setSelectedIndex(selectedIndex <= 0 ? suggestedTags.length - 1 : selectedIndex - 1)
    }

    if (e.keyCode === keyCodes.ESCAPE) {
      e.preventDefault()
      e.stopPropagation()
      setSelectedIndex(-1)
      setShowSuggestions(false)
    }

    if (e.keyCode === keyCodes.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      inputRef?.current?.focus()
    }

    if (e.keyCode === keyCodes.ENTER && selectedIndex !== -1) {
      e.preventDefault()
      e.stopPropagation()
      setActiveTags([...activeTags, suggestedTags[selectedIndex]])
      setTagInputValue('')
      inputRef?.current?.focus()
      return
    }

    if (e.keyCode === keyCodes.ENTER && tagInputStd.length) {
      e.preventDefault()

      if (!isCreateInput) {
        return
      }

      handleAddTagOrShowConfirm()
    }
  }

  const handleTagSelect = (tag) => {
    setShowSuggestions(false)
    setTagInputValue('')
    setActiveTags([...activeTags, tag])
    inputRef?.current?.focus()
    setCreateConfirmMessage('')
  }

  const handleTagRemove = (text) => {
    setTagInputValue('')
    setActiveTags(activeTags.filter((tag) => tag !== text))
    setCreateConfirmMessage('')
  }

  const handleChange = (e) => {
    setShowSuggestions(true)
    setTagInputValue(e.target.value)
    setCreateConfirmMessage('')
  }

  useEffect(() => {
    if (tagInputValue?.length > 0 && suggestedTags?.length > 0 && !isCreateInput) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(-1)
    }
    // eslint-disable-next-line
  }, [tagInputValue])

  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  return (
    <div className='flex items-center flex-wrap'>
      {activeTags && activeTags?.map((tag) => <Tag key={tag} text={tag} remove={() => handleTagRemove(tag)} />)}
      <div className='relative flex items-center mb-2'>
        <CgTag className='w-5 h-5 text-tBlack text-opacity-50 -rotate-45' />
        <div ref={popperTrigger}>
          <input
            ref={controlledInputRef ? controlledInputRef : uncontrolledInputRef}
            type='text'
            id={id}
            name={name}
            placeholder={placeholder}
            aria-label='Add tag'
            value={tagInputValue}
            onKeyDown={handleTagInputKeyDown}
            onKeyUp={onKeyUp}
            onChange={handleChange}
            maxLength={maxLength}
            disabled={disabled}
            className='border-0 ml-1 text-sm bg-transparent placeholder:text-tBlack placeholder:text-opacity-50 max-w-15 focus:ring-0 p-0'
            autoComplete='off'
            autoFocus={autoFocus}
          />
        </div>
        {showSuggestions && tagInputValue.length >= 1 && suggestedTags.length > 0 && (
          <Portal.Root ref={popperContainer}>
            <SuggestionBox
              suggestions={suggestedTags}
              handleSelect={handleTagSelect}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              scrollAreaClasses='!max-h-48'
              containerClasses='!w-52'
            />
          </Portal.Root>
        )}
        {isCreateInput &&
          showSuggestions &&
          tagInputValue?.length >= 1 &&
          suggestedTags.length === 0 &&
          !activeTags.includes(tagInputValue.trim()) && (
            <Portal.Root ref={popperContainer}>
              <SuggestionContainer containerClasses='!w-52'>
                <SuggestionItem active={true} onClick={handleAddTagOrShowConfirm}>
                  <span className='flex text-xxs text-gray-400'>Create new {type}</span>
                  {tagInputValue}
                </SuggestionItem>
              </SuggestionContainer>
            </Portal.Root>
          )}
      </div>
      {isCreateInput && createConfirmMessage.length > 0 && (
        <TagAddConfirmation
          type={type}
          handleConfirm={() => handleTagSelect(tagInputValue.trim())}
          handleDecline={() => {
            setCreateConfirmMessage('')
            setTagInputValue('')
          }}
        />
      )}
    </div>
  )
}

export default TagInput
