import { isEmpty } from 'lodash'
import { videoTitleErrKey } from './VideoResourceCard'
import { linkTitleErrKey, linkUrlErrKey, urlSchema, linkImgErrKey } from './LinkResourceCard'

export const getInvalidResources = (resources) => {
  let invalidResources = {}

  Object.entries(resources).forEach(([resourceId, resource]) => {
    if (resource.type === 'video' && !resource.videoId) {
      invalidResources[resourceId] = { [videoTitleErrKey]: 'Video is required' }
    } else if (resource.type === 'link') {
      let resourceErrors = {}
      if (resource.title.trim().length === 0) {
        resourceErrors[linkTitleErrKey] = 'Title is required'
      }

      try {
        urlSchema.validateSync(resource.url)
      } catch (error) {
        resourceErrors[linkUrlErrKey] = error.message
      }

      if (resource.previewImg && resource.previewImg === 'uploading') {
        resourceErrors[linkImgErrKey] = 'Image is uploading'
      } else if (resource.previewImg) {
        delete resourceErrors[linkImgErrKey]
      }

      if (!isEmpty(resourceErrors)) {
        invalidResources[resourceId] = resourceErrors
      } else {
        delete invalidResources[resourceId]
      }
    }
  })

  return invalidResources
}
