import 'twin.macro'
import React, { useState, useEffect } from 'react'
import { Combobox } from '@headlessui/react'
import { useFormContext } from 'react-hook-form'

import { inputBase } from 'common/components/Input/Input'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { usePopper } from 'common/hooks/usePopper'
import { ProgressionInputOptions } from './ProgressionInputOptions'
import { validFirebaseCharRegex } from 'common/utils/fileUploading/validators'

export const ProgressionInput = React.memo(
  ({ value, onSelect, availableProgressions, onKeyUp, onFocus, inputRefsSortMethod, inputRef, autoFocus }) => {
    const [selectedProgressionId, setSelectedProgressionId] = useState(value)
    const [inputText, setInputText] = useState(availableProgressions?.[value] || '') //Displays program name, used for search
    const [errorText, setErrorText] = useState(null)
    const { watch } = useFormContext()
    const watchCues = watch('cues')
    const { inputRefs, addInputRef } = useFormRefsControl()

    const handleSelect = (progressionKey, e) => {
      const validProgressionKey = validFirebaseCharRegex.test(progressionKey)
      if (!validProgressionKey) {
        setErrorText('Please avoid special characters such as . [ ] : ; $ # /')
        return
      }
      setSelectedProgressionId(progressionKey)
      onSelect(progressionKey)
    }

    useEffect(() => {
      const isInputInControlAlready = inputRefs.some((inRef) => inRef.ref.current === inputRef.current)
      if (!isInputInControlAlready) {
        addInputRef({
          ref: inputRef,
          posIdx: watchCues.length + 1,
          name: 'progression',
          exIdx: 0,
          sortMethod: inputRefsSortMethod,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let [popperTrigger, popperContainer] = usePopper({
      placement: 'bottom-start',
      strategy: 'fixed',
    })

    return (
      <div className='relative flex px-4 md:px-10'>
        <Combobox value={selectedProgressionId} onChange={handleSelect}>
          <div ref={popperTrigger} className='w-full'>
            <Combobox.Input
              ref={(e) => {
                inputRef.current = e
              }}
              name='progression'
              placeholder={`Enter progression name`}
              autoComplete='off'
              displayValue={(progressionKey) => progressionKey || ''}
              css={[inputBase]}
              onKeyDown={(e) => {
                setErrorText(null)
                const validFirebaseChar = validFirebaseCharRegex.test(e.key)
                if (!validFirebaseChar) {
                  e.preventDefault()
                }
              }}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
              onChange={(event) => {
                setInputText(event.target.value)
              }}
              autoFocus={autoFocus}
            />
            {Boolean(errorText) && <p className='flex text-xs mt-1 text-tRed'>{errorText}</p>}
          </div>
          {inputText?.length > 0 && (
            <ProgressionInputOptions
              value={inputText}
              availableProgressions={availableProgressions}
              inputRef={inputRef}
              popperContainer={popperContainer}
            />
          )}
        </Combobox>
      </div>
    )
  }
)
