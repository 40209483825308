import tw from 'twin.macro'
import logo from 'assets/images/TrybeLogo.png'
import { Link, useLocation, useMatch } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'
import { some } from 'lodash'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

//PARENT PATHS
const PROGRAMS = '/programs'
const EXERCISES = '/exercises'
const LAYOUT = '/layout'
const VIDEOS = '/videos'

//CHILD PATHS
const PROGRAMGROUPS = '/series'
const STANDALONES = '/standalones'
const PROGRESSIONS = '/progressions'
// const UPLOAD = '/upload'
const RESOURCES = '/resources'
const FEATUREDRESOURCES = '/featured'

//Child-to-Parent path mapping
const programSubRoutes = [PROGRAMS, PROGRAMGROUPS, STANDALONES]
const exerciseSubRoutes = [
  EXERCISES,
  PROGRESSIONS,
  // UPLOAD
]
const layoutSubRoutes = [LAYOUT, FEATUREDRESOURCES, RESOURCES]
const videoSubRoutes = [VIDEOS]

const greenUnderline = `underline underline-offset-8 decoration-2 decoration-tGreen`
const activeRoute = `text-sm md:text-lg font-semibold text-tGray-dark underline ${greenUnderline}`
const inactiveRoute = 'text-sm md:text-lg font-semibold text-tGray-med hover:text-tGreen'
const activeSubroute = `flex text-sm md:text-base items-center px-3 py-2 md:py-3 font-medium relative focus:outline-0 focus:cursor-pointer ${greenUnderline}`
const inactiveSubroute = `flex text-sm md:text-base items-center px-3 py-2 md:py-3 font-medium relative focus:outline-0 focus:cursor-pointer focus:bg-gray-100`
const dropdownContent = 'w-auto md:w-80 bg-white rounded-lg shadow-md border border-gray-100 mt-2 py-3 md:py-4 px-1'
const hiddenOnMobileClasses = 'hidden md:block'

function Header(props) {
  const location = useLocation() //Used to check if user is in "Home" page, which is the logo and therefore all routes should be colored as active
  const isProgramView = useMatch(`${PROGRAMS}/:programId`)

  //Add paths that should not have a header here
  if (location.pathname === '/signup') {
    return null
  }

  return (
    <div
      css={[
        tw`flex h-[60px] md:h-[80px] py-4 md:py-10 bg-white items-center sticky top-0 z-[100] shadow-sm`,
        isProgramView && tw`absolute inset-x-0`,
      ]}
    >
      <Link to='/' className='flex flex-row items-center ml-4 mr-2 md:mr-5 md:ml-5'>
        <img src={logo} height='40px' width='40px' alt='Trybe logo' />
      </Link>
      <div className='flex space-x-4'>
        <LayoutPaths location={location} />
        <ProgramPaths location={location} />
        <ExercisePaths location={location} />
        <VideoPaths location={location} />
      </div>
      <TopRightLinks />
    </div>
  )
}

function ProgramPaths({ location }) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={`focus:outline-0 focus:ring-0 focus:ring-offset-0 ${hiddenOnMobileClasses}`}>
        <div className={isActiveParent(location, PROGRAMS) ? activeRoute : inactiveRoute}>Programs</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={dropdownContent} align='start'>
        {/* <DropdownMenu.Item className={inactiveSubroute}>{'All Programs (Coming Soon)'}</DropdownMenu.Item> */}
        <div className='space-y-0 md:space-y-1'>
          {createDropdownLink(PROGRAMS, 'All Programs', location)}
          {createDropdownLink(PROGRAMGROUPS, 'Series', location)}
        </div>
        {/* {createDropdownLink(STANDALONES, 'Standalone Workouts', location)} */}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

function ExercisePaths({ location }) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className='focus:outline-0 focus:ring-0 focus:ring-offset-0'>
        <div className={isActiveParent(location, EXERCISES) ? activeRoute : inactiveRoute}>Exercises</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={dropdownContent} align='start'>
        <div className='space-y-0 md:space-y-1'>
          {createDropdownLink(EXERCISES, 'Exercise Library', location)}
          {createDropdownLink(PROGRESSIONS, 'Progressions', location)}
        </div>
        {/* {createDropdownLink(UPLOAD, 'Bulk Upload', location)} */}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

function LayoutPaths({ location }) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={`focus:outline-0 focus:ring-0 focus:ring-offset-0 ${hiddenOnMobileClasses}`}>
        <div className={isActiveParent(location, LAYOUT) ? activeRoute : inactiveRoute}>App Layout</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={dropdownContent} align='start'>
        {createDropdownLink(LAYOUT, 'App Layout', location)}
        {createDropdownLink(FEATUREDRESOURCES, 'Featured Resources', location)}
        {createDropdownLink(RESOURCES, 'Additional Resources', location)}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

function VideoPaths({ location }) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className='focus:outline-0 focus:ring-0 focus:ring-offset-0'>
        <div className={isActiveParent(location, VIDEOS) ? activeRoute : inactiveRoute}>Videos</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={dropdownContent} align='start'>
        <div className='space-y-0 md:space-y-1'>{createDropdownLink(VIDEOS, 'Full Length Videos', location)}</div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

function TopRightLinks(props) {
  return (
    <div className='flex flex-row ml-auto mr-4 md:mr-5 items-center space-x-2'>
      {/* <Link to='/help'>
        <FiHelpCircle className='w-6 h-6 text-tGray-med' />
      </Link> */}
      <Link to='/account'>
        <FaUser className='w-6 h-6 text-tGray-med' />
      </Link>
    </div>
  )
}

function createDropdownLink(path, text, location, hiddenOnMobile = false) {
  return (
    <Link to={path} className={hiddenOnMobile ? hiddenOnMobileClasses : 'block'}>
      <DropdownMenu.Item className={isActiveChild(location, path) ? activeSubroute : inactiveSubroute}>
        {text}
      </DropdownMenu.Item>
    </Link>
  )
}

function isActiveParent(location, parentPath) {
  if (!location?.pathname || !parentPath) return false
  const currentLocation = location?.pathname

  if (parentPath === PROGRAMS) {
    return some(programSubRoutes, (route) => currentLocation.startsWith(route))
  } else if (parentPath === EXERCISES) {
    return some(exerciseSubRoutes, (route) => currentLocation.startsWith(route))
  } else if (parentPath === LAYOUT) {
    return some(layoutSubRoutes, (route) => currentLocation.startsWith(route))
  } else if (parentPath === VIDEOS) {
    return some(videoSubRoutes, (route) => currentLocation.startsWith(route))
  }

  return false
}

function isActiveChild(location, path) {
  if (!location?.pathname || !path) return false

  return location.pathname.startsWith(path)
}

export default Header
