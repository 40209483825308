import tw from 'twin.macro'

import { CgImage } from 'react-icons/cg'

import { VideoModal } from 'common/components/VideoModal/VideoModal'
import { Spinner } from 'common/components/Spinner/Spinner'
import { TableCell } from 'modules/ExerciseLibrary/ExerciseTable/styles'
import { getThumbnail } from 'common/utils/thumbnailUtils'
import { getLandOrPortExThumb, getLandOrPortExVid } from 'common/utils/exerciseUtils'

export function PreviewImgCell({ isUploading, exercise, children, exVidOrientation }) {
  if (isUploading) {
    return (
      <TableCell css={tw`relative pl-4 md:pl-10`}>
        {children}
        <div
          css={[
            tw`relative w-14 md:w-20 h-14 md:h-20 rounded-lg md:rounded-xl overflow-hidden bg-tBlack`,
            exVidOrientation === 'portrait' && tw`w-14 md:w-16 h-20 md:h-24 rounded-lg`,
          ]}
        >
          <div className='w-full h-full flex items-center justify-center'>
            <Spinner className='w-5 h-5 text-gray-100 opacity-50' fillClassName='fill-tBlack opacity-50' />
          </div>
        </div>
      </TableCell>
    )
  }

  const exThumbWidth = exVidOrientation === 'landscape' ? 80 : 64
  const exThumbHeight = exVidOrientation === 'landscape' ? 80 : 96

  const videoUrl = getLandOrPortExVid(exVidOrientation, exercise)
  const thumbnail = getThumbnail({
    thumbnailUrl: getLandOrPortExThumb(exVidOrientation, exercise),
    videoUrl: videoUrl,
    options: { width: exThumbWidth * 1.5, height: exThumbHeight * 1.5 },
  })

  if (thumbnail) {
    return (
      <TableCell css={tw`relative pl-4 md:pl-10`}>
        {children}
        <div
          css={[
            tw`relative w-14 md:w-20 h-14 md:h-20 rounded-lg md:rounded-xl overflow-hidden bg-tBlack`,
            exVidOrientation === 'portrait' && tw`w-14 md:w-16 h-20 md:h-24 rounded-lg`,
          ]}
          onClick={(e) => e.stopPropagation()}
        >
          <img src={thumbnail} alt={exercise.name} className='w-full h-full object-cover' />
          {videoUrl && <VideoModal videoName={exercise.name} videoUrl={videoUrl} exVidOrientation={exVidOrientation} />}
        </div>
      </TableCell>
    )
  }

  return (
    <TableCell css={tw`relative pl-4 md:pl-10`}>
      {children}
      <div
        css={[
          tw`relative w-14 md:w-20 h-14 md:h-20 rounded-lg md:rounded-xl overflow-hidden bg-tBlack`,
          exVidOrientation === 'portrait' && tw`w-14 md:w-16 h-20 md:h-24 rounded-lg`,
        ]}
      >
        <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-7 h-7' />
      </div>
    </TableCell>
  )
}
