import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import tw, { css } from 'twin.macro'
import * as Popover from '@radix-ui/react-popover'
import { CgMoreAlt, CgMic, CgPen, CgTrash } from 'react-icons/cg'
import { TbVideo, TbVideoOff } from 'react-icons/tb'
import { format } from 'date-fns'

import { useWindowSize } from 'common/hooks/useWindowSize'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useDuplicateExerciseMutation,
  useRemoveExerciseMutation,
  useListenExLibEnabledQuery,
} from '../exerciseLibraryApi'
import { useSaveProgressionMutation } from 'modules/Progressions/progressionsApi'
import { exerciseSelected, exercisesMultiSelected, getIsExSelected, newExerciseViewed } from '../exerciseLibrarySlice'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { TableCell } from 'modules/ExerciseLibrary/ExerciseTable/styles'
import { CueCell } from 'modules/ExerciseLibrary/ExerciseTable/Cells/CueCell'
import { PreviewImgCell } from 'modules/ExerciseLibrary/ExerciseTable/Cells/PreviewImgCell'
import { DeleteDialogBanner } from 'common/components/DeleteDialogBanner/DeleteDialogBanner'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { capitalizeFirstLetterOfWords } from 'common/utils/stringUtils'
import { getLandOrPortExVid } from 'common/utils/exerciseUtils'
import { NewExerciseTag } from '../components/NewExerciseTag'
import { getIsNewExercise } from '../exerciseLibrarySlice'
import { uploadingExerciseCancelled } from '../uploadingExercisesSlice'

function TableRow({
  exerciseKey,
  currPageExercises,
  exercise,
  isAnyExSelected,
  progressions,
  setModalEx,
  setEditDialogOpen,
}) {
  const [width] = useWindowSize()
  const { userId } = useAuth()
  const { exVidOrientation } = useCustomization()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const dispatch = useDispatch()

  const { data: exLibEnabledData } = useListenExLibEnabledQuery({ coachOrgId })
  const exLibEnabled = exLibEnabledData === true

  const [duplicateExercise] = useDuplicateExerciseMutation()
  const [removeExercise] = useRemoveExerciseMutation()
  const [saveProgression] = useSaveProgressionMutation()
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: exerciseKey })

  const currentExerciseProgression =
    progressions !== null ? Object.entries(progressions).find(([_key, value]) => value.includes(exerciseKey)) : []
  const [progressionKey, progressionExercises] = currentExerciseProgression || []

  const rowActionContainerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isFixedOptions, setIsFixedOptions] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { createAlert } = useAlert()
  const newExerciseInfo = useSelector((state) => getIsNewExercise(state, exerciseKey))
  const isExSelected = useSelector((state) => getIsExSelected(state, exerciseKey))

  const handleDelete = async () => {
    setLoading(true)
    await removeExercise({ coachOrgId, exerciseKey })

    if (progressionKey) {
      const updatedExercises = progressionExercises.filter((exercise) => exercise !== exerciseKey)
      await saveProgression({ coachOrgId, progressionKey, exercises: updatedExercises })
    }
    dispatch(uploadingExerciseCancelled({ exerciseId: exerciseKey }))
    if (isExSelected) {
      dispatch(exerciseSelected({ exId: exerciseKey }))
    }
    setLoading(false)
    createAlert({ text: 'Exercise deleted!', type: 'success' })
  }

  const setExerciseAsViewed = () => {
    if (newExerciseInfo) {
      dispatch(newExerciseViewed({ exId: exerciseKey }))
    }
  }

  const handleRowClick = (e) => {
    // Check that event did not happen in modal
    const occuredOutsideModal = e.currentTarget.contains(e.target)

    // Check that event did not happen in action row (edit/delete/more)
    const occuredOutsideActionRow = !rowActionContainerRef.current.contains(e.target)
    if (occuredOutsideModal && occuredOutsideActionRow) {
      handleOpenEditDialog()
    }
  }

  const handleOpenEditDialog = () => {
    setExerciseAsViewed()
    setModalEx({ exercise, exerciseKey })
    setEditDialogOpen(true)
  }

  const handleExDuplicate = async () => {
    await duplicateExercise({ coachOrgId, exercise })
    createAlert({ text: 'Exercise duplicated!', type: 'success' })
  }

  const isUploading = isUploadingAssets(assetDrafts)

  return (
    <tr
      className='group'
      css={[tw`cursor-pointer h-24 hover:bg-gray-50`, isFixedOptions && tw`bg-gray-50`]}
      onClick={handleRowClick}
      aria-label='table-row'
    >
      <PreviewImgCell isUploading={isUploading} exercise={exercise} exVidOrientation={exVidOrientation}>
        <input
          type='checkbox'
          onClick={(e) => {
            e.stopPropagation()
            if (!e.shiftKey) {
              dispatch(exerciseSelected({ exId: exerciseKey }))
            } else if (e.shiftKey) {
              dispatch(exercisesMultiSelected({ exSelectToId: exerciseKey, currPageExEntries: currPageExercises }))
            }
          }}
          value={isExSelected ? 'on' : 'off'}
          className='group-hover:visible -translate-y-1/2 hidden md:block'
          css={[
            tw`invisible absolute w-[18px] h-[18px] left-[11px] top-1/2 cursor-pointer rounded-sm border-tBlack border-opacity-20 text-tGreen focus:ring-0`,
            isAnyExSelected && tw`visible`,
          ]}
          checked={isExSelected ? true : false}
          readOnly
        />
      </PreviewImgCell>
      <TableCell tw='w-auto md:w-[350px] pl-0 md:pl-6'>
        <div className='flex flex-col'>
          <div className='flex flex-row w-auto md:w-[350px]'>
            <div
              className='font-bold text-tBlack capitalize truncate'
              data-testid='table-exercise-title'
              style={{ maxWidth: width && width <= 768 ? parseInt(width / 1.7) : 330 }}
            >
              {exercise.name}
            </div>
            {newExerciseInfo?.showTag ? <NewExerciseTag /> : null}
          </div>
          {exLibEnabled && exercise.level ? (
            <div className='text-sm text-tGray-dark'>Level {exercise.level}</div>
          ) : null}
        </div>
      </TableCell>
      <TableCell tw='w-[110px]' hideOnMobile>
        <div className='flex flex-row w-[110px] items-center justify-center'>
          {getLandOrPortExVid(exVidOrientation, exercise) ? (
            <TbVideo className='inline-flex w-6 h-6 text-tGreen' />
          ) : (
            <TbVideoOff className='inline-flex w-6 h-6 text-gray-400' />
          )}
          {exVidOrientation !== 'portrait' && (
            <CgMic className={`inline-flex w-6 h-6 ml-4 ${exercise.audio ? 'text-tGreen' : 'text-tGray-light'}`} />
          )}
        </div>
      </TableCell>
      <CueCell exercise={exercise} hideOnMobile={true} />
      <TableCell tw='w-[220px] text-sm' hideOnMobile>
        {getFormattedDate(exercise)}
      </TableCell>
      <td className='relative'>
        <div
          ref={rowActionContainerRef}
          className='absolute top-1/2 -translate-y-1/2 right-3 inline-flex items-center justify-end bg-white group-hover:border group-hover:border-tBlack group-hover:border-opacity-20 rounded-lg group-hover:shadow-sm'
          css={[isFixedOptions && tw`border border-tBlack border-opacity-20 shadow-sm`]}
        >
          <button
            aria-label='Edit Row'
            className='hidden group-hover:inline-block hover:text-tGreen py-1 px-2 border-r border-tBlack border-opacity-20 transition-all'
            onClick={handleOpenEditDialog}
          >
            <CgPen className=' w-5 h-5' />
          </button>
          <Dialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen}>
            <DialogTrigger
              onOpenCb={() => setExerciseAsViewed()}
              className='hidden group-hover:inline-block hover:text-tRed py-1 px-2 border-r border-tBlack border-opacity-20 transition-all disabled:cursor-not-allowed'
              aria-label='Delete Row'
            >
              <CgTrash className='w-5 h-5' />
            </DialogTrigger>
            <DialogContent>
              <DeleteDialogBanner
                text={`This will delete ${capitalizeFirstLetterOfWords(exercise.name)}`}
                loading={loading}
                handleDelete={() => handleDelete()}
              />
            </DialogContent>
          </Dialog>
          <Popover.Root onOpenChange={(isOpen) => setIsFixedOptions(isOpen)}>
            <Popover.Trigger className='py-1 px-2'>
              <CgMoreAlt
                className='w-5 h-5 hover:text-tGreen'
                onClick={() => setIsFixedOptions(true)}
                aria-label='More'
              />
            </Popover.Trigger>
            <Popover.Content align='end' css={[PopoverContetStyles]}>
              <Popover.Arrow className='fill-white' />
              <Popover.Close css={[PopoverContetButtonStyles]} onClick={handleOpenEditDialog} aria-label='More-Edit'>
                Edit
              </Popover.Close>
              <Popover.Close
                css={[PopoverContetButtonStyles]}
                onClick={handleExDuplicate}
                aria-label='More-Duplicate'
                disabled={isUploading}
              >
                Duplicate
              </Popover.Close>
              <Popover.Close
                css={[PopoverContetButtonStyles, tw`text-tRed`]}
                onClick={() => setDeleteDialogOpen(true)}
                aria-label='More-Delete'
              >
                Delete
              </Popover.Close>
            </Popover.Content>
          </Popover.Root>
        </div>
      </td>
    </tr>
  )
}

function getFormattedDate(exercise) {
  if (!exercise.updatedAt && !exercise.createdAt) {
    return 'No date'
  }

  return format(exercise.updatedAt || exercise.createdAt, 'PP')
}

const PopoverContetStyles = css`
  ${tw`
    flex
    flex-col
    bg-white
    mt-1
    py-1.5
    px-1.5
    shadow-md
    rounded-xl
    w-28
  `}

  > span {
    bottom: 95% !important;
    right: 10px !important;
  }
`

const PopoverContetButtonStyles = tw`
  text-sm
  flex
  justify-start
  w-full
  mb-0.5
  py-0.5
  px-2
  rounded-lg
  last:mb-0
  hover:bg-gray-100
  disabled:cursor-not-allowed
`

export default TableRow
