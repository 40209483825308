import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { sortBy } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensors, useSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'

import { Sortable } from 'common/components/Sortable/Sortable'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'
import { dropAnimation } from 'common/utils/dndUtils'

import { AddResourcePopover, addLinkBtnId, addVideoBtnId } from './AddResourcePopover'
import { VideoResourceCard } from './VideoResourceCard'
import { NoResourcesCard } from './NoResourcesCard'
import { LinkResourceCard } from './LinkResourceCard'
import { CardDragOverlay } from './CardDragOverlay'

export const initialInputRefsSortMethod = ['addResource', 'submit']

export function ResourcesForm({ onSubmit, submitRef, resourceErrors, setResourceErrors, programId }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: videoData, isLoading } = useListenCoachVideosQuery({ coachOrgId })
  const videosLoading = isLoading || videoData?.isLoading
  const videos = videosLoading || videoData === null ? {} : videoData

  const methods = useFormContext()
  const { setValue, handleSubmit, watch, clearErrors, register } = methods

  const watchProgramResources = watch('programResources') //new resources format: JS object with UID keys
  const programResourcesEntries = sortBy(Object.entries(watchProgramResources), ([_, resource]) => resource.idx)

  // ref control
  const { addInputRef, moveFocusOnKeyPress, removeInputRef, setLastKnownSortMethod } = useFormRefsControl()
  const addResourceRef = useRef()

  useEffect(() => {
    const resourceRefsSortMethod = programResourcesEntries.map(([resourceId, _]) => `resource-${resourceId}`)
    const newInputRefsSortMethod = [...resourceRefsSortMethod, ...initialInputRefsSortMethod]

    addInputRef({
      ref: addResourceRef,
      name: 'addResource',
      sortMethod: newInputRefsSortMethod,
    })

    addInputRef({
      ref: submitRef,
      name: 'submit',
      sortMethod: newInputRefsSortMethod,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Drag and drop
  const [activeDragItem, setActiveDragItem] = useState(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = ({ active }) => {
    const activeResourceIndex = programResourcesEntries.findIndex(([resourceId, _]) => resourceId === active.id)
    const activeResource = watchProgramResources[active.id]

    const videoTitle = videos[activeResource.videoId]?.title || 'Untitled video'
    const linkTitle = activeResource?.title || 'Untitled link'
    setActiveDragItem({ index: activeResourceIndex, title: activeResource.type === 'video' ? videoTitle : linkTitle })
  }

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = programResourcesEntries.findIndex(([resourceId, _]) => resourceId === active.id)
      const newIndex = programResourcesEntries.findIndex(([resourceId, _]) => resourceId === over.id)

      const updatedResourceEntries = arrayMove(programResourcesEntries, oldIndex, newIndex)

      const resourcesUpdate = {}
      updatedResourceEntries.forEach(([resourceId, resource], idx) => {
        resourcesUpdate[resourceId] = { ...resource, idx }
      })

      setValue('programResources', resourcesUpdate)
      setLastKnownSortMethod((sortMethod) => {
        const newSortMethod = arrayMove(sortMethod, oldIndex, newIndex)
        return [...newSortMethod]
      })
      setActiveDragItem(null)
    }
  }

  useEventListener('keydown', (e) => {
    if (e.target.name === 'addResource' && e.code === 'Enter' && !e.shiftKey) {
      return
    }

    if (e.target.id === addLinkBtnId) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        document.getElementById(addVideoBtnId)?.focus()
      }
      return
    }

    if (e.target.id === addVideoBtnId) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        document.getElementById(addLinkBtnId)?.focus()
      }
      return
    }

    moveFocusOnKeyPress(e, handleSubmit(onSubmit))
  })

  function handleDelete(id, inputRefs) {
    const filteredProgramResources = programResourcesEntries.filter(([resourceId, resource]) => resourceId !== id)
    const updatedResources = {}

    filteredProgramResources.forEach(([resourceId, resource], idx) => {
      updatedResources[resourceId] = { ...resource, idx }
    })

    setValue('programResources', updatedResources)

    setResourceErrors((errors) => {
      let errorsUpdate = errors
      delete errorsUpdate[id]
      return { ...errorsUpdate }
    })
    inputRefs.forEach((ref) => removeInputRef(ref))
  }

  if (Object.keys(watchProgramResources).length === 0) {
    return (
      <div className='flex flex-col px-10 py-4'>
        <NoResourcesCard
          addResourceRef={addResourceRef}
          setValue={setValue}
          programResourcesEntries={programResourcesEntries}
        />
      </div>
    )
  }

  return (
    <div className='flex flex-col px-10 py-4'>
      <h3 className='inline-flex font-semibold text-tBlack mb-2'>Extra resources</h3>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={programResourcesEntries} strategy={verticalListSortingStrategy}>
          {programResourcesEntries.map(([resourceId, resource], index) =>
            resource.type === 'video' ? (
              <Sortable
                key={resourceId}
                id={resourceId}
                css={tw`rounded-md mb-2`}
                draggingClasses='opacity-50 ring-2 ring-tGreen z-10'
                withHandle={true}
                variableSize={true}
              >
                <VideoResourceCard
                  index={index}
                  programResourcesEntries={programResourcesEntries}
                  resource={resource}
                  setValue={setValue}
                  videos={videos}
                  handleDelete={(inputRefs) => handleDelete(resource.id, inputRefs)}
                  setResourceErrors={setResourceErrors}
                  error={resourceErrors[resource.id] || {}}
                />
              </Sortable>
            ) : (
              <Sortable
                key={resource.id}
                id={resource.id}
                css={tw`rounded-md mb-2`}
                draggingClasses='opacity-50 ring-2 ring-tGreen z-10'
                withHandle={true}
                variableSize={true}
              >
                <LinkResourceCard
                  index={index}
                  programResourcesEntries={programResourcesEntries}
                  resource={resource}
                  setValue={setValue}
                  handleDelete={(inputRefs) => handleDelete(resource.id, inputRefs)}
                  setResourceErrors={setResourceErrors}
                  error={resourceErrors[resource.id] || {}}
                  coachOrgId={coachOrgId}
                  clearErrors={clearErrors}
                  register={register}
                  programId={programId}
                />
              </Sortable>
            )
          )}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          className='cursor-move'
          dropAnimation={dropAnimation}
          modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
        >
          {activeDragItem && <CardDragOverlay activeDragItem={activeDragItem} />}
        </DragOverlay>
      </DndContext>
      <AddResourcePopover
        addResourceRef={addResourceRef}
        setValue={setValue}
        programResourcesEntries={programResourcesEntries}
      />
    </div>
  )
}
