import React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

export function Tooltip({ children, content, open, defaultOpen, onOpenChange, triggerClasses, ...otherProps }) {
  const defaultClasses = 'bg-tBlack text-white leading-none text-sm px-2 py-2 rounded-md'

  return (
    <TooltipPrimitive.Provider delayDuration={400} skipDelayDuration={400}>
      <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
        <TooltipPrimitive.Trigger asChild>
          <span className={triggerClasses ? triggerClasses : ''} tabIndex={-1}>
            {children}
          </span>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content side='top' align='center' className={defaultClasses} {...otherProps}>
          {content}
          <TooltipPrimitive.Arrow offset={6} width={11} height={5} />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
