import React, { useState } from 'react'
import { timeAgo } from 'modules/Programs/utils/timeUtils'

export function TextHint({ hintText, hideText = 'Hide hint', expandedText, updatedAt, updatedAtText }) {
  const [isHintExpanded, setIsHintExpanded] = useState(false)

  if (!hintText || !expandedText) {
    console.warn('TextHint setup missing parameters')
    return null
  }

  return (
    <>
      <button
        type='button'
        onClick={() => setIsHintExpanded(!isHintExpanded)}
        className='italic text-xs text-gray-400 underline underline-offset-2 hover:text-tGreen'
        aria-label={isHintExpanded ? 'hint-hide' : 'hint-show'}
      >
        {isHintExpanded ? <span>{hideText}</span> : <span>{hintText}</span>}
      </button>
      {isHintExpanded && (
        <div data-testid='expanded-hint-text'>
          <p className='italic text-xs text-gray-400 leading-relaxed'>
            <span>{expandedText}</span>
            {updatedAt && updatedAtText && (
              <span>
                {` `}
                {updatedAtText}
                <strong>{timeAgo(updatedAt)}</strong>
                {`.`}
              </span>
            )}
          </p>
        </div>
      )}
    </>
  )
}
