import { useState } from 'react'
import { FaPlay } from 'react-icons/fa'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { EmptyStateContainer } from 'common/components/EmptyStateContainer/EmptyStateContainer'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'

export function VideoModal({ videoName, videoUrl, exVidOrientation }) {
  const [videoDialogOpen, setVideoDialogOpen] = useState(false)

  return (
    <Dialog open={videoDialogOpen} setOpen={setVideoDialogOpen}>
      <DialogTrigger className='absolute inset-0 hidden items-center justify-center bg-tBlack bg-opacity-30 group-hover:flex'>
        <FaPlay className='w-5 h-5 text-white' />
      </DialogTrigger>
      <DialogContent
        contentClassNames='!bg-transparent !w-auto !max-w-7xl min-w-[70%]'
        closeClassNames='!fixed bg-gray-200 hover:!bg-gray-200 hover:!bg-opacity-100 hover:text-black'
        overlayClassNames='!bg-opacity-80 !bg-black'
        contentProps={{
          onPointerDownOutside: () => {
            setVideoDialogOpen(false)
          },
        }}
      >
        <VideoViewer videoName={videoName} videoUrl={videoUrl} exVidOrientation={exVidOrientation} />
      </DialogContent>
    </Dialog>
  )
}

function VideoViewer({ videoName, videoUrl, exVidOrientation }) {
  const [error, setError] = useState(null)

  if (error) {
    return <EmptyStateContainer text={error} />
  }

  if (exVidOrientation === 'portrait') {
    return (
      <>
        <DialogPrimitive.Title className='text-3xl md:text-5xl font-bold text-white mb-4 max-w-3xl capitalize mx-auto text-center'>
          {videoName}
        </DialogPrimitive.Title>
        <video
          autoPlay
          controls
          className='object-cover h-[calc(100vh-240px)] aspect-[1/1.777] rounded-xl overflow-hidden mx-auto'
        >
          <source onError={() => setError('No video found')} src={videoUrl} />
        </video>
      </>
    )
  }

  return (
    <>
      <DialogPrimitive.Title className='text-3xl md:text-5xl font-bold text-white mb-4 max-w-2xl capitalize'>
        {videoName}
      </DialogPrimitive.Title>
      <div className='w-full rounded-xl overflow-hidden'>
        <video autoPlay controls className='object-cover w-full h-full'>
          <source onError={() => setError('No video found')} src={videoUrl} />
        </video>
      </div>
    </>
  )
}
