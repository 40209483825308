import 'twin.macro'
import React from 'react'

import { Button } from 'common/components/Button/Button'

export function EmptyStateContainer({ text = 'No items', action, actionText }) {
  return (
    <div className='flex flex-col items-center justify-center h-96 bg-white px-6 py-4 rounded-xl'>
      <h3 className='font-bold text-3xl text-tBlack'>{text}</h3>
      {action && actionText && (
        <div className='mt-4'>
          <Button size='md' onClick={() => action()}>
            {actionText}
          </Button>
        </div>
      )}
    </div>
  )
}
