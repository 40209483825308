import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { CgPen, CgAdd } from 'react-icons/cg'
import { Weeks } from './Weeks'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { Button } from 'common/components/Button/Button'
import {
  useListenDraftProgramQuery,
  useAddWeekMutation,
  useInitDraftProgramMutation,
  useSetDraftProgramUpdatedAtMutation,
  useListenDraftProgramUpdatedAtQuery,
  useListenBenchmarksQuery,
} from '../programApi'
import { ProgramForm } from '../ProgramForm/ProgramForm'
import { Skeleton } from 'common/components/Skeleton/Skeleton'
import { DayHeaderContainer } from './styles'
import { times } from 'lodash'
import { dayViewMinHeight, headerMinHeight, titleDescMinHeight } from '../constants/dayViewStyleConstants'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useDispatch } from 'react-redux'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useEventListener } from 'common/hooks/useEventListener'
import { programScrolled, programViewed } from '../programRenderingSlice'
import {
  copiedPartsCleared,
  copiedWorkoutsCleared,
  draftProgramListened,
  multiSelectReferencesCleared,
} from '../programSlice'
import { debounce } from 'lodash'
import { WorkoutInfoForm } from '../WorkoutInfoForm/WorkoutInfoForm'
import { IS_MAC_OS } from 'common/utils/detectOS'
import { useHotkey } from 'common/hooks/useHotkey'
import HotkeyMenu from 'common/components/HotkeyMenu/HotkeyMenu'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { PublishQuestionnaireDialog } from './PublishQuestionnaire/PublishQuestionnaireDialog'
import { useListenExercisesQuery } from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { MutationPendingIndicator } from 'common/components/MutationPendingIndicator/MutationPendingIndicator'
import LastUpdatedAt from './LastUpdatedAt'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useCustomization } from 'common/contexts/Customization/useCustomization'

const SceneContainer = styled.div(({ isEditingLastWeek }) => [
  tw`flex flex-col min-h-screen bg-offWhite px-4
  after:transition-height after:transition-duration[400ms] after:h-0 mt-[70px]`,
  isEditingLastWeek && tw`after:h-[40vh]`,
])

const editButtonClasses = tw`
  self-start
  flex
  items-center
  font-bold
  text-tGray-med
  hover:text-tGray-dark
  text-base
  transition-all
  whitespace-nowrap
  mb-2
`

function ProgramView() {
  const { id: programId } = useParams()
  const { userId } = useAuth()
  const { exVidOrientation } = useCustomization()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const orgId = coachOrgId
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [editIdx, setEditIdx] = useState(null)

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editWorkoutInfoModalOpen, setEditWorkoutInfoModalOpen] = useState(false)
  const [modalWorkout, setModalWorkout] = useState({})
  const [hotkeyMenuIsOpen, setHotkeyMenuIsOpen] = useState(false)
  const [questModalIsOpen, setQuestModalIsOpen] = useState(false)

  // Preload exercises before loading workouts for performance
  // i.e. if there are many exercises with no videos it can take a while for camera icons to show up
  const { data: exData = {}, isLoading: isLoadingExercises } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  // Preload benchmarks
  const { data: benchmarks } = useListenBenchmarksQuery({ orgId })
  const benchmarksLoading = benchmarks === undefined || benchmarks?.isLoading

  const { data: programData = {}, isLoading } = useListenDraftProgramQuery({
    orgId,
    programId,
  })

  const {
    program = {},
    wktsByWeek,
    numWeeks,
    hasWorkouts,
    wktIdsSorted,
    wktIdsToDayIdcs,
    shouldRedirect,
    shouldInitDraft,
  } = programData

  const [initDraftProgram] = useInitDraftProgramMutation()

  const { data: videoData = {} } = useListenCoachVideosQuery({ coachOrgId: orgId })

  useEffect(() => {
    if (shouldInitDraft) {
      initDraftProgram({ orgId, programId })
    } else if (shouldRedirect) {
      navigate('/programs')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInitDraft, shouldRedirect])

  useEffect(() => dispatch(programViewed()), [programId, dispatch])

  useEffect(() => {
    dispatch(
      draftProgramListened({
        wktIdsSorted,
      })
    )
  }, [wktIdsSorted, dispatch])

  // Cleanup multiSelectFrom/To references on unmount
  // to avoid multi-select issues when doing multi-select in more than one program
  useEffect(() => {
    return () => dispatch(multiSelectReferencesCleared())
  }, [dispatch])

  const [setDraftProgramUpdatedAt] = useSetDraftProgramUpdatedAtMutation()

  const { data: draftUpdatedAt, isLoadingDraftUpdatedAt } = useListenDraftProgramUpdatedAtQuery({ orgId, programId })

  const onScroll = () => {
    //used for lazy loading workouts, so Week.js knows to re-render
    dispatch(programScrolled())
  }

  const debouncedOnScroll = debounce(onScroll, 600)

  useEventListener('scroll', debouncedOnScroll, window, { passive: true })

  const handleShowHotkeyMenu = () => {
    setHotkeyMenuIsOpen(!hotkeyMenuIsOpen)
  }

  const modKey = IS_MAC_OS ? 'meta' : 'ctrl'

  useHotkey(modKey, 'h', handleShowHotkeyMenu, { isActive: true })

  useHotkey(
    modKey,
    'l',
    () => {
      dispatch(copiedWorkoutsCleared())
      dispatch(copiedPartsCleared())
    },
    { isActive: true }
  )

  if (isLoading || !coachOrgId || programData.isFiller || isLoadingExercises || benchmarksLoading) {
    const numWeekLoaders = 2

    const getWeekDayLoaders = (weekIdx) =>
      times(7, (idx) => (
        <div
          key={`day-${idx}-${weekIdx}`}
          className='flex flex-col bg-white border border-t-0 h-full border-gray-200 w-[14.285%]'
          style={{ minHeight: dayViewMinHeight }}
        >
          <DayHeaderContainer style={{ minHeight: headerMinHeight }}>
            <Skeleton css={tw`w-10`} />
          </DayHeaderContainer>
          <div
            className='flex flex-col justify-center p-4 border-b border-gray-200'
            style={{ minHeight: titleDescMinHeight }}
          >
            <Skeleton css={tw`w-3/6 mb-6`} />
            <Skeleton css={tw`w-5/6`} />
          </div>
          <div className='h-full' />
        </div>
      ))

    return (
      <SceneContainer>
        <div className='flex justify-between items-center mt-12 mb-48'>
          <div className='flex flex-col w-96 mr-6'>
            <Skeleton css={tw`w-3/4 mb-4`} />
            <Skeleton />
          </div>
          <Button size='lg' variant='primary'>
            Publish program
          </Button>
        </div>
        {times(numWeekLoaders, (idx) => (
          <div key={`week-${idx}`}>
            <div className='bg-white border border-gray-200'>
              <DayHeaderContainer css={tw`border-b-0`} style={{ minHeight: headerMinHeight }}>
                <Skeleton css={tw`w-10`} />
              </DayHeaderContainer>
            </div>
            <div className='flex flex-wrap'>{getWeekDayLoaders(idx)}</div>
          </div>
        ))}

        <HotkeyMenu hotkeyMenuIsOpen={hotkeyMenuIsOpen} setHotkeyMenuIsOpen={setHotkeyMenuIsOpen} />
      </SceneContainer>
    )
  }

  const isEditingLastWeek = editIdx && editIdx <= numWeeks * 7 + 1 && editIdx + 1 >= numWeeks * 7 - 6

  return (
    <SceneContainer isEditingLastWeek={isEditingLastWeek}>
      <MutationPendingIndicator mutationFulfillmentAction={setProgramUpdatedAt} />
      <div className='flex justify-between items-start mt-12 mb-20'>
        <div className='flex flex-col max-w-2xl mr-6'>
          <Dialog open={editModalIsOpen} setOpen={setEditModalIsOpen}>
            <DialogTrigger
              css={editButtonClasses}
              className='hover:underline decoration-2 decoration-tGray-med underline-offset-2'
            >
              <CgPen className='w-4 h-4 mr-1' />
              Edit
            </DialogTrigger>
            <DialogContent header='Edit program' contentClassNames='!absolute !top-12' titleClassNames='!shadow-none'>
              {/*TO DO: If change program data fetched, update ProgramForm*/}
              <ProgramForm programId={programId} program={program} />
            </DialogContent>
          </Dialog>
          <h1 className='text-5xl font-bold text-tBlack mb-4'>{program?.name || 'Untitled program'}</h1>
          {program?.description && <p className='text-gray-500'>{program.description}</p>}
        </div>
        <Button onClick={() => setQuestModalIsOpen(true)}>Publish program</Button>
      </div>
      <LastUpdatedAt
        leadupText='Last published'
        updatedAt={program.publishedAt}
        isLoading={isLoading || programData.isFiller}
      />
      <LastUpdatedAt leadupText='Last changes saved' updatedAt={draftUpdatedAt} isLoading={isLoadingDraftUpdatedAt} />
      <Weeks
        editIdx={editIdx}
        setEditIdx={setEditIdx}
        wktsByWeek={wktsByWeek}
        numWksToShow={numWeeks}
        hasWorkouts={hasWorkouts}
        wktIdsToDayIdcs={wktIdsToDayIdcs}
        wktIdsSorted={wktIdsSorted}
        orgId={orgId}
        setModalWorkout={setModalWorkout}
        setEditWorkoutInfoModalOpen={setEditWorkoutInfoModalOpen}
      />
      <AddWeek programId={programId} weeksNum={numWeeks} orgId={orgId} />

      <Dialog open={editWorkoutInfoModalOpen} setOpen={setEditWorkoutInfoModalOpen}>
        <DialogContent header='Workout details'>
          <FormRefsControlProvider>
            <WorkoutInfoForm coachOrgId={coachOrgId} workout={modalWorkout} programId={programId} />
          </FormRefsControlProvider>
        </DialogContent>
      </Dialog>
      <HotkeyMenu hotkeyMenuIsOpen={hotkeyMenuIsOpen} setHotkeyMenuIsOpen={setHotkeyMenuIsOpen} />
      {questModalIsOpen && (
        <PublishQuestionnaireDialog
          questModalIsOpen={questModalIsOpen}
          setQuestModalIsOpen={setQuestModalIsOpen}
          coachOrgId={coachOrgId}
          programId={programId}
          exData={exData}
          benchmarkData={benchmarks}
          videoData={videoData}
          wktIdsToDayIdcs={wktIdsToDayIdcs}
          program={program}
        />
      )}
    </SceneContainer>
  )

  async function setProgramUpdatedAt(timestamp) {
    await setDraftProgramUpdatedAt({ coachOrgId, programId, timestamp })
  }
}

function AddWeek(props) {
  const [addWeek] = useAddWeekMutation()
  return (
    <div className='my-10 mx-auto'>
      <Button
        size='lg'
        variant='tertiary'
        css={tw`w-96 text-gray-500 text-lg`}
        onClick={() => addWeek({ orgId: props.orgId, programId: props.programId, weekIdx: props.weeksNum })}
      >
        <CgAdd className='w-5 h-5 mr-2' /> Add week
      </Button>
    </div>
  )
}

export default ProgramView
