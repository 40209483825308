import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgSearch } from 'react-icons/cg'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
} from 'modules/ContentTags/contentTagsApi'
import {
  useListenCoachProgramsQuery,
  useListenProgramGroupsQuery,
  useListenPrimaryUIColorQuery,
} from './programGroupApi'
import { ProgramGroupTable } from './ProgramGroupTable/ProgramGroupTable'
import { ProgramGroupForm } from './ProgramGroupForm/ProgramGroupForm'
import { Button, buttonBase, buttonSizes, buttonVariants } from 'common/components/Button/Button'
import { Dialog, DialogContent, DialogTrigger } from 'common/components/Dialog/Dialog'
import { InputGroup } from 'common/components/Input/InputGroup'
import { Input } from 'common/components/Input/Input'
import { FormRefsControlProvider } from 'common/components/RefsControl/FormRefsControl/context'
import { useFilter } from 'common/components/ContentTagFilter/hooks/useFilter'
import { Filter } from 'common/components/ContentTagFilter/Filter'

function ProgramGroups() {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: programGroupData } = useListenProgramGroupsQuery({ coachOrgId })
  const programGroupsLoading = programGroupData === undefined || programGroupData?.isLoading
  const programGroups = programGroupsLoading || programGroupData === null ? {} : programGroupData

  const { data: coachProgramsData } = useListenCoachProgramsQuery({ coachOrgId })
  const coachProgramsLoading = coachProgramsData === undefined || coachProgramsData?.isLoading
  const coachPrograms = coachProgramsLoading || coachProgramsData === null ? {} : coachProgramsData

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficulty = difficultyData || {}
  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipment = equipmentData || {}
  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructors = instructorsData || {}
  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = tagsData || {}

  const tagsLoading =
    difficulty === undefined ||
    difficulty?.isLoading ||
    equipment === undefined ||
    equipment?.isLoading ||
    instructors === undefined ||
    instructors?.isLoading ||
    tags === undefined ||
    tags?.isLoading

  const { filters, setFilters, result: filteredProgramGroups } = useFilter({ items: programGroups })

  const dataLoading = programGroupsLoading || coachProgramsLoading || tagsLoading

  const { data: primaryUIColorData } = useListenPrimaryUIColorQuery({ coachOrgId })

  const [searchQuery, setSearchQuery] = useState('')

  const filteredProgramGroupEntries = Object.entries(filteredProgramGroups).filter(
    ([_, programGroup]) => programGroup.name && programGroup.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const programGroupsToRender = Object.fromEntries(filteredProgramGroupEntries)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  return (
    <div className='flex flex-col min-h-screen bg-offWhite px-16'>
      <div className='w-full max-w-7xl mx-auto mt-12'>
        <div className='flex items-start justify-between'>
          <div className='w-1/3 mr-6'>
            <h1 className='font-bold text-5xl text-tBlack mb-4'>Series</h1>
            <p className='text-gray-500 max-w-sm'>
              Create a series to group and organize programs for your users. You can add a series in App Layout.
            </p>
          </div>
          <div className='flex-1 flex justify-center w-1/3'>
            <InputGroup css={[tw`max-w-md ring-offWhite`, searchQuery.length && tw`ring-tGreen ring-2`]}>
              <CgSearch tw='w-5 h-5' />
              <Input
                type='text'
                placeholder='Search for series'
                onChange={handleSearchChange}
                value={searchQuery}
                hideRing
              />
              {searchQuery.length && (
                <Button variant='secondary' size='md' onClick={() => setSearchQuery('')}>
                  Clear
                </Button>
              )}
            </InputGroup>
            <Filter
              filters={filters}
              setFilters={setFilters}
              difficulty={difficulty}
              equipment={equipment}
              instructors={instructors}
              tags={tags}
            />
          </div>
          <div className='flex justify-end ml-6 w-1/4'>
            <Dialog>
              <DialogTrigger css={[buttonBase, buttonVariants.primary, buttonSizes.lg, tw`w-44`]}>
                Create series
              </DialogTrigger>
              <DialogContent header='Create series'>
                <FormRefsControlProvider>
                  <ProgramGroupForm
                    coachOrgId={coachOrgId}
                    coachPrograms={coachPrograms}
                    availableProgramGroups={programGroups}
                    primaryUIColorData={primaryUIColorData}
                  />
                </FormRefsControlProvider>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className='flex flex-col my-20'>
          <ProgramGroupTable
            coachOrgId={coachOrgId}
            programGroups={programGroups}
            filteredProgramGroups={programGroupsToRender}
            loading={dataLoading}
            coachPrograms={coachPrograms}
          />
        </div>
      </div>
    </div>
  )
}

export default ProgramGroups
