import React from 'react'
import tw from 'twin.macro'
import * as Popover from '@radix-ui/react-popover'
import { CgAdd } from 'react-icons/cg'

import { Button } from 'common/components/Button/Button'
import { createUID } from 'common/utils/createUID'

export const addVideoBtnId = 'addVideoBtn'
export const addLinkBtnId = 'addLinkBtn'

export function AddResourcePopover({ addResourceRef, setValue, programResourcesEntries }) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild={true}>
        <Button name='addResource' ref={addResourceRef} size='md' variant='secondary' css={addBtnClasses}>
          <CgAdd className='mr-2 w-4 h-4' />
          Add resource
        </Button>
      </Popover.Trigger>
      <Popover.Content
        className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300'
        align='center'
        alignOffset={-7}
      >
        <Popover.Arrow offset={12} className='fill-gray-300' />
        <Popover.Close asChild={true}>
          <button
            id={addVideoBtnId}
            css={popoverBtnClasses}
            onClick={() => {
              const id = createUID()
              setValue(`programResources.${id}`, {
                type: 'video',
                id,
                videoId: null,
                idx: programResourcesEntries.length,
              })
            }}
          >
            Add video
          </button>
        </Popover.Close>
        <Popover.Close asChild={true}>
          <button
            id={addLinkBtnId}
            css={popoverBtnClasses}
            onClick={() => {
              const id = createUID()
              setValue(`programResources.${id}`, {
                type: 'link',
                id,
                title: '',
                description: '',
                url: '',
                idx: programResourcesEntries.length,
              })
            }}
          >
            Add link
          </button>
        </Popover.Close>
      </Popover.Content>
    </Popover.Root>
  )
}

const popoverBtnClasses = tw`
  flex items-center 
  bg-white hover:bg-gray-300 
  hover:bg-opacity-30 text-sm 
  focus:bg-gray-300 focus:bg-opacity-30 
  text-tBlack transition-all 
  rounded-md justify-start 
  font-medium px-2 py-2 w-full
`
const addBtnClasses = tw`
  bg-gray-200 hover:bg-gray-200 
  text-gray-600 hover:ring-2 
  hover:ring-tGray-ml rounded-md 
  px-4 py-2 text-xs my-4 
  font-medium self-center
`
