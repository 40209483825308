import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { push, child, ref, onValue, set, off } from 'firebase/database'
import db from 'domains/db'
import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode'

//Firebase auth functions
export const firebaseAuthWithCustomToken = async (token) => {
  const auth = getAuth()
  signInWithCustomToken(auth, token)
    .then((_userCredential) => {
      // Signed in
      // const user = userCredential.user
      // ...
    })
    .catch((error) => {
      console.log('error code', error.code, 'error message', error.message)
      // ...
    })
}

export const getFirebaseCustomToken = (OAuthToken, userId) => {
  return new Promise(async (resolve) => {
    const authKey = push(child(ref(db), 'requests/authWithOAuthToken')).key
    const authPath = `requests/authWithOAuthToken/${authKey}`
    const authListenerPath = `requests/authWithOAuthToken/${authKey}/response`

    const listener = ref(db, authListenerPath)
    onValue(listener, (snap) => {
      const response = snap.val()
      const token = response ? response.token : null

      if (token) {
        off(ref(db, authPath))
        resolve(token)
      }
    })

    set(ref(db, `${authPath}`), { token: OAuthToken, userId: userId })
  })
}

export const firebaseAuth = async (OAuthToken, userId) => {
  try {
    const token = await getFirebaseCustomToken(OAuthToken, userId)
    const auth = await firebaseAuthWithCustomToken(token) //Provides a 60-min Firebase Token, and a refresh token to get new FB tokens. https://firebase.google.com/docs/auth/admin/manage-sessions
    return auth
  } catch (err) {
    console.log('firebaseAuth err', err)
  }
}
//End firebase

//AWS auth functions
export async function awsAuth(idToken, userId) {
  const domain = process.env.AUTH_DOMAIN
  const { exp } = jwt_decode(idToken)

  try {
    const cred = await Auth.federatedSignIn(
      domain,
      {
        token: idToken, //Amplify docs say to use idToken
        expires_at: exp * 1000,
      },
      {
        name: userId,
      }
    )

    if (cred) {
      console.log('Federated Sign In success')
      return { success: true }
    } else {
      console.log('Federated Sign In - credentials not found')
      return { success: false, error: 'Federated Sign In did not return credentials' }
    }
  } catch (error) {
    console.error('error', error)
    return { success: false, error }
  }
}
//End AWS
