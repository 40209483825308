import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'index.css'
import App from 'modules/App'
import { store } from 'store'
import { AuthProvider } from 'modules/Auth/AuthContext'
import { AlertProvider } from 'common/components/Alert/context'
import { CustomizationProvider } from 'common/contexts/Customization/context'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <CustomizationProvider>
            <AlertProvider>
              <App />
            </AlertProvider>
          </CustomizationProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
