import 'twin.macro'
import React from 'react'
import { trim, each, size } from 'lodash'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'

import { Button } from 'common/components/Button/Button'
import { availableItemTypes } from '../../../constants/constants'
import { selectButtonActiveStyles, selectButtonBaseStyles } from '../styles'
import { existsInCurrentLayout, includesSearchQuery } from '../utils'

export function VideoSelection({ currentRow, currentItem, handleSelection, searchQuery }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: videoData } = useListenCoachVideosQuery({ coachOrgId })
  const videos = videoData?.isLoading || videoData === null || videoData === undefined ? {} : videoData

  if (videoData?.isLoading) {
    return <div className='text-sm'>Loading...</div>
  }

  if (!videos || size(videos) === 0) {
    return <div className='text-sm'>No videos in your video library.</div>
  }

  const availableVideos = getAvailableVideos()

  if (!Object.keys(availableVideos).length) {
    return <div className='text-sm'>No video results for this search.</div>
  }

  return Object.entries(availableVideos).map(([videoId, video]) => (
    <Button
      key={videoId}
      onClick={() => handleSelection({ id: videoId })}
      variant='secondary'
      size='md'
      css={[selectButtonBaseStyles, currentItem.id === videoId && selectButtonActiveStyles]}
    >
      <div className='flex-1'>
        <span className='flex'>{trim(video.title) ? video.title : 'Untitled'}</span>
      </div>
    </Button>
  ))

  function getAvailableVideos() {
    const availableVideos = {}

    each(videos, (video, videoId) => {
      const includesQuery = includesSearchQuery(video.title, searchQuery)

      const alreadyInLayout = existsInCurrentLayout({
        currentRow,
        itemId: videoId,
        itemType: availableItemTypes.VIDEO,
      })

      if (includesQuery && !alreadyInLayout && video.title) {
        availableVideos[videoId] = video
      }
    })

    return availableVideos
  }
}
