import tw from 'twin.macro'
import React from 'react'
import { CgChevronRightO } from 'react-icons/cg'

import { Button } from 'common/components/Button/Button'
import { availableItemTypes } from '../../../constants/constants'

const itemTypeSelectors = {
  PROGRAMGROUP: {
    type: availableItemTypes.PROGRAMGROUP,
    readable: 'series',
  },
  PROGRAM: {
    type: availableItemTypes.PROGRAM,
    readable: 'programs',
  },
  VIDEO: {
    type: availableItemTypes.VIDEO,
    readable: 'videos',
  },
}

export function ItemTypeSelectors({ setCurrentItem }) {
  const handleTypeSelection = (type) => {
    setCurrentItem({ type, id: null })
  }

  return (
    <div className='flex flex-col'>
      {Object.values(itemTypeSelectors).map(({ type, readable }) => (
        <Button
          key={type}
          onClick={() => handleTypeSelection(type)}
          variant='secondary'
          size='md'
          css={tw`flex-1 flex px-4 mb-2 last:mb-0`}
        >
          <span className='first-letter:capitalize text-left flex-1'>{readable}</span>
          <CgChevronRightO className='w-[18px] h-[18px] hover:text-tGreen' />
        </Button>
      ))}
    </div>
  )
}
