import { getFirebase, globalApi, listenFirebase, setFirebase } from 'modules/App/globalApi'
import { createUID } from 'common/utils/createUID'

export const productInfoLibraryApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenStripeProductId: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/productInfo/stripeProductId`, api)
      },
    }),
    listenLegacyProductPricing: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/productInfo/plans`, api)
      },
    }),
    listenProductPricing: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/productInfo/subscriptionPlans`, api)
      },
    }),
    setProductPrices: build.mutation({
      queryFn: async ({ coachOrgId, plans }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/productInfo/subscriptionPlans`, plans)
        return { data, error }
      },
    }),
    setProductPrice: build.mutation({
      queryFn: async ({ coachOrgId, plan, planId }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/productInfo/subscriptionPlans/${planId}`,
          plan
        )
        return { data, error }
      },
    }),
    setProductPricePromos: build.mutation({
      queryFn: async ({ coachOrgId, planId, promos }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/productInfo/subscriptionPlans/${planId}/promo`,
          promos
        )
        return { data, error }
      },
    }),
    setProductPricePromo: build.mutation({
      queryFn: async ({ coachOrgId, planId, promoIdx, promo }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/productInfo/subscriptionPlans/${planId}/promo/${promoIdx}`,
          promo
        )
        return { data, error }
      },
    }),
    listenCoupons: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/productInfo/coupons`, api)
      },
    }),
    setCoupon: build.mutation({
      queryFn: async ({ coachOrgId, couponKey, coupon }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/productInfo/coupons/${couponKey}`, coupon)
        return { data, error }
      },
    }),
    setStripeRequest: build.mutation({
      queryFn: async ({ coachOrgId, requestId, type, payload }) => {
        const { data: stripeAccountId } = await getFirebase(`coachOrgs/${coachOrgId}/productInfo/stripeAccountId`)
        const { data: stripeCustomerOwner } =
          (await getFirebase(`coachOrgs/${coachOrgId}/productInfo/stripeCustomerOwner`)) || 'connected_account'

        const value = {
          stripeAccountId,
          stripeCustomerOwner,
          coachOrgId,
          type,
          payload,
          response: {
            status: 'pending',
          },
        }

        const { data, error } = await setFirebase(
          `requests/stripeConnect/manageProducts/${coachOrgId}/requests/${requestId}`,
          value
        )
        return { data, error }
      },
    }),
    listenStripeResponse: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId, responseId }, api) => {
        listenFirebase(`requests/stripeConnect/manageProducts/${coachOrgId}/responses/${responseId}`, api)
      },
    }),
    listenStripeProductResponse: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`requests/stripeConnect/manageProducts/${coachOrgId}/responses/stripeProductUpdate`, api)
      },
    }),
    listenStripeProductName: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/productInfo/stripeProductName`, api)
      },
    }),
    listenMaybeInitPricingFromLegacy: build.query({
      queryFn: () => {
        return { data: { isFiller: true } }
      },
      onCacheEntryAdded: async ({ orgId }, api) => {
        const key = createUID()
        const path = `requests/scripts/initPricingFromLegacy/${orgId}/${key}`

        //Listen for cloud function response
        listenFirebase(path, api, async (resp) => {
          console.log('listenFirebase resp', resp)
          if (!resp) {
            api.updateCachedData(() => ({
              data: {
                isLoading: true,
              },
            }))
          } else if (resp.error) {
            console.error('listenMaybeInitPricingFromLegacy error', resp.error)
            api.updateCachedData(() => ({
              data: {
                success: false,
                error: resp.error,
              },
            }))
          } else if (resp.success) {
            api.updateCachedData(() => ({
              data: {
                success: true,
              },
            }))
          }
        })

        //Set trigger to true to start the function
        setFirebase(path, { trigger: true })
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenStripeProductIdQuery,
  useListenLegacyProductPricingQuery,
  useListenProductPricingQuery,
  useSetProductPricesMutation,
  useSetProductPriceMutation,
  useSetProductPricePromosMutation,
  useSetProductPricePromoMutation,
  useListenCouponsQuery,
  useSetCouponMutation,
  useSetStripeRequestMutation,
  useListenStripeResponseQuery,
  useListenStripeProductResponseQuery,
  useListenStripeProductNameQuery,
  useListenMaybeInitPricingFromLegacyQuery,
} = productInfoLibraryApi
