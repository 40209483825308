import 'twin.macro'
import React, { useState } from 'react'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { usePopper } from 'common/hooks/usePopper'
import { getStandardizedName } from 'common/utils/stringUtils'

import { inputBase } from 'common/components/Input/Input'
import { ExerciseSuggestions } from '../exercise/ExerciseSuggestions'

export default function ExerciseInput({ inputRef, exercises, exerciseId, setExerciseId, exVidOrientation }) {
  const [searchText, setSearchText] = useState('')
  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const suggestedExercises = Object.entries(exercises).filter(([_, exercise]) =>
    getStandardizedName(exercise.name)?.includes(getStandardizedName(searchText))
  )

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width
  return (
    <div className='mb-4'>
      <label className='capitalize inline-flex cursor-pointer font-semibold text-tBlack' tw='mb-1'>
        Exercise
      </label>
      <Combobox value={exerciseId} onChange={(value) => setExerciseId(value)} nullable>
        <div ref={popperTrigger} className='w-full'>
          <Combobox.Input
            ref={inputRef}
            autoFocus={true}
            placeholder='Search Exercises'
            autoComplete='off'
            onChange={(e) => setSearchText(e.target.value)}
            className='text-ellipsis bg-inherit'
            css={inputBase}
          />
        </div>
        {searchText?.length > 0 && suggestedExercises?.length > 0 && (
          <Portal.Root ref={popperContainer}>
            <div style={{ width: inputWidth }}>
              <ExerciseSuggestions suggestedExercises={suggestedExercises} exVidOrientation={exVidOrientation} />
            </div>
          </Portal.Root>
        )}
      </Combobox>
    </div>
  )
}
