import tw, { styled } from 'twin.macro'
import React, { useState, Fragment } from 'react'
import * as Portal from '@radix-ui/react-portal'
import { Combobox } from '@headlessui/react'
import { CgChevronDown } from 'react-icons/cg'
import { FaPlay } from 'react-icons/fa'

import fallbackImg from 'modules/Programs/assets/exercise-fallback-img.svg'
import { exIdQueryParam, videoQueryParam } from 'modules/VideoViewer/constants/queryParamConstants'
import { getStandardizedName } from 'common/utils/stringUtils'
import { usePopper } from 'common/hooks/usePopper'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { getLandOrPortExVid } from 'common/utils/exerciseUtils'
import { getLandOrPortExThumb } from 'common/utils/exerciseUtils'

export function MatchInput({ scrollAreaRef, suggestedMatches, setSelectedMatches, itemMissingVid, itemType }) {
  const { exVidOrientation } = useCustomization()
  const [selectedMatchId, setSelectedMatchId] = useState(suggestedMatches ? suggestedMatches[0].id : null)

  const [trigger, container] = usePopper({
    placement: 'bottom-end',
    strategy: 'fixed',
  })

  if (selectedMatchId === null) {
    return null
  }

  const itemName = getStandardizedName(itemMissingVid.name)
  const selectedOptionIdx = suggestedMatches?.findIndex((ex) => ex.id === selectedMatchId)
  const selectedOption = suggestedMatches?.[selectedOptionIdx]
  const selectedOptionVideo =
    itemType === 'exercise' ? getLandOrPortExVid(exVidOrientation, selectedOption) : selectedOption?.video

  const isDialogScrollable = scrollAreaRef?.current?.scrollHeight > scrollAreaRef?.current?.clientHeight

  return (
    <div css={[tw`self-start w-2/5 flex items-center`, isDialogScrollable && tw`pr-1.5`]}>
      <div className='relative bg-gray-100 rounded' ref={trigger}>
        <Combobox
          value={{ exerciseId: selectedMatchId }}
          onChange={(exId) => {
            setSelectedMatchId(exId)
            setSelectedMatches((selMatches) => ({ ...selMatches, [itemName]: exId }))
          }}
        >
          <Combobox.Button as='div' className='flex group p-1.5'>
            <div
              css={[
                tw`relative flex flex-shrink-0 h-8 w-10 rounded overflow-hidden bg-tBlack`,
                exVidOrientation === 'portrait' && itemType === 'exercise' && tw`h-12 w-8`,
              ]}
            >
              {selectedOptionVideo && (
                <button
                  onClick={() => window.open(getVideoPreviewUrl(itemType, selectedOption.id))}
                  className='absolute inset-0 hidden items-center justify-center bg-tBlack bg-opacity-30 group-hover:flex'
                >
                  <FaPlay className='w-4 h-4 text-white' />
                </button>
              )}
              <object
                data={getThumbnailUrl(itemType, selectedOption, exVidOrientation)}
                type='image/jpeg'
                className='w-full h-full object-cover'
              >
                <img
                  src={fallbackImg}
                  title='Exercise media not available'
                  alt='Exercise media not available'
                  className='w-full h-full object-cover'
                  style={{
                    imageRendering: 'pixelated',
                  }}
                />
              </object>
            </div>
            <div className='mx-1 self-center'>
              <Combobox.Input
                placeholder='Select Option'
                autoComplete='off'
                onKeyDown={(e) => e.preventDefault()}
                displayValue={() => `Match ${suggestedMatches.length === 1 ? '' : selectedOptionIdx + 1}`}
                css={inputClasses}
                className='text-ellipsis bg-inherit'
              />
            </div>
            <div className='flex items-center'>
              <CgChevronDown className='cursor-pointer text-gray-500 hover:text-tGreen w-[18px] h-[18px]' />
            </div>
          </Combobox.Button>
          {suggestedMatches.length > 1 && (
            <Portal.Root ref={container}>
              <Combobox.Options as={Fragment}>
                <OptionsContainer>
                  <ScrollArea css={tw`rounded-xl max-h-64`}>
                    <ul className='p-2 pr-4'>
                      {suggestedMatches.map((suggestion, idx) => {
                        const suggestionVideo =
                          itemType === 'exercise' ? getLandOrPortExVid(exVidOrientation, suggestion) : suggestion?.video
                        return (
                          <Combobox.Option as={Fragment} key={suggestion.id} value={suggestion.id}>
                            {({ active }) => (
                              <SuggestedItem isFocused={active} className='group'>
                                <div className='flex items-center'>
                                  <div className='flex items-center flex-1'>
                                    <div
                                      css={[
                                        tw`relative flex h-8 w-10 rounded overflow-hidden bg-tBlack`,
                                        exVidOrientation === 'portrait' && itemType === 'exercise' && tw`h-12 w-8`,
                                      ]}
                                    >
                                      {suggestionVideo && (
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            window.open(getVideoPreviewUrl(itemType, suggestion.id))
                                          }}
                                          className='absolute inset-0 hidden items-center justify-center bg-tBlack bg-opacity-30 group-hover:flex'
                                        >
                                          <FaPlay className='w-4 h-4 text-white' />
                                        </button>
                                      )}
                                      <object
                                        data={getThumbnailUrl(itemType, suggestion, exVidOrientation)}
                                        type='image/jpeg'
                                        className='w-full h-full object-cover'
                                      >
                                        <img
                                          src={fallbackImg}
                                          title='Exercise media not available'
                                          alt='Exercise media not available'
                                          className='w-full h-full object-cover'
                                          style={{
                                            imageRendering: 'pixelated',
                                          }}
                                        />
                                      </object>
                                    </div>
                                    <span className='ml-3 text-tBlack font-medium text-sm capitalize'>
                                      Match {suggestedMatches.length === 1 ? '' : idx + 1}
                                    </span>
                                  </div>
                                </div>
                              </SuggestedItem>
                            )}
                          </Combobox.Option>
                        )
                      })}
                    </ul>
                  </ScrollArea>
                </OptionsContainer>
              </Combobox.Options>
            </Portal.Root>
          )}
        </Combobox>
      </div>
    </div>
  )
}

const getVideoPreviewUrl = (itemType, itemId) =>
  `/videoviewer?${itemType === 'exercise' ? exIdQueryParam : videoQueryParam}=${itemId}`

const getThumbnailUrl = (itemType, item, exVidOrientation) =>
  itemType === 'exercise' ? getLandOrPortExThumb(exVidOrientation, item) : item.previewImg

const OptionsContainer = tw.div`w-[180px] pointer-events-auto bg-white rounded-md overflow-hidden shadow-xl z-30 border-2 border-gray-300`

const SuggestedItem = styled.li(({ isFocused }) => [
  tw`cursor-pointer hover:bg-gray-300 hover:bg-opacity-30 py-2.5 px-2 rounded break-all`,
  isFocused && tw`bg-gray-300 bg-opacity-30`,
])

const inputClasses = tw`font-medium text-sm focus:ring-0 p-1 py-1.5 capitalize w-full caret-transparent 
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400 rounded
`
