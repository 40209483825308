import 'twin.macro'
import React, { Fragment } from 'react'
import * as Portal from '@radix-ui/react-portal'
import { CgCheck } from 'react-icons/cg'
import { Combobox } from '@headlessui/react'

import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import { getSuggestedProgressions } from './utils'

export function ProgressionInputOptions({ availableProgressions, value, inputRef, popperContainer }) {
  const suggestedProgressions = getSuggestedProgressions({
    availableProgressions,
    inputValue: value,
  })

  const suggestedProgStart = 0
  const suggestedProgEnd = 30

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width

  return (
    <Portal.Root ref={popperContainer}>
      <Combobox.Options as={Fragment}>
        <SuggestionContainer containerStyle={{ width: inputWidth }}>
          {Object.keys(suggestedProgressions).length === 0 ? (
            <Combobox.Option as={Fragment} key={value} value={value}>
              {({ active }) => (
                <SuggestionItem active={active}>
                  <span className='flex text-xxs text-gray-400'>Create new progression</span>
                  {value}
                </SuggestionItem>
              )}
            </Combobox.Option>
          ) : (
            Object.entries(suggestedProgressions)
              .slice(suggestedProgStart, suggestedProgEnd)
              .map(([progressionKey]) => (
                <Combobox.Option as={Fragment} key={progressionKey} value={progressionKey}>
                  {({ selected, active }) => (
                    <SuggestionItem active={active}>
                      {selected && <CgCheck className='w-6 h-6 text-tGreen' aria-hidden='true' />}
                      {progressionKey}
                    </SuggestionItem>
                  )}
                </Combobox.Option>
              ))
          )}
        </SuggestionContainer>
      </Combobox.Options>
    </Portal.Root>
  )
}
