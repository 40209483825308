import tw from 'twin.macro'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { availableItemTypes } from 'modules/Layout/constants/constants'
import { useListenCoachProgramsQuery } from 'modules/Layout/layoutApi'
import { pluralize } from 'common/utils/stringUtils'

export function NavigationActions({ handleBack, previousItem, currentItem, coachOrgId }) {
  const { data: coachPrograms } = useListenCoachProgramsQuery({ coachOrgId })

  if (!currentItem) {
    return <CancelBtn />
  }

  const title = currentItem.type === 'programGroup' ? 'Series' : currentItem.type
  let subtitle
  if (currentItem.type === availableItemTypes.WORKOUT && previousItem) {
    const program = previousItem.find((item) => item.type === availableItemTypes.PROGRAM)
    subtitle = coachPrograms[program.id]
  }

  return (
    <div className='flex justify-between items-center mb-2'>
      <button onClick={() => handleBack()} css={baseButtonClasses}>
        Back
      </button>
      <div className='flex flex-col items-center mx-2'>
        <h4 className='font-bold text-tBlack first-letter:uppercase'>{pluralize({ text: title })}</h4>
        {subtitle && <div className='text-xs text-center first-letter:uppercase text-gray-500'>{subtitle}</div>}
      </div>
      <div className='w-12' />
    </div>
  )
}

const CancelBtn = () => (
  <div className='flex justify-between items-center mb-2'>
    <Popover.Close css={baseButtonClasses}>Cancel</Popover.Close>
  </div>
)

const baseButtonClasses = tw`text-tGreen rounded-lg px-2 py-1 hover:bg-tGreen hover:bg-opacity-10 transition-colors`
