import React, { useState, useEffect } from 'react'
import { timeAgo } from '../utils/timeUtils'

export default function LastUpdatedAt(props) {
  const { updatedAt, isLoading } = props
  const [, setTime] = useState(Date.now())

  useEffect(() => {
    const MS_INTERVAL = 1000 * 30

    const interval = setInterval(() => setTime(Date.now()), MS_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (isLoading || !updatedAt || updatedAt.isFiller) {
    return <div className='h-6' />
  }

  const leadupText = props.leadupText || 'Last updated'

  return (
    <div className='h-6 flex row justify-end mb-2 mr-[2px] text-gray-500 text-sm'>
      {leadupText} {timeAgo(updatedAt)}
    </div>
  )
}
