import React, { useState, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { CgImage } from 'react-icons/cg'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenProgramImgQuery, useListenProgramIntroVideoQuery } from '../programApi'
import {
  useListenDifficultyQuery,
  useListenEquipmentQuery,
  useListenInstructorsQuery,
  useListenTagsQuery,
} from 'modules/ContentTags/contentTagsApi'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { useEventListener } from 'common/hooks/useEventListener'

import { Input } from 'common/components/Input/Input'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { getTagsList } from 'modules/ContentTags/contentTagUtils'
import { TextareaInputsAccordion } from './TextareaInputsAccordion'
import { TagInputsAccordion } from 'common/components/TagInputsAccordion/TagInputsAccordion'

export function DisplayForm({ programId, program, isNewProgram, onSubmit, createProgramOnUpload, submitRef }) {
  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: difficultyData } = useListenDifficultyQuery({ coachOrgId })
  const difficultyTags = getTagsList(difficultyData)

  const { data: equipmentData } = useListenEquipmentQuery({ coachOrgId })
  const equipmentTags = getTagsList(equipmentData)

  const { data: instructorsData } = useListenInstructorsQuery({ coachOrgId })
  const instructorTags = getTagsList(instructorsData)

  const { data: tagsData } = useListenTagsQuery({ coachOrgId })
  const tags = getTagsList(tagsData)

  const methods = useFormContext()
  const {
    register,
    watch,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = methods
  const formState = watch()

  const [accordionValue, setAccordionValue] = useState(null)

  const { data: previewImg } = useListenProgramImgQuery({ coachOrgId, programId })
  const { data: introVideo } = useListenProgramIntroVideoQuery({ coachOrgId, programId })

  const DIFFICULTY_LIMIT = 10
  const EQUIPMENT_LIMIT = 10
  const INSTRUCTORS_LIMIT = 4
  const TAGS_LIMIT = 10

  const isDifficultyLimitReached = formState.difficulty.length >= DIFFICULTY_LIMIT
  const isEquipmentLimitReached = formState.equipment.length >= EQUIPMENT_LIMIT
  const isInstructorsLimitReached = formState.instructors.length >= INSTRUCTORS_LIMIT
  const isTagsLimitReached = formState.tags.length >= TAGS_LIMIT

  const hasOnlyPreviewImgErr = errors?.previewImg && Object.keys(errors).length === 1
  const hasOnlyIntroVideoErr = errors?.introVideo && Object.keys(errors).length === 1

  // ref control
  const { addManyInputRefs, moveFocusedInputBy, moveFocusOnKeyPress } = useFormRefsControl()
  const nameRef = useRef()
  const subtitleRef = useRef()
  const descriptionRef = useRef()
  const frequencyRef = useRef()
  const prerequisitesRef = useRef()
  const difficultyRef = useRef()
  const equipmentRef = useRef()
  const instructorsRef = useRef()
  const tagsRef = useRef()

  useEffect(() => {
    addManyInputRefs([
      { ref: nameRef, name: 'name' },
      { ref: subtitleRef, name: 'subtitle' },
      { ref: descriptionRef, name: 'description' },
      { ref: frequencyRef, name: 'frequency' },
      { ref: prerequisitesRef, name: 'prerequisites' },
      { ref: difficultyRef, name: 'difficulty' },
      { ref: equipmentRef, name: 'equipment' },
      { ref: instructorsRef, name: 'instructors' },
      { ref: tagsRef, name: 'tags' },
      { ref: submitRef, name: 'submit' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveProgNameFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey) {
        setAccordionValue('tagInputs')
        setTimeout(() => {
          moveFocusedInputBy(-1)
        }, 50)
      } else if (!e.shiftKey) {
        moveFocusedInputBy(1)
      }
    }
  }

  const moveSubtitleFocusOnKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'Tab') {
      e.preventDefault()
      e.stopPropagation()
      if (e.shiftKey) {
        moveFocusedInputBy(-1)
      } else if (!e.shiftKey) {
        if (accordionValue !== 'textAreaInputs') {
          setAccordionValue('textAreaInputs')
        }
        setTimeout(() => {
          moveFocusedInputBy(1)
        }, 50)
      }
    }
  }

  const moveTagFocusOnKeyPress = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const isTagInputEmpty = e.target.value.trim() === ''
    if (e.code === 'Enter' || (!isTagInputEmpty && e.code === 'Tab')) {
      return
    }

    if (e.shiftKey) {
      if (e.target.name === 'difficulty' && accordionValue !== 'textAreaInputs') {
        setAccordionValue('textAreaInputs')
        setTimeout(() => {
          moveFocusedInputBy(-1)
        }, 50)
      } else {
        moveFocusedInputBy(-1)
      }
    } else if (!e.shiftKey) {
      moveFocusedInputBy(1)
    }
  }

  useEventListener('keydown', (e) => {
    if (
      e.target.name === 'instructors' ||
      e.target.name === 'equipment' ||
      e.target.name === 'tags' ||
      e.target.name === 'difficulty'
    ) {
      if (e.code === 'Enter' || e.code === 'Tab') {
        moveTagFocusOnKeyPress(e)
      } else {
        if (e.target.name === 'difficulty' && isDifficultyLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'equipment' && isEquipmentLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'instructors' && isInstructorsLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (e.target.name === 'tags' && isTagsLimitReached) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } else {
      moveFocusOnKeyPress(e, handleSubmit(onSubmit))
    }
  })

  return (
    <>
      <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
        <div className='mb-2'>
          <label htmlFor='name' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
            Program name
          </label>
          <Input
            name='name'
            type='text'
            placeholder='Enter program name'
            register={register}
            onKeyDown={moveProgNameFocusOnKeyPress}
            inputRef={nameRef}
            error={errors?.name?.message}
            autoFocus={true}
          />
          {errors.name && <p className='flex text-xs mt-1 text-tRed'>{errors.name.message}</p>}
        </div>
      </div>
      <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
        <div className='mb-2'>
          <label htmlFor='subtitle' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
            Subtitle
          </label>
          <Input
            name='subtitle'
            type='text'
            placeholder='(Optional) Enter subtitle'
            register={register}
            onKeyDown={moveSubtitleFocusOnKeyPress}
            inputRef={subtitleRef}
            error={errors?.subtitle?.message}
          />
          {errors.subtitle && <p className='flex text-xs mt-1 text-tRed'>{errors.subtitle.message}</p>}
        </div>
      </div>
      <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
        <UploadInput
          name='previewImg'
          label='Cover image'
          register={register}
          setValue={setValue}
          coachOrgId={coachOrgId}
          id={programId}
          uploadType='program-image'
          liveUrl={previewImg || program?.previewImg}
          setError={setError}
          onUpload={isNewProgram ? createProgramOnUpload : null}
          uploadDisabled={!formState.name || (!isEmpty(errors) && !hasOnlyPreviewImgErr)}
          previewIcon={<CgImage className='!w-5 !h-5' />}
          clearErrors={clearErrors}
          fileType='image'
        />
        {errors.previewImg && <p className='flex text-xs mt-1 text-tRed'>{errors.previewImg.message}</p>}
      </div>
      <div className='flex flex-col px-10 py-4 border-b border-gray-200'>
        <UploadInput
          name='introVideo'
          label='Intro video'
          register={register}
          setValue={setValue}
          coachOrgId={coachOrgId}
          id={programId}
          uploadType='program-intro-video'
          liveUrl={introVideo || program?.introVideo}
          setError={setError}
          onUpload={isNewProgram ? createProgramOnUpload : null}
          uploadDisabled={!formState.name || (!isEmpty(errors) && !hasOnlyIntroVideoErr)}
          clearErrors={clearErrors}
          customFileSizeLimit={5000000000} //5.0gb for large video files
          fileType='video'
        />
        {errors.introVideo && <p className='flex text-xs mt-1 text-tRed'>{errors.introVideo.message}</p>}
      </div>
      <TextareaInputsAccordion
        accordionValue={accordionValue}
        setAccordionValue={setAccordionValue}
        register={register}
        descriptionRef={descriptionRef}
        frequencyRef={frequencyRef}
        prerequisitesRef={prerequisitesRef}
        errors={errors}
        moveFocusedInputBy={moveFocusedInputBy}
      />
      <TagInputsAccordion
        accordionValue={accordionValue}
        setAccordionValue={setAccordionValue}
        formState={formState}
        register={register}
        difficultyRef={difficultyRef}
        equipmentRef={equipmentRef}
        instructorsRef={instructorsRef}
        tagsRef={tagsRef}
        difficultyTags={difficultyTags}
        equipmentTags={equipmentTags}
        instructorTags={instructorTags}
        tags={tags}
        setValue={setValue}
        isDifficultyLimitReached={isDifficultyLimitReached}
        isEquipmentLimitReached={isEquipmentLimitReached}
        isInstructorsLimitReached={isInstructorsLimitReached}
        isTagsLimitReached={isTagsLimitReached}
        rootClasses='!border-b-0'
      />
    </>
  )
}
