import tw from 'twin.macro'
import { CgTime, CgStopwatch } from 'react-icons/cg'
import { IoIosWater } from 'react-icons/io'
import { useEventListener } from 'common/hooks/useEventListener'

function CmdOption(props) {
  let time = props.type === 'rest' ? props.rest : props.time

  const setTime = (e) => {
    e.stopPropagation()
    props.onClick()
  }

  const setTimeOnEnterOrTab = (e) => {
    const enterKeyCode = 13
    const tabKeyCode = 9

    if (e.keyCode === enterKeyCode || e.keyCode === tabKeyCode) {
      e.preventDefault()
      e.stopPropagation()
      setTime(e)
    }
  }

  useEventListener('keydown', setTimeOnEnterOrTab, props.instrInputRef.current)

  if (!time && props?.type !== 'stopwatch') return null

  let minSecText = time.substring(3, 8)
  const has2DigMin = minSecText.charAt(0) === '0' ? false : true
  if (!has2DigMin) {
    minSecText = minSecText.substring(1)
  }

  return (
    <div className='flex flex-col p-2'>
      <div className='flex p-2 bg-gray-300 bg-opacity-30 rounded-md cursor-pointer'>
        <button className='flex items-center text-sm font-medium text-tBlack' onClick={(e) => setTime(e)}>
          {props.type === 'stopwatch' && <CgStopwatch className='w-5 h-5 text-gray-500 mr-2' />}
          {props.type === 'stopwatch' && `Add stopwatch`}
          {props.type === 'rest' && <IoIosWater className='w-5 h-5 text-gray-500 mr-2' />}
          {props.type === 'rest' && `Rest Timer: ${minSecText || '0:00'}`}
          {props.type === 'time' && <CgTime className='w-5 h-5 text-gray-500 mr-2' />}
          {props.type === 'time' && `Timer: ${minSecText || '0:00'}`}
        </button>
      </div>
    </div>
  )
}

const DropdownContainer = tw.div`
  absolute
  z-20
  flex
  flex-col
  top-7
  left-0
  rounded-md
  overflow-hidden
  bg-white
  border-2
  border-gray-300
  shadow-xl
`

export function CmdDropdown(props) {
  if (!props.rest && !props.time && props?.type !== 'stopwatch') return null

  return (
    <DropdownContainer role='dialog'>
      <CmdOption
        rest={props.rest}
        time={props.time}
        type={props.type}
        onClick={props.onClick}
        instrInputRef={props.instrInputRef}
      />
    </DropdownContainer>
  )
}
