import tw from 'twin.macro'
import React, { useState } from 'react'
import { Combobox } from '@headlessui/react'
import * as Portal from '@radix-ui/react-portal'

import { VideoSuggestions } from 'modules/Programs/components/WorkoutVideo'
import { inputBase } from 'common/components/Input/Input'
import { usePopper } from 'common/hooks/usePopper'
import { getStandardizedName } from 'common/utils/stringUtils'

export default function VideoInput({ inputRef, value, videoTitle, videos, setValue, clearErrors, error }) {
  const [searchText, setSearchText] = useState(videoTitle)
  let [popperTrigger, popperContainer] = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const suggestedVideos = videos
    ? Object.entries(videos).filter(([_, data]) =>
        getStandardizedName(data.title)?.includes(getStandardizedName(searchText))
      )
    : []

  const inputWidth = inputRef?.current?.getBoundingClientRect()?.width
  return (
    <div className='mb-4'>
      <label className='capitalize inline-flex cursor-pointer font-semibold text-tBlack' tw='mb-1'>
        Video
      </label>
      <Combobox
        value={value}
        onChange={(videoId) => {
          clearErrors('videoId')
          setValue(`videoId`, videoId)
        }}
        nullable
      >
        <div ref={popperTrigger} className='w-full'>
          <Combobox.Input
            ref={inputRef}
            placeholder='Search Full Length Videos'
            autoComplete='off'
            displayValue={() => videoTitle}
            onChange={(e) => setSearchText(e.target.value)}
            disabled={true}
            className='text-ellipsis bg-inherit'
            css={[inputBase, error && tw`ring-tRed focus:ring-tRed`]}
          />
          {error && <p className='flex text-xs mt-1 text-tRed'>{error.message}</p>}
        </div>
        {searchText?.length > 0 && suggestedVideos?.length > 0 && (
          <Portal.Root ref={popperContainer}>
            <div style={{ width: inputWidth }}>
              <VideoSuggestions suggestedVideos={suggestedVideos} containerClasses='!top-2' />
            </div>
          </Portal.Root>
        )}
      </Combobox>
    </div>
  )
}
