import tw, { styled, css } from 'twin.macro'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  interpCmd,
  getHasCmdIntent,
  getIfCaretWithinBounds,
  getHasCmd,
  removeCmdsAndMultiSpacesFromInstr,
} from 'modules/Programs/utils/exerciseDomain'
import { getIsWorkoutSelected } from 'modules/Programs/programSlice'
import { CmdDropdown } from './ExerciseCommandDropdown'
import { useSetWorkoutExerciseMutation, useUpdateWorkoutExerciseMutation } from 'modules/Programs/programApi'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import { CgTrash, CgTime, CgStopwatch } from 'react-icons/cg'
import { IoIosWater } from 'react-icons/io'
import { getPrettyExerciseTime, EMPTY_TIME_STRING } from '../../utils/timeUtils'
import { ExerciseNameInput } from './ExerciseNameInput'
import DetectOutsideClick from 'common/components/DetectOutsideClick/DetectOutsideClick'
import { useRefsControl } from 'common/components/RefsControl/WorkoutRefsControl/useRefsControl'
import { createUID } from 'common/utils/createUID'
import { handleTimerHelperSelect, TimerHelper } from './TimerHelper'
import { useEventListener } from 'common/hooks/useEventListener'
import { TimerInputPopover } from './TimerInputPopover'

const EditExerciseContainer = styled.div(({ isSelected, isFocused }) => [
  tw`flex flex-col relative p-2 rounded-md border shadow-sm`,
  isFocused && tw`border-gray-400`,
  css`
    &:hover #editexercise-actions {
      visibility: visible;
      display: flex;
    }

    &:hover #removetimer-action {
      display: flex;
    }
  `,
  isSelected
    ? css`
        &:hover #editexercise-actions-container {
          border-color: rgb(229 231 235);
          box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
          background-color: rgb(255, 255, 255);
        }
      `
    : css`
        &:hover #editexercise-actions-container {
          border-color: rgb(229 231 235);
          box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        }
      `,

  // timer actions
  css`
    &:hover #timer-actions-container {
      visibility: visible;
      display: flex;
    }
  `,
])

const ExInstrContainer = styled.div(({ isEditingWkt, instrState }) => [
  tw`flex relative mt-1 w-full`,
  !isEditingWkt && !instrState && tw`hidden`,
])

const EditExercise = React.memo((props) => {
  const {
    exercise,
    isEditingWkt,
    exIdx,
    partIdx,
    dayIdx,
    setEditIdx,
    setActivePartIdx,
    setActiveExIdx,
    workoutId,
    attributes,
    listeners,
    addExRef,
    isLastExInPart,
    isLastExercise,
    isPartSelected,
  } = props

  const { userId } = useAuth()
  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { workoutId: isLargeView } = useParams()

  const [instrState, setInstructions] = useState(exercise.instructions || '')
  const [timeState, setTime] = useState(exercise.time || '')
  const [restState, setRest] = useState(exercise.rest || '')

  const [cmdDropdown, setCmdDropdown] = useState({
    show: false,
    type: null,
    cmdText: '',
  })
  const [showHelper, setShowHelper] = useState(false)
  const [selectedHelper, setSelectedHelper] = useState('rest')
  const [showTimerActions, setShowTimerActions] = useState(true)

  const [setWorkoutExercise] = useSetWorkoutExerciseMutation({
    fixedCacheKey: isLargeView ? 'largeview-shared-set-exercise' : null,
  })

  const [updateWorkoutExercise] = useUpdateWorkoutExerciseMutation()

  //Tracking refs for "Enter" key navigation
  const [refsUID, setRefsUID] = useState('') //Temporary ID
  const { addExerciseRefs, activeInputRef, setActiveInput, focusOnActiveInput } = useRefsControl()
  const nameRef = useRef()
  const instrRef = useRef()
  const editExContRef = useRef()

  const debouncedInstr = useDebounce(instrState, 200)
  const hasCmd = getHasCmd({ instructions: debouncedInstr })

  useDebouncedTextMutation({
    stateText: instrState,
    dbText: exercise.instructions,
    mutation: setWorkoutExercise,
    debouncedStateText: debouncedInstr,
    mutationArgs: {
      orgId: coachOrgId,
      workoutId,
      partIdx,
      exIdx,
      exercise: {
        ...exercise,
        instructions: hasCmd ? removeCmdsAndMultiSpacesFromInstr(debouncedInstr) : debouncedInstr,
      },
    },
  })

  const handleStopWatchSet = (hasStopWatch, cmdText = '/') => {
    const formattedInstr = instrState.replace(cmdText, '')
    setCmdDropdown({ show: false, type: null, cmdText: '' })
    setTime('')
    setInstructions(formattedInstr)

    updateWorkoutExercise({
      orgId: coachOrgId,
      workoutId,
      partIdx,
      exIdx,
      exercise: {
        hasStopWatch,
        time: null,
      },
    })
  }

  const handleTimeSet = (time, rest, cmdText) => {
    const formattedInstr = instrState.replace(cmdText, '')
    setCmdDropdown({ show: false, type: null, cmdText: '' })

    setRest(rest)
    setTime(time)
    setInstructions(formattedInstr)

    if (time && time !== EMPTY_TIME_STRING) {
      updateWorkoutExercise({
        orgId: coachOrgId,
        workoutId,
        partIdx,
        exIdx,
        exercise: {
          time,
          hasStopWatch: null,
        },
      })
    }

    if (rest && rest !== EMPTY_TIME_STRING) {
      updateWorkoutExercise({
        orgId: coachOrgId,
        workoutId,
        partIdx,
        exIdx,
        exercise: {
          rest,
        },
      })
    }
  }

  const getNameInputRef = (input) => {
    nameRef.current = input
  }

  useEffect(() => {
    if (isEditingWkt) {
      if (refsUID) {
        addExerciseRefs(
          { name: nameRef, instr: instrRef, addEx: isLastExInPart ? addExRef : null },
          refsUID,
          partIdx,
          exIdx,
          isLastExercise
        )
      } else {
        const newId = createUID()
        setRefsUID(newId)
        addExerciseRefs(
          { name: nameRef, instr: instrRef, addEx: isLastExInPart ? addExRef : null },
          newId,
          partIdx,
          exIdx,
          isLastExercise
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingWkt, partIdx, exIdx, isLastExInPart])

  useEffect(() => {
    if (isEditingWkt && (activeInputRef === nameRef || activeInputRef === instrRef)) {
      const shouldSelect = true //select = highlight all text
      focusOnActiveInput(shouldSelect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingWkt, activeInputRef])

  const handleFocusInput = (e) => {
    //Covers clicking on inputs
    e.stopPropagation()
    if (e.target !== nameRef.current && e.target !== instrRef.current) {
      setActiveInput(nameRef)
    }
    // we need the condition cause in LargeDayView we dont have this function so we get an error
    if (setEditIdx) setEditIdx(dayIdx)
  }

  const handleKeyDownInput = (e) => {
    const arrowUpOrDown = e.key === 'ArrowDown' || e.key === 'ArrowUp'
    const shouldSelectHelper = showHelper && (e.key === 'Enter' || e.key === 'Tab')

    if (arrowUpOrDown && selectedHelper === 'rest' && showHelper) {
      setSelectedHelper('time')
    } else if (arrowUpOrDown && selectedHelper === 'time' && showHelper) {
      setSelectedHelper('stopwatch')
    } else if (arrowUpOrDown && selectedHelper === 'stopwatch' && showHelper) {
      setSelectedHelper('rest')
    } else if (shouldSelectHelper) {
      e.preventDefault()
      e.stopPropagation()
      handleTimerHelperSelect({
        selectedHelper,
        setInstructions,
        setRest,
        setCmdDropdown,
        setTime,
        setShowHelper,
        setSelectedHelper,
        handleStopWatchSet,
      })
    } else if (e.key === 'Escape') {
      setShowHelper(false)
      setSelectedHelper('rest')
    }
  }

  const handleChangeInput = (e) => {
    const typedValue = e.nativeEvent.data
    const isSingleCharTyped = e.target.value.length === 1
    const isPrevCharSpace = e.target.value[e.target.value.length - 2] === ' '

    if (typedValue === '/' && (isSingleCharTyped || isPrevCharSpace)) {
      setShowHelper(true)
    } else {
      setShowHelper(false)
    }

    onSetInstructions({
      e,
      setInstructions,
      setRest,
      setTime,
      showCmdDropdown: cmdDropdown.show,
      setCmdDropdown,
    })
    setActivePartIdx(partIdx)
    setActiveExIdx(exIdx)

    setShowTimerActions(false)
  }

  const isWktSelected = useSelector((state) => getIsWorkoutSelected(state, workoutId))
  const isExInstrInputFocused = instrRef.current === document.activeElement

  const onInstrInpMouseMove = () => {
    if (isExInstrInputFocused) {
      setShowTimerActions(true)
    }
  }

  useEventListener('mousemove', onInstrInpMouseMove, editExContRef?.current, { passive: true })

  const onInstrInpMouseLeave = () => {
    if (isExInstrInputFocused) {
      setShowTimerActions(false)
    }
  }

  useEventListener('mouseleave', onInstrInpMouseLeave, editExContRef?.current, { passive: true })

  return (
    <EditExerciseContainer
      ref={editExContRef}
      isSelected={isPartSelected || isWktSelected}
      isFocused={editExContRef?.current?.contains(document.activeElement)}
      onClick={handleFocusInput}
    >
      <div className='relative flex items-center ml-1'>
        <div className='font-medium text-sm capitalize'>{exIdx + 1 + '. '}</div>
        <ExerciseNameInput
          setActiveExIdx={setActiveExIdx}
          setActivePartIdx={setActivePartIdx}
          workoutId={workoutId}
          partIdx={partIdx}
          exIdx={exIdx}
          exercise={exercise}
          coachOrgId={coachOrgId}
          instrRef={instrRef}
          isEditingWkt={isEditingWkt}
          isSelected={isPartSelected || isWktSelected}
          getNameInputRef={getNameInputRef}
          editExContRef={editExContRef}
          onFocus={() => {
            setActivePartIdx(partIdx)
            setActiveExIdx(exIdx)
            setActiveInput(nameRef)
          }}
          setShowTimerActions={setShowTimerActions}
          attributes={attributes}
          listeners={listeners}
        />
      </div>
      <ExInstrContainer isEditingWkt={isEditingWkt} instrState={instrState}>
        <input
          type='text'
          css={[instrInputClasses, !isEditingWkt && tw`cursor-pointer`]}
          className='text-ellipsis bg-inherit'
          value={instrState}
          placeholder="Exercise instructions (type '/' for timers)"
          onKeyDown={handleKeyDownInput}
          onChange={handleChangeInput}
          onBlur={() => {
            setInstructions(instrState.trim())
            setShowTimerActions(true)
          }}
          onFocus={() => {
            setActivePartIdx(partIdx)
            setActiveExIdx(exIdx)
            setActiveInput(instrRef)
            setShowTimerActions(false)
          }}
          ref={instrRef}
        />
        <TimerInputPopover
          exercise={exercise}
          setShowTimerActions={setShowTimerActions}
          showTimerActions={showTimerActions}
          isEditingWkt={isEditingWkt}
          instrRef={instrRef}
          updateExTimers={(update) => {
            updateWorkoutExercise({
              orgId: coachOrgId,
              workoutId,
              partIdx,
              exIdx,
              exercise: update,
            })
          }}
        />
        {showHelper && isEditingWkt && (
          <DetectOutsideClick isOpen={showHelper} setIsOpen={() => setShowHelper(false)}>
            <div
              role='dialog'
              className='absolute z-20 top-7 left-0 bg-white p-2 rounded-md shadow-xl border-2 border-gray-300'
            >
              <TimerHelper
                setShowHelper={setShowHelper}
                selectedHelper={selectedHelper}
                setSelectedHelper={setSelectedHelper}
                setInstructions={setInstructions}
                setRest={setRest}
                setTime={setTime}
                setCmdDropdown={setCmdDropdown}
                handleStopWatchSet={handleStopWatchSet}
              />
            </div>
          </DetectOutsideClick>
        )}
        {cmdDropdown.show ? (
          <DetectOutsideClick
            isOpen={cmdDropdown.show}
            setIsOpen={() => setCmdDropdown({ show: false, type: null, cmdText: '' })}
          >
            <CmdDropdown
              instrInputRef={instrRef}
              rest={restState}
              time={timeState}
              type={cmdDropdown.type}
              onClick={() => {
                if (cmdDropdown?.type === 'stopwatch') {
                  handleStopWatchSet(true, cmdDropdown.cmdText)
                } else {
                  handleTimeSet(timeState, restState, cmdDropdown.cmdText)
                }
                instrRef.current.focus()
              }}
            />
          </DetectOutsideClick>
        ) : null}
      </ExInstrContainer>
      <TimerDisplay
        exercise={exercise}
        isEditingWkt={isEditingWkt}
        handleDeleteTimer={() => {
          setTime(null)
          updateWorkoutExercise({
            orgId: coachOrgId,
            workoutId,
            partIdx,
            exIdx,
            exercise: {
              time: null,
            },
          })
        }}
        handleDeleteRest={() => {
          setRest(null)
          updateWorkoutExercise({
            orgId: coachOrgId,
            workoutId,
            partIdx,
            exIdx,
            exercise: {
              rest: null,
            },
          })
        }}
        handleDeleteStopWatch={() => {
          updateWorkoutExercise({
            orgId: coachOrgId,
            workoutId,
            partIdx,
            exIdx,
            exercise: {
              hasStopWatch: null,
            },
          })
        }}
      />
    </EditExerciseContainer>
  )
})

const instrInputClasses = tw`text-sm text-gray-500
overflow-hidden focus:ring-0 border-none w-full
placeholder:text-gray-400 focus:border-b-2 focus:border-tGreen
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded p-1
`

function TimerDisplay({ exercise, isEditingWkt, handleDeleteTimer, handleDeleteRest, handleDeleteStopWatch }) {
  if (!exercise?.time && !exercise?.rest && !exercise?.hasStopWatch) {
    return null
  }

  return (
    <div className='mt-2 ml-1'>
      {exercise?.hasStopWatch && (
        <Timer type='stopwatch' isEditingWkt={isEditingWkt} handleDelete={() => handleDeleteStopWatch()} />
      )}
      {exercise?.time && exercise.time !== EMPTY_TIME_STRING ? (
        <Timer time={exercise.time} type='timer' isEditingWkt={isEditingWkt} handleDelete={() => handleDeleteTimer()} />
      ) : null}
      {exercise?.rest && exercise.rest !== EMPTY_TIME_STRING ? (
        <Timer time={exercise.rest} type='rest' isEditingWkt={isEditingWkt} handleDelete={() => handleDeleteRest()} />
      ) : null}
    </div>
  )
}

function Timer(props) {
  const { time, type, isEditingWkt, handleDelete } = props
  const timerText = type === 'rest' ? 'Rest Timer' : 'Timer'

  return (
    <div className='flex items-center text-xs text-gray-500 mb-1 last:mb-0'>
      {type === 'stopwatch' && <CgStopwatch className='flex w-4 h-4 mr-1' />}
      {type === 'timer' && <CgTime className='flex w-4 h-4 mr-1' />}
      {type === 'rest' && <IoIosWater className='flex w-4 h-4 mr-1' />}
      {type === 'stopwatch' ? 'Stopwatch' : `${timerText}: ${getPrettyExerciseTime(time)}`}
      {isEditingWkt && (
        <CgTrash
          onClick={() => handleDelete()}
          id='removetimer-action'
          className='hidden cursor-pointer ml-2 w-4 h-4 text-gray-500 hover:text-tRed transition-colors'
        />
      )}
    </div>
  )
}

const interpFor = (data) => {
  const { type, text, remainderText, caretPos } = data

  const hasCmdIntent = getHasCmdIntent(text, remainderText, type)
  const isCaretWithinBounds = getIfCaretWithinBounds(text, caretPos, type)
  const showDropdown = hasCmdIntent && isCaretWithinBounds

  let result = {
    val: null,
    remainderText,
    showDropdown,
  }

  if (hasCmdIntent) {
    let interped = interpCmd(text, remainderText, type)
    if (interped) {
      result.remainderText = interped.remainderText
      result.val = interped.timeString
      result.fullCmdText = interped.fullCmdText
    }
  }

  return result
}

const handleInterpAndCmdDropdown = ({ e, showCmdDropdown, setCmdDropdown }) => {
  const text = e.target.value
  const caretPos = e.target.selectionStart

  if (!text.includes('/')) {
    return { time: '', rest: '' }
  }

  let { showDropdown: showStopWatchDropdown, fullCmdText: stopWatchCmdText } = interpFor({
    type: 'stopwatch',
    text,
    remainderText: text,
    caretPos,
  })

  if (showStopWatchDropdown) {
    setCmdDropdown({
      show: true,
      type: 'stopwatch',
      cmdText: stopWatchCmdText,
    })
  }

  let {
    showDropdown: showRestDropdown,
    val: rest,
    fullCmdText: restCmdText,
    remainderText,
  } = interpFor({
    type: 'rest',
    text,
    remainderText: text,
    caretPos,
  })

  if (showRestDropdown) {
    setCmdDropdown({
      show: true,
      type: 'rest',
      cmdText: restCmdText,
    })
  }

  let {
    showDropdown: showTimeDropdown,
    val: time,
    fullCmdText: timeCmdText,
  } = interpFor({
    type: 'time',
    text,
    remainderText,
    caretPos,
  })

  if (showTimeDropdown) {
    setCmdDropdown({
      show: true,
      type: 'time',
      cmdText: timeCmdText,
    })
  }

  if (!showRestDropdown && !showTimeDropdown && !showStopWatchDropdown && showCmdDropdown) {
    setCmdDropdown({
      show: false,
      type: null,
      cmdText: '',
    })
  }

  return { time, rest }
}

const onSetInstructions = ({ e, setInstructions, setRest, setTime, showCmdDropdown, setCmdDropdown }) => {
  const text = e.target.value
  setInstructions(text)
  const { time, rest } = handleInterpAndCmdDropdown({ e, showCmdDropdown, setCmdDropdown })

  setRest(rest || '')
  setTime(time || '')
}

export default EditExercise
