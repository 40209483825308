import { CgImage } from 'react-icons/cg'

import { VideoModal } from 'common/components/VideoModal/VideoModal'
import { Spinner } from 'common/components/Spinner/Spinner'
import { TableCell } from './styles'

export function PreviewImgTableCell({ isUploading, video }) {
  if (isUploading) {
    return (
      <CellContainer>
        <div className='w-full h-full flex items-center justify-center'>
          <Spinner className='w-5 h-5 text-gray-100 opacity-50' fillClassName='fill-tBlack opacity-50' />
        </div>
      </CellContainer>
    )
  }

  if (video?.previewImg) {
    return (
      <CellContainer onClick={(e) => e.stopPropagation()}>
        <img src={video.previewImg} alt={video.title} className='w-full h-full object-cover' />
        {video?.video && <VideoModal videoName={video.title} videoUrl={video.video} />}
      </CellContainer>
    )
  }

  return (
    <CellContainer>
      <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-7 h-7' />
    </CellContainer>
  )
}

function CellContainer({ children, onClick }) {
  return (
    <TableCell tw='w-1/12'>
      <div
        className='relative w-14 md:w-20 h-14 md:h-20 rounded-lg md:rounded-xl overflow-hidden bg-tBlack ml-2 md:ml-0'
        onClick={onClick}
      >
        {children}
      </div>
    </TableCell>
  )
}
