import { useAuth } from 'modules/Auth/hooks/useAuth'
import React from 'react'

import { useListenUserProfileQuery } from 'modules/Users/userApi'
import {
  useListenExLibEnabledQuery,
  useListenShowExLibLevelsQuery,
  useSetExLibEnabledMutation,
  useSetShowExLibLevelsMutation,
} from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { useListenLinkHubEnabledQuery, useSetLinkHubEnabledMutation } from 'modules/LinkHub/linkHubApi'
import {
  useListenContentFiltersEnabledQuery,
  useSetContentFilterEnabledMutation,
} from 'modules/ContentTags/contentTagsApi'

import { Spinner } from 'common/components/Spinner/Spinner'
import { Switch } from 'common/components/Switch/Switch'
import AccountLayout from './AccountLayout'

export default function Advanced() {
  const { userId } = useAuth()

  const [setExLibEnabled] = useSetExLibEnabledMutation()
  const [setShowExLibLevels] = useSetShowExLibLevelsMutation()
  const [setLinkHubEnabled] = useSetLinkHubEnabledMutation()
  const [setContentFilterEnabled] = useSetContentFilterEnabledMutation()

  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''
  const { data: exLibEnabled } = useListenExLibEnabledQuery({ coachOrgId })
  const { data: showExLibLevels } = useListenShowExLibLevelsQuery({ coachOrgId })
  const { data: linkHubEnabled } = useListenLinkHubEnabledQuery({ coachOrgId })
  const { data: contentFiltersEnabled } = useListenContentFiltersEnabledQuery({ coachOrgId })

  const dataLoading =
    profile === undefined ||
    profile?.isLoading ||
    exLibEnabled === undefined ||
    exLibEnabled?.isLoading ||
    showExLibLevels === undefined ||
    showExLibLevels?.isLoading ||
    linkHubEnabled === undefined ||
    linkHubEnabled?.isLoading ||
    contentFiltersEnabled === undefined ||
    contentFiltersEnabled?.isLoading

  if (dataLoading) {
    return (
      <AccountLayout>
        <Spinner className='w-10 h-10 text-gray-100 m-auto' />
      </AccountLayout>
    )
  }

  return (
    <AccountLayout>
      <div className='mb-12'>
        <h1 className='font-bold text-xl mb-4'>Additional Resources</h1>
        <Switch
          labelClasses='justify-between w-full'
          labelContainerClasses='text-sm'
          onChange={() => setLinkHubEnabled({ coachOrgId, enabled: !linkHubEnabled })}
          label='Display Additional Resources to Users'
          isChecked={linkHubEnabled === true}
        />
      </div>
      <div className='mb-12'>
        <h1 className='font-bold text-xl mb-4'>Exercise Library</h1>
        <Switch
          labelClasses='justify-between w-full'
          labelContainerClasses='text-sm'
          onChange={() => setExLibEnabled({ coachOrgId, enabled: !exLibEnabled })}
          label='Display Exercise Library to Users'
          isChecked={exLibEnabled === true}
        />
        <p className='text-sm text-gray-500 mt-2'>
          Shows a dedicated Exercise Library to users in the app. Exercises will only be shown if they are properly
          tagged with a “Tag” and “Level”.
        </p>
        {exLibEnabled && (
          <div className='mt-8'>
            <Switch
              labelClasses='justify-between w-full'
              labelContainerClasses='text-sm'
              onChange={() => setShowExLibLevels({ coachOrgId, show: !showExLibLevels })}
              label='Display “Level” to Users'
              isChecked={showExLibLevels === true}
            />
            <p className='text-sm text-gray-500 mt-2'>
              Level is used to order the Exercises in the library in ascending order. The Level value can be optionally
              displayed to users.
            </p>
          </div>
        )}
      </div>
      <div>
        <h1 className='font-bold text-xl mb-4'>Content Filters</h1>
        <div className='space-y-4'>
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.difficulty, name: 'difficulty' })
            }
            label='Display Difficulty Filters to Users'
            isChecked={contentFiltersEnabled?.difficulty === true}
          />
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.equipment, name: 'equipment' })
            }
            label='Display Equipment Filters to Users'
            isChecked={contentFiltersEnabled?.equipment === true}
          />
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.instructors, name: 'instructors' })
            }
            label='Display Instructor Filters to Users'
            isChecked={contentFiltersEnabled?.instructors === true}
          />
          <Switch
            labelClasses='justify-between w-full'
            labelContainerClasses='text-sm'
            onChange={() =>
              setContentFilterEnabled({ coachOrgId, enabled: !contentFiltersEnabled?.tags, name: 'tags' })
            }
            label='Display Tag Filters to Users'
            isChecked={contentFiltersEnabled?.tags === true}
          />
        </div>
      </div>
    </AccountLayout>
  )
}
