import tw from 'twin.macro'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { CgOptions } from 'react-icons/cg'

import TagInput from 'common/components/Tag/TagInput'
import { buttonBase, buttonVariants, buttonSizes } from 'common/components/Button/Button'
import FilterCheckbox from './components/FilterCheckbox'

import { getTagsList } from 'modules/ContentTags/contentTagUtils'
import { initialFilterState } from './constants'

export function Filter({ filters, setFilters, difficulty, equipment, instructors, tags }) {
  const activeFilterCount = Object.values(filters).reduce((acc, filter) => (filter === null ? acc : acc + 1), 0)
  const difficultyTags = getTagsList(difficulty)
  const equipmentTags = getTagsList(equipment)
  const instructorTags = getTagsList(instructors)
  const tagsList = getTagsList(tags)

  const handleClearFilters = () => {
    setFilters(initialFilterState)
  }

  return (
    <Popover.Root>
      <Popover.Trigger
        css={[
          buttonBase,
          buttonVariants.tertiary,
          buttonSizes.lg,
          tw`relative px-4 ml-4 md:ml-6 ring-white`,
          activeFilterCount > 0 && tw`ring-tGreen`,
        ]}
      >
        {activeFilterCount > 0 && <FilterCounter>{activeFilterCount}</FilterCounter>}
        <CgOptions className='w-6 h-6 mr-0 md:mr-2' />
        <span className='hidden md:inline'>Filters</span>
      </Popover.Trigger>
      <Popover.Content className='bg-white px-3 py-3 mt-2 rounded-xl w-56 shadow-md' aria-label='Filters'>
        <div className='flex justify-between items-center mb-2'>
          <button
            className='text-tGreen rounded-lg px-2 py-1 hover:bg-tGreen hover:bg-opacity-10'
            onClick={handleClearFilters}
          >
            Clear
          </button>
          <h4 className='font-bold text-tBlack'>Filters</h4>
          <Popover.Close className='text-tGreen font-bold rounded-lg px-2 py-1 hover:bg-tGreen hover:bg-opacity-10 transition-all'>
            Done
          </Popover.Close>
        </div>
        <div className='divide-y divide-gray-200'>
          <FilterCheckbox labelText='Difficulty' name='difficulty' filters={filters} setFilters={setFilters}>
            {filters.difficulty && (
              <div className='flex items-center pt-2'>
                <TagInput
                  activeTags={filters.difficulty}
                  tagsList={difficultyTags}
                  setActiveTags={(difficulty) => {
                    setFilters({ ...filters, difficulty: difficulty })
                  }}
                  isCreateInput={false}
                  placeholder='Add difficulty'
                  id='difficulty'
                  name='difficulty'
                  maxLength={50}
                  autoFocus={true}
                />
              </div>
            )}
          </FilterCheckbox>
          <FilterCheckbox labelText='Equipment' name='equipment' filters={filters} setFilters={setFilters}>
            {filters.equipment && (
              <div className='flex items-center pt-2'>
                <TagInput
                  activeTags={filters.equipment}
                  tagsList={equipmentTags}
                  setActiveTags={(equipment) => {
                    setFilters({ ...filters, equipment: equipment })
                  }}
                  isCreateInput={false}
                  placeholder='Add equipment'
                  id='equipment'
                  name='equipment'
                  maxLength={20}
                  autoFocus={true}
                />
              </div>
            )}
          </FilterCheckbox>
          <FilterCheckbox labelText='Instructors' name='instructors' filters={filters} setFilters={setFilters}>
            {filters.instructors && (
              <div className='flex items-center pt-2'>
                <TagInput
                  activeTags={filters.instructors}
                  tagsList={instructorTags}
                  setActiveTags={(instructor) => {
                    setFilters({ ...filters, instructors: instructor })
                  }}
                  isCreateInput={false}
                  placeholder='Add instructors'
                  id='instructors'
                  name='instructors'
                  maxLength={50}
                  autoFocus={true}
                />
              </div>
            )}
          </FilterCheckbox>
          <FilterCheckbox labelText='Tags' name='tags' filters={filters} setFilters={setFilters}>
            {filters.tags && (
              <div className='flex items-center pt-2'>
                <TagInput
                  activeTags={filters.tags}
                  tagsList={tagsList}
                  setActiveTags={(tags) => {
                    setFilters({ ...filters, tags })
                  }}
                  isCreateInput={false}
                  placeholder='Add tags'
                  id='tags'
                  name='tags'
                  maxLength={50}
                  autoFocus={true}
                />
              </div>
            )}
          </FilterCheckbox>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

const FilterCounter = tw.span`
  absolute inline-flex items-center justify-center -right-1 -top-1 leading-none text-sm text-white w-5 h-5 bg-tGreen rounded-full
`
