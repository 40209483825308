import { globalApi, listenFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { isEmpty } from 'lodash'

import { getUpdatedContent } from './contentTagUtils'

export const contentTagsApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenTags: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/contentTags/tags`, api)
      },
    }),
    updateTagsContent: build.mutation({
      queryFn: async ({ coachOrgId, prevTags, currTags, contentId, programId, contentType }) => {
        const path = `coachOrgs/${coachOrgId}/contentTags/tags`

        if (!contentId) {
          console.error('updateTagsContent mutation contentId is required')
          return { data: null }
        }

        const tagsUpdate = getUpdatedContent({
          prevItems: prevTags,
          currItems: currTags,
          contentId,
          programId,
          contentType,
        })

        if (isEmpty(tagsUpdate)) {
          return { data: null }
        }

        const { data, error } = await updateFirebase(path, tagsUpdate)
        return { data, error }
      },
    }),
    listenInstructors: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/contentTags/instructors`, api)
      },
    }),
    updateInstructorsContent: build.mutation({
      queryFn: async ({ coachOrgId, prevInstructors, currInstructors, contentId, programId, contentType }) => {
        const path = `coachOrgs/${coachOrgId}/contentTags/instructors`

        if (!contentId) {
          console.error('updateInstructorsContent mutation contentId is required')
          return { data: null }
        }

        const instructorsUpdate = getUpdatedContent({
          prevItems: prevInstructors,
          currItems: currInstructors,
          contentId,
          programId,
          contentType,
        })

        if (isEmpty(instructorsUpdate)) {
          return { data: null }
        }

        const { data, error } = await updateFirebase(path, instructorsUpdate)
        return { data, error }
      },
    }),
    listenEquipment: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/contentTags/equipment`, api)
      },
    }),
    updateEquipmentContent: build.mutation({
      queryFn: async ({ coachOrgId, prevEquipment, currEquipment, contentId, programId, contentType }) => {
        const path = `coachOrgs/${coachOrgId}/contentTags/equipment`

        if (!contentId) {
          console.error('updateEquipmentContent mutation contentId is required')
          return { data: null }
        }

        const equipmentUpdate = getUpdatedContent({
          prevItems: prevEquipment,
          currItems: currEquipment,
          contentId,
          programId,
          contentType,
        })

        if (isEmpty(equipmentUpdate)) {
          return { data: null }
        }

        const { data, error } = await updateFirebase(path, equipmentUpdate)
        return { data, error }
      },
    }),
    listenDifficulty: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/contentTags/difficulty`, api)
      },
    }),
    updateDifficultyContent: build.mutation({
      queryFn: async ({ coachOrgId, prevDifficulty, currDifficulty, contentId, programId, contentType }) => {
        const path = `coachOrgs/${coachOrgId}/contentTags/difficulty`

        if (!contentId) {
          console.error('updateDifficultyContent mutation contentId is required')
          return { data: null }
        }

        const difficultyUpdate = getUpdatedContent({
          prevItems: prevDifficulty,
          currItems: currDifficulty,
          contentId,
          programId,
          contentType,
        })

        if (isEmpty(difficultyUpdate)) {
          return { data: null }
        }

        const { data, error } = await updateFirebase(path, difficultyUpdate)
        return { data, error }
      },
    }),
    listenContentFiltersEnabled: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        await listenFirebase(`coachOrgs/${coachOrgId}/mobilePreMountSettings/contentFilters`, api)
      },
    }),
    setContentFilterEnabled: build.mutation({
      queryFn: async ({ coachOrgId, enabled, name }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/mobilePreMountSettings/contentFilters/${name}`,
          enabled
        )
        return { data, error }
      },
    }),
  }),
})

export const {
  useListenTagsQuery,
  useUpdateTagsContentMutation,
  useListenInstructorsQuery,
  useUpdateInstructorsContentMutation,
  useListenEquipmentQuery,
  useUpdateEquipmentContentMutation,
  useListenDifficultyQuery,
  useUpdateDifficultyContentMutation,
  useListenContentFiltersEnabledQuery,
  useSetContentFilterEnabledMutation,
} = contentTagsApi
