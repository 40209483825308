import { createUID } from 'common/utils/createUID'
import { removeInvalidFirebaseChars } from 'common/utils/fileUploading/nameAndIdUtils'
import { getStandardizedName } from 'common/utils/stringUtils'
import { each, isEmpty } from 'lodash'
import { EMPTY_TIME_STRING } from './timeUtils'

const regRestIntent = /\/(rest|res|re|r)( )?(\d|:)?/i //is typing /r..e..s..t
const regFullRest = /\/(rest|res|re|r)( )?(\d{0,2})?(:)?\d{0,2}/i
const regStopWatchIntent = /\/(stopwatch|stopwatc|stopwat|stopwa|stopw|stop|sto|st|s)/i //is typing /s..t..o..p..w..a..t..c..h
const regColonTime = /(\d{0,2})?:(\d{1,2})?/i

//requires ":"
const regFullRestStrict = /\/(rest|res|re|r)( )?(\d{0,2})?(:)\d{0,2}/gi

const regTimeIntent = /\/(\d|:)?/i //is typing /[digit]
//at least 1 number then :, so not overly aggressively
//to make room for typing rest cmd
const regTypingTime = /\/(\d{1,2})(:)?\d{0,2}/

//captures full time text, requires ":"
//when removing time commands from exercise.instructions
const regFullTimeStrict = /\/(\d{0,2})(:)\d{0,2}/
const regTimeSecOnly = /\/(:)\d{0,2}/

const regMultiSpaces = / +(?= )/g

export const getHasCmdIntent = (inputText, remainderText, type) => {
  //if user is not typing /r or /[digit], then return false

  const textToUse = remainderText || inputText
  let regIntentTest

  if (type === 'rest') {
    regIntentTest = regRestIntent
  } else if (type === 'time') {
    regIntentTest = regTimeIntent
  } else if (type === 'stopwatch') {
    regIntentTest = regStopWatchIntent
  }

  const intentForCmd = regIntentTest.test(textToUse)

  if (intentForCmd) {
    return true
  } else {
    return false
  }
}

export const getIfCaretWithinBounds = (inputText, caretPos, type) => {
  //check if caret is within the bounds of the command text
  //ie. if user enters space after "/rest 0:00", then return false
  let fullCmdCatch
  if (type === 'rest') {
    fullCmdCatch = findMatchGlobally(inputText, regFullRest)
  } else if (type === 'time') {
    fullCmdCatch = findMatchGlobally(inputText, regTypingTime) || findMatchGlobally(inputText, regTimeSecOnly)
  } else if (type === 'stopwatch') {
    fullCmdCatch = findMatchGlobally(inputText, regStopWatchIntent)
  }

  if (!fullCmdCatch) return false

  const cmdStartIdx = fullCmdCatch.index
  const relevantText = fullCmdCatch[0]
  const cmdEndIdx = cmdStartIdx + relevantText.length

  if (caretPos >= cmdStartIdx && caretPos <= cmdEndIdx) {
    return true
  } else {
    return false
  }
}

//solve issue where user types "20 arms/rep /r1:00" and rest not caught
const findMatchGlobally = (textToUse, regex) => {
  let count = 0
  let match
  let matches = []

  const regGlobal = new RegExp(regex, 'g')

  while ((match = regGlobal.exec(textToUse)) !== null && count < 10) {
    count++
    matches.push(match)
  }

  //if multiple matches, use the one that has a number
  if (matches.length > 1) {
    let matchToUse

    const hasNumber = (match) => {
      return /\d/.test(match)
    }

    each(matches, (match) => {
      if (hasNumber(match) && !matchToUse) {
        matchToUse = match
      }
    })

    return matchToUse
  } else {
    return matches[0]
  }
}

export const interpCmd = (inputText, remainderText, type) => {
  let result = {
    timeString: null,
    hour: null,
    min: null,
    sec: null,
    hundredths: null,
    fullCmdText: null,
    remainderText: null,
  }

  const textToUse = remainderText || inputText
  let fullCatch

  if (type === 'rest') {
    fullCatch = findMatchGlobally(textToUse, regFullRest)
  } else if (type === 'time') {
    fullCatch = findMatchGlobally(textToUse, regTypingTime) || findMatchGlobally(textToUse, regTimeSecOnly)
  } else if (type === 'stopwatch') {
    fullCatch = findMatchGlobally(textToUse, regStopWatchIntent)
  }

  if (!fullCatch) {
    return result
  }

  const fullCmdText = fullCatch[0]

  let textToRemove
  if (type === 'rest') {
    textToRemove = '/' + fullCatch[1]
  } else if (type === 'time') {
    textToRemove = '/'
  } else if (type === 'stopwatch') {
    textToRemove = '/' + fullCatch[1]
  }

  let cmdTimePortion = fullCmdText.replace(textToRemove, '')
  cmdTimePortion = cmdTimePortion.trim()

  result.fullCmdText = fullCmdText
  result.remainderText = textToUse.replace(fullCmdText, '')

  if (type === 'stopwatch') {
    return result
  }

  const timeCmdToNum = Number(cmdTimePortion)
  //if user has entered a colon, convert that to time
  if (regColonTime.test(cmdTimePortion)) {
    result = getMinAndSec(cmdTimePortion, result)
    result = getTimeResults(result)
    return result
  } else if (Number.isInteger(timeCmdToNum) && cmdTimePortion.length < 3) {
    //if user typed in 1-2 numbers, convert that to min
    result.min = timeCmdToNum
    result = getTimeResults(result)
    return result
  }
}

const getMinAndSec = (text, resultPassed) => {
  const result = { ...resultPassed }
  let timeCatch = regColonTime.exec(text)

  if (timeCatch) {
    let minCatch = timeCatch[1] || '00'
    let secCatch = timeCatch[2] || '00'

    result.min = minCatch
    result.sec = secCatch
    result.timeString = getTimeString(result)
  }

  return result
}

const getTimeResults = (resultPassed) => {
  let result = { ...resultPassed }

  if (result.sec && Number(result.sec) > 59) {
    let addlMin = Math.floor(result.sec / 60)
    result.min = (Number(result.min) + addlMin).toString()
    result.sec = (result.sec % 60).toString()
  }

  if (result.min && Number(result.min) > 59) {
    result.min = '59'
  }

  result.timeString = getTimeString(result)

  return result
}

const getTimeString = (timeGiven = {}) => {
  let timeString = EMPTY_TIME_STRING
  let time = { ...timeGiven }
  let hour, min, sec, hundredths

  //for now, do not allow hours
  hour = '00'
  timeString = hour + timeString.slice(2)

  min = time.min
  //if min is less than 2 digits, add digits
  if (!min) min = '00'
  if (String(min).length === 1) min = '0' + min
  timeString = timeString.slice(0, 3) + min + timeString.slice(5)

  sec = time.sec
  //if sec is less than 2 digits, add digits
  if (!sec) sec = '00'
  if (String(sec).length === 1) sec = sec + '0'
  timeString = timeString.slice(0, 6) + sec + timeString.slice(8)

  hundredths = time.hundredths
  if (!hundredths) hundredths = '00'
  timeString = timeString.slice(0, 9) + hundredths

  return timeString
}

export const getHasCmd = ({ instructions }) => {
  const hasRestCmd = regFullRestStrict.test(instructions)
  const hasTimeCmd = regFullTimeStrict.test(instructions)

  if (hasRestCmd || hasTimeCmd) {
    return true
  } else {
    return false
  }
}

export const removeCmdsAndMultiSpacesFromInstr = (instr) => {
  if (!instr) return instr

  let result = instr
  result = result.replace(regFullRestStrict, '')
  result = result.replace(regFullTimeStrict, '')
  result = result.replace(regMultiSpaces, '')

  return result
}

export const getSuggestedExercises = (exerciseList, exerciseName) => {
  if (!exerciseList && isEmpty(exerciseList)) {
    return []
  }

  const suggestedExercises = Object.entries(exerciseList).filter(([exId, exercise]) => {
    const stdExName = getStandardizedName(exercise.name)
    return stdExName?.includes(exerciseName.toLowerCase().trim())
  })

  return suggestedExercises
}

export const getReadableExId = (name) => {
  const validName = name && typeof name === 'string' ? removeInvalidFirebaseChars(name) : ''
  const uid = createUID()
  const newId = `${validName}${uid}-` // Arbitary "-" string because errors can occur if it its an "s", due to mobile app parsing
  const lowercaseId = newId.toLowerCase() // Must be lowercase as mobile app does not match to capital properly right now

  return lowercaseId
}

export const getExHasVideo = ({ exId, exName, exNameToId, exIdHasVideo }) => {
  let exHasVideo = exIdHasVideo[exId]

  // If exercise has no ID, fallback to checking by standartized name
  if (!exId) {
    const nameStateStd = getStandardizedName(exName)
    const exIdByStdName = exNameToId[nameStateStd]
    exHasVideo = exIdHasVideo[exIdByStdName]
  }

  return exHasVideo
}
