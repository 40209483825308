import React from 'react'
import { CgInfo } from 'react-icons/cg'
import { Tooltip } from '../Tooltip/Tooltip'

export function Switch({
  name,
  label,
  register,
  isChecked,
  onChange,
  tooltipText,
  labelClasses,
  labelContainerClasses,
}) {
  return (
    <div className='flex items-center'>
      <label htmlFor={name} className={`flex items-center cursor-pointer ${labelClasses || ''}`}>
        <div className={`mr-5 text-gray-700 font-semibold flex items-center ${labelContainerClasses || ''}`}>
          <span>{label}</span>
          {Boolean(tooltipText) && (
            <Tooltip content={tooltipText} triggerClasses='ml-1'>
              <CgInfo className='w-4 h-4 cursor-pointer text-gray-500 hover:text-tGreen' />
            </Tooltip>
          )}
        </div>
        <div className='relative'>
          {register ? (
            <input {...register(name)} id={name} type='checkbox' className='hidden h-0 w-0' />
          ) : (
            <input
              onChange={onChange}
              checked={isChecked}
              id={name}
              name={name}
              aria-label={name}
              type='checkbox'
              className='hidden h-0 w-0'
            />
          )}
          <div className='block bg-tGray-light w-14 h-8 rounded-full'></div>
          <div
            className={`absolute left-1 top-1 w-6 h-6 rounded-full ${
              isChecked ? 'translate-x-full bg-tGreen' : 'translate-x-0 bg-white'
            } transition`}
          ></div>
        </div>
      </label>
    </div>
  )
}
