import 'twin.macro'
import React, { useState, useEffect, useRef } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { CgChevronDown } from 'react-icons/cg'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateProgressionMutation, useRemoveProgressionMutation } from '../progressionsApi'
import { schema } from 'modules/Progressions/ProgressionForm/schema'
import { useDialog } from 'common/components/Dialog/hooks/useDialog'
import { Input } from 'common/components/Input/Input'
import { UpdateActions } from 'common/components/UpdateActions/UpdateActions'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { DeleteConfirmationBanner } from 'common/components/DeleteConfirmationBanner/DeleteConfirmationBanner'
import { ExerciseInputList } from 'common/components/ExerciseInputList/ExerciseInputList'
import { checkProgressionNameExists } from './utils'
import { isEqual } from 'lodash'
import { removeEmptyExercises } from './utils'
import { useFormRefsControl } from 'common/components/RefsControl/FormRefsControl/useFormRefsControl'
import { validFirebaseCharRegex } from 'common/utils/fileUploading/validators'
import { useEventListener } from 'common/hooks/useEventListener'

export function ProgressionForm({ coachOrgId, exercises = [], progressionKey = '', progressions, exerciseList }) {
  const [createProgression] = useCreateProgressionMutation()
  const [deleteProgression] = useRemoveProgressionMutation()

  const [, setDialogOpen] = useDialog()
  const { createAlert } = useAlert()

  const [loading, setLoading] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [accordionValue, setAccordionValue] = useState('formExercises')

  const defaultValues = {
    progressionName: progressionKey,
    exercises,
  }
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    context: { progressions, progressionKey, exerciseList },
  })

  const {
    watch,
    setError,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const formState = watch()

  const onSubmit = async (data) => {
    const trimmedFormData = {
      ...data,
      progressionName: data.progressionName.trim().toLowerCase(),
      exercises: data.exercises,
    }

    const progressionNameExists = checkProgressionNameExists({
      progressions,
      progressionName: trimmedFormData.progressionName,
      progressionKey,
    })
    if (progressionNameExists) {
      setError(
        'progressionName',
        { type: 'manual', message: 'Progression with this name already exists' },
        { shouldFocus: true }
      )
      return
    }

    trimmedFormData.exercises = removeEmptyExercises(trimmedFormData.exercises)

    const alertText = progressionKey ? 'Progression updated!' : 'Progression created!'
    // When editing existing progression name, remove previous progression, create new
    if (progressionKey && progressionKey !== trimmedFormData.progressionName) {
      setLoading(true)
      await deleteProgression({ coachOrgId, progressionKey })
      await createProgression({
        coachOrgId,
        progressionKey: trimmedFormData.progressionName,
        exercises: trimmedFormData.exercises,
      })
      setLoading(false)
      createAlert({ text: alertText, type: 'success' })
      setDialogOpen(false)
    }

    setLoading(true)
    await createProgression({
      coachOrgId,
      progressionKey: trimmedFormData.progressionName,
      exercises: trimmedFormData.exercises,
    })
    setLoading(false)
    createAlert({ text: alertText, type: 'success' })
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    await deleteProgression({ coachOrgId, progressionKey })
    setLoading(false)
    createAlert({ text: 'Progression deleted!', type: 'success' })
    setDialogOpen(false)
  }

  // refs control
  const { inputRefs, moveFocusedInputBy, moveFocusedInputTo, addInputRef } = useFormRefsControl()

  const nameRef = useRef()
  const submitRef = useRef()
  const sortMethod = ['name', 'exercise', 'submit']

  useEffect(() => {
    addInputRef({ ref: nameRef, posIdx: 0, name: 'name', sortMethod })
    addInputRef({ ref: submitRef, posIdx: formState.exercises.length + 2, name: 'submit', sortMethod })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keydown', (e) => {
    if (e.code === 'Tab') e.preventDefault()
  })

  return (
    <FormProvider {...methods}>
      <div className='divide-y divide-gray-200 overflow-auto'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='progressionForm'
          name='Progression Form'
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault() //Otherwise form autosubmits on each enter press
            }
          }}
        >
          <div className='flex flex-col px-4 md:px-10 py-4 border-b border-gray-200'>
            <div className='mb-2'>
              <label htmlFor='progressionName' className='inline-flex cursor-pointer font-semibold text-tBlack mb-1'>
                Progression name
              </label>
              <Input
                name='progressionName'
                type='text'
                placeholder='Enter progression name'
                register={register}
                inputRef={nameRef}
                error={errors?.progressionName?.message}
                onKeyDown={(e) => {
                  const validFirebaseChar = validFirebaseCharRegex.test(e.key)
                  if (!validFirebaseChar) {
                    e.preventDefault()
                  }
                  if ((e.code === 'Tab' || e.code === 'Enter') && !e.shiftKey && accordionValue === '') {
                    setAccordionValue('formExercises')
                  }
                }}
                onKeyUp={(e) => {
                  if (e.code === 'Tab' || e.code === 'Enter') {
                    if (e.shiftKey) {
                      moveFocusedInputTo(inputRefs.length - 1)
                    } else if (!e.shiftKey) {
                      moveFocusedInputBy(1)
                    }
                  }
                }}
                autoFocus={true}
              />
              {errors.progressionName && (
                <p className='flex text-xs mt-1 text-tRed'>{errors.progressionName.message}</p>
              )}
            </div>
          </div>
          <Accordion.Root
            type='single'
            value={accordionValue}
            onValueChange={setAccordionValue}
            collapsible='true'
            className='py-4'
          >
            <Accordion.Item value='formExercises'>
              <Accordion.Header>
                <Accordion.Trigger type='button' className='flex items-center w-full group px-4 md:px-10' tabIndex={-1}>
                  <label htmlFor='exercises' className='inline-flex cursor-pointer font-semibold text-tBlack my-2'>
                    Exercises
                  </label>
                  <CgChevronDown className='w-6 h-6 ml-auto group-radix-state-open:-rotate-180 transition-all' />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <ExerciseInputList
                  exerciseList={exerciseList}
                  progressions={progressions}
                  progressionKey={progressionKey}
                  inputRefsSortMethod={sortMethod}
                />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </form>
      </div>
      {progressionKey && deleteConfirmation ? (
        <DeleteConfirmationBanner
          text='Are you sure?'
          handleDelete={handleDelete}
          handleGoBack={() => setDeleteConfirmation(false)}
          loading={loading}
        />
      ) : (
        <UpdateActions
          itemKey={progressionKey}
          handleSubmit={handleSubmit}
          handleDelete={() => setDeleteConfirmation(true)}
          loading={loading}
          disabled={isEqual(formState, defaultValues)}
          hideDelete={!progressionKey}
          actionText={progressionKey ? 'Save' : 'Create'}
          onKeyUp={(e) => {
            if (e.code === 'Enter') {
              handleSubmit(onSubmit)
            } else if (e.code === 'Tab') {
              if (e.shiftKey) {
                moveFocusedInputBy(-1)
              }
            }
          }}
          onKeyDown={(e) => {
            if (e.code === 'Tab') {
              if (e.shiftKey && accordionValue === '') {
                setAccordionValue('formExercises')
              } else if (!e.shiftKey) {
                // Timeout is needed because keyUp event is triggered in progressionName input
                // and progressionName input is skipped immediately after tabbing to it from submitBtn
                // e.stopPropagation and e.preventDefault does not prevent event from firing
                setTimeout(() => {
                  moveFocusedInputTo(0)
                }, 200)
              }
            }
          }}
          form='progressionForm'
          ref={submitRef}
        />
      )}
    </FormProvider>
  )
}
