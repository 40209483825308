import tw, { styled, css } from 'twin.macro'
import React, { useState, Fragment, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Combobox } from '@headlessui/react'
import * as Popover from '@radix-ui/react-popover'
import { useForm, FormProvider } from 'react-hook-form'
import { CgImage } from 'react-icons/cg'
import { FaPlay } from 'react-icons/fa'
import { TbVideoOff } from 'react-icons/tb'
import { IoIosCloseCircle } from 'react-icons/io'

import { useUpdateWorkoutMutation } from '../programApi'
import { useListenCoachVideosQuery } from 'modules/VideoLibrary/videoLibraryApi'
import { useUpdateVideoMutation } from 'modules/VideoLibrary/videoLibraryApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'

import { videoQueryParam } from 'modules/VideoViewer/constants/queryParamConstants'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { SuggestionContainer, SuggestionItem } from 'common/components/Suggestions/Suggestions'
import exerciseFallbackImg from 'modules/Programs/assets/exercise-fallback-img.svg'
import { createUID } from 'common/utils/createUID'
import { useRefsControl } from 'common/components/RefsControl/WorkoutRefsControl/useRefsControl'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { getStandardizedName } from 'common/utils/stringUtils'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import { Spinner } from 'common/components/Spinner/Spinner'

export function WorkoutVideo({ orgId, workout, isEditing }) {
  const { data: videoData, isLoading } = useListenCoachVideosQuery({ coachOrgId: orgId })
  const videosLoading = isLoading || videoData?.isLoading
  const videos = videosLoading || videoData === null ? {} : videoData
  const { videoId } = workout
  const vid = videos?.[videoId]

  const videoTitle = vid?.title || workout.videoTitle || ''

  const [wktVideoTitle, setWktVideoTitle] = useState(videoTitle)
  const [wktVideoId, setWktVideoId] = useState(workout.videoId || '')

  const { workoutId: isLargeView } = useParams()

  const [updateWorkout] = useUpdateWorkoutMutation({
    fixedCacheKey: isLargeView ? 'largeview-shared-update-workout' : null,
  })

  const debWktVideoTitleValue = useDebounce(wktVideoTitle, 200)
  useDebouncedTextMutation({
    stateText: wktVideoTitle,
    dbText: workout.videoTitle,
    mutation: updateWorkout,
    debouncedStateText: debWktVideoTitleValue,
    mutationArgs: {
      orgId,
      workoutId: workout.id,
      workout: {
        videoId: wktVideoId,
        videoTitle: debWktVideoTitleValue,
      },
    },
  })

  // When video deleted from videoLibrary, delete workout videoId
  useEffect(() => {
    if (!videosLoading && workout.videoId && !videos?.[workout.videoId]) {
      updateWorkout({
        orgId,
        workoutId: workout.id,
        workout: {
          videoId: null,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos])

  if (videosLoading || !videos) {
    return null
  }

  return (
    <div className='flex flex-col w-full px-3 mt-3.5'>
      <div className='mb-3'>
        <WorkoutVideoInput
          wktVideoId={wktVideoId}
          setWktVideoId={setWktVideoId}
          wktVideoTitle={wktVideoTitle}
          setWktVideoTitle={setWktVideoTitle}
          isEditing={isEditing}
          allOrgVids={videos}
          orgId={orgId}
          workout={workout}
        />
      </div>
      <SelectedVidCard className='flex items-center mt-2 mb-8 border rounded-md p-3 relative'>
        <Thumbnail vid={vid} />
        <div className='flex-1 flex flex-col ml-3'>
          <VidNameAndTime vid={vid} />
        </div>
        {videoId && (
          <div
            id='cancel-selected-vid'
            className='absolute hidden top-[-12px] right-[-12px]'
            onClick={(e) => clearSelectedVid(e)}
          >
            <IoIosCloseCircle className='cursor-pointer w-6 h-6 text-gray-500 hover:text-gray-600 transition-colors' />
          </div>
        )}
      </SelectedVidCard>
    </div>
  )

  function clearSelectedVid(e) {
    e.preventDefault()
    e.stopPropagation()

    setWktVideoId('')
    setWktVideoTitle('')
  }
}

export const SelectedVidCard = styled.div(
  css`
    &:hover #video-img-placeholder {
      display: none;
    }
    &:hover #cancel-selected-vid {
      display: block;
    }
  `
)

function WorkoutVideoInput({
  wktVideoId,
  setWktVideoId,
  wktVideoTitle,
  setWktVideoTitle,
  isEditing,
  allOrgVids,
  workout,
  orgId,
}) {
  const { setActiveInput } = useRefsControl()

  const inputRef = useRef()

  const suggestedVideos = allOrgVids
    ? Object.entries(allOrgVids).filter(([_, data]) =>
        getStandardizedName(data.title)?.includes(getStandardizedName(wktVideoTitle))
      )
    : []

  const videoLibTitle = allOrgVids[workout.videoId]?.title

  const isWktVideoSelected =
    workout.videoId && getStandardizedName(wktVideoTitle) === getStandardizedName(videoLibTitle)

  // If video title was changed in video library, change it in program
  // Effect changes wktVideoTitle and debouncedTextMutation runs
  const videoTitlesDiffer = getStandardizedName(videoLibTitle) !== getStandardizedName(workout.videoTitle)

  useEffect(() => {
    if (workout.videoId && videoTitlesDiffer) {
      setWktVideoTitle(videoLibTitle || workout.videoTitle || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTitlesDiffer])

  // Need this effect to forcefully setState because of bug in daylargeview
  // wktVideoId and wktVideoTitle can be inherited from previous day video workout
  // probably related to useListenVideoLibraryQuery and RTK query caching
  useEffect(() => {
    setWktVideoId(workout.videoId || '')
    setWktVideoTitle(videoLibTitle || workout.videoTitle || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout.videoId, videoLibTitle])

  return (
    <Combobox
      value={{ wktVideoId }}
      onChange={(videoId) => handleVideoSelect({ videoId, videoTitle: allOrgVids[videoId]?.title })}
      nullable
    >
      <div className='flex relative w-full'>
        <Combobox.Input
          placeholder='Search video'
          autoComplete='off'
          displayValue={() => wktVideoTitle}
          onChange={handleInputChange}
          css={inputClasses}
          className={`text-ellipsis bg-inherit ${isEditing ? 'cursor-auto' : 'cursor-pointer'}`}
          ref={inputRef}
          onFocus={() => setActiveInput(inputRef)}
        />
        <UploadVideo
          workout={workout}
          wktVideoTitle={wktVideoTitle}
          orgId={orgId}
          isEditing={isEditing}
          isWktVideoSelected={isWktVideoSelected}
        />
        {wktVideoTitle && suggestedVideos?.length > 0 && <VideoSuggestions suggestedVideos={suggestedVideos} />}
      </div>
    </Combobox>
  )

  function handleVideoSelect({ videoId, videoTitle }) {
    if (videoId && videoTitle) {
      setTimeout(() => {
        inputRef.current.focus()
      }, 100)
    }

    setWktVideoTitle(videoTitle || '')
    setWktVideoId(videoId || '')
  }

  function handleInputChange(e) {
    const title = e.target.value
    setWktVideoTitle(title)

    const stdTitle = getStandardizedName(title)
    const allOrgVidsValues = Object.values(allOrgVids)
    const existingVideo = allOrgVidsValues.find((videoInfo) => getStandardizedName(videoInfo.title) === stdTitle)
    const videoId = existingVideo?.id || ''

    setWktVideoId(videoId)
  }
}

const inputClasses = tw`font-medium text-sm focus:ring-0 p-1 capitalize w-full 
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400 
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded
`

export function VideoSuggestions({ suggestedVideos, containerClasses }) {
  const suggestionStart = 0
  const suggestionEnd = 30

  return (
    <Combobox.Options as={Fragment}>
      <SuggestionContainer containerClasses={containerClasses ? containerClasses : '!top-9'}>
        {suggestedVideos.slice(suggestionStart, suggestionEnd).map(([suggestedVidId, suggestedVidData]) => {
          return (
            <Combobox.Option as={Fragment} key={suggestedVidId} value={suggestedVidId}>
              {({ active }) => (
                <SuggestionItem active={active}>
                  <div className='flex items-center'>
                    <div className='flex items-center flex-1'>
                      <div className='relative flex h-8 w-10 rounded overflow-hidden bg-tBlack'>
                        {suggestedVidData.previewImg ? (
                          <object
                            data={suggestedVidData.previewImg}
                            type='image/jpeg'
                            className='w-full h-full object-cover'
                          >
                            <img
                              src={exerciseFallbackImg}
                              title='Video thumbnail not available'
                              alt='Video thumbnail not available'
                              className='w-full h-full object-cover'
                              style={{
                                imageRendering: 'pixelated',
                              }}
                            />
                          </object>
                        ) : (
                          <CgImage className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-4 h-4' />
                        )}
                      </div>
                      <span className='ml-3 text-tBlack font-medium text-sm capitalize'>{suggestedVidData.title}</span>
                    </div>
                  </div>
                </SuggestionItem>
              )}
            </Combobox.Option>
          )
        })}
      </SuggestionContainer>
    </Combobox.Options>
  )
}

function UploadVideo({ wktVideoTitle, orgId, isWktVideoSelected, isEditing, workout }) {
  const isWktVideoTitleEmpty = !wktVideoTitle || !wktVideoTitle?.trim()

  const videoIdRef = useRef(createUID())
  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId: orgId, id: videoIdRef.current })
  const isUploading = isUploadingAssets(assetDrafts)

  // When new video was uploaded or selected workout was changed, reset videoId used for uploads
  useEffect(() => {
    if (!isUploading && isWktVideoSelected) {
      videoIdRef.current = createUID()
    }
  }, [isWktVideoSelected, isUploading])

  const [updateVideo] = useUpdateVideoMutation()
  const [updateWorkout] = useUpdateWorkoutMutation()

  const defaultValues = {
    title: wktVideoTitle,
    type: 'video',
    subtitle: '',
    video: '',
    description: '',
    tags: [],
  }

  const methods = useForm({
    defaultValues,
  })

  const {
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = methods

  if (isWktVideoTitleEmpty && !isEditing) {
    return (
      <Tooltip
        content='Name required to upload video'
        triggerClasses='flex items-center justify-center cursor-pointer ml-1'
      >
        <button tabIndex={-1} className='cursor-not-allowed' disabled={true} data-testid='addVideoBtn'>
          <TbVideoOff className='w-[18px] h-[18px] text-red-300' data-testid='addVideoIcon' />
        </button>
      </Tooltip>
    )
  }

  if ((!isWktVideoTitleEmpty && !isWktVideoSelected) || isUploading) {
    return (
      <Popover.Root>
        <Tooltip
          content={isUploading ? 'Video uploading' : 'Upload video'}
          triggerClasses='flex items-center justify-center ml-1'
        >
          <Popover.Trigger asChild={true}>
            <button
              tabIndex={-1}
              onClick={(e) => {
                e.stopPropagation()
              }}
              data-testid='addVideoBtn'
            >
              {isUploading ? (
                <Spinner className='w-4 h-4 text-gray-100 mb-[2px] mr-[2px]' />
              ) : (
                <TbVideoOff className='w-[18px] h-[18px] text-red-400' data-testid='addVideoIcon' />
              )}
            </button>
          </Popover.Trigger>
        </Tooltip>
        <Popover.Content
          align='end'
          alignOffset={-7}
          className='bg-white px-4 py-4 rounded-xl w-96 shadow-2xl border-2 border-gray-300'
          onClick={(e) => e.stopPropagation()} // To prevent closing of the popover because of focusing logic
        >
          <Popover.Arrow offset={12} className='fill-gray-300' />
          <div className='flex justify-between mb-7'>
            <h4 className='font-bold text-tBlack capitalize self-center'>{wktVideoTitle}</h4>
            <Popover.Close css={popoverBtnClasses}>Close</Popover.Close>
          </div>
          <FormProvider {...methods}>
            <form>
              <UploadInput
                name='video'
                label='Upload Video'
                id={videoIdRef.current}
                coachOrgId={orgId}
                register={register}
                setValue={setValue}
                uploadType='video-library'
                setError={setError}
                clearErrors={clearErrors}
                onUpload={handleChange}
                showPreview={false}
                customFileSizeLimit={5000000000} //5.0gb for large video files
                fileType='video'
              />
              {errors?.video ? <InputError>{errors.video.message}</InputError> : null}
            </form>
          </FormProvider>
          {isUploading && (
            <p className='text-xs text-gray-500 mt-4'>
              * Feel free to close this box while the video is being uploaded.
            </p>
          )}
        </Popover.Content>
      </Popover.Root>
    )
  }

  return null

  async function handleChange(e) {
    if (workout.videoId !== videoIdRef.current) {
      await updateVideo({
        coachOrgId: orgId,
        videoKey: videoIdRef.current,
        video: { ...defaultValues, id: videoIdRef.current },
      })
      await updateWorkout({
        orgId,
        workoutId: workout.id,
        workout: {
          videoId: videoIdRef.current,
        },
      })
    }
  }
}

const InputError = tw.p`flex text-xs mt-1 text-tRed`

const popoverBtnClasses = tw`
  text-tGreen rounded-lg px-2 py-1
  hover:bg-tGreen hover:bg-opacity-10 transition-all whitespace-nowrap ml-4 self-start
`

export const Thumbnail = ({ vid }) => {
  if (vid && vid.video) {
    return (
      <Link
        css={[tw`relative h-10 w-12 rounded overflow-hidden`, vid.previewImg && tw`cursor-pointer`]}
        to={`/videoviewer?${videoQueryParam}=${vid.id}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <div css={[tw`absolute bg-tBlack w-full h-full`, vid.previewImg ? tw`opacity-20` : tw`!opacity-100`]} />
        {vid.previewImg ? (
          <img alt={`${vid.title}`} src={vid.previewImg} className='w-full h-full object-cover' />
        ) : (
          <CgImage
            id='video-img-placeholder'
            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-5 h-5'
          />
        )}
        <FaPlay
          id='video-play-action'
          className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white w-3 h-3'
        />
      </Link>
    )
  }

  return (
    <div className='relative h-10 w-12 rounded overflow-hidden' data-testid='noVideoThumb'>
      <div className='absolute bg-tBlack w-full h-full' />
      <TbVideoOff className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-50 w-5 h-5' />
    </div>
  )
}

export const VidNameAndTime = ({ vid }) => {
  if (!vid) {
    return <span className='text-xs text-gray-500 italic'>No video</span>
  }

  let timeInMinutes
  if (!vid.duration) {
    timeInMinutes = 0
  } else {
    const [hours, minutes, secondsWithMs] = vid.duration.split(':')
    const roundedSecToMin = parseInt(secondsWithMs) > 0 ? 1 : 0
    timeInMinutes = parseInt(hours) * 60 + parseInt(minutes) + roundedSecToMin
  }

  return (
    <>
      {timeInMinutes ? (
        <div className='text-xs text-gray-600 font-medium'>{timeInMinutes} min</div>
      ) : (
        <div className='text-xs text-gray-600 font-medium'>Uploading...</div>
      )}
      <div className='text-xs text-gray-600 font-light mt-1.5'>Video</div>
    </>
  )
}
