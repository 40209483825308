import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { sortBy } from 'lodash'

import { useAuth } from 'modules/Auth/hooks/useAuth'
import { useListenUserProfileQuery } from 'modules/Users/userApi'
import { useListenProductPricingQuery, useListenCouponsQuery, useListenStripeProductIdQuery } from '../productInfoApi'

import ProductInfoLayout from '../ProductInfoLayout'
import PricingList from '../components/PricingList/PricingList'

export default function ProductInfoPricing() {
  const navigate = useNavigate()
  const { userId } = useAuth()

  const { data: profile } = useListenUserProfileQuery({ userId })
  const coachOrgId = profile?.coachOrgId || ''

  const { data: plansData } = useListenProductPricingQuery({ coachOrgId })
  const productPlansLoading = plansData === undefined || plansData?.isLoading
  const productPlans = productPlansLoading || !plansData ? {} : plansData
  const sortedProductPlans = sortBy(productPlans, (plan) => plan.idx)

  const { data: stripeProductId } = useListenStripeProductIdQuery({ coachOrgId })
  const stripeProductIdLoading = stripeProductId === undefined || stripeProductId?.isLoading
  // Prefetch coupons for pricing form
  const { data: coupons } = useListenCouponsQuery({ coachOrgId })
  const couponsLoading = coupons === undefined || coupons?.isLoading

  const isLoading = productPlansLoading || couponsLoading || stripeProductIdLoading

  useEffect(() => {
    if (!isLoading && !stripeProductId) {
      navigate('/productinfo')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, stripeProductId])

  return (
    <ProductInfoLayout hasStripeProductId={Boolean(stripeProductId)}>
      <PricingList coachOrgId={coachOrgId} coupons={coupons} plans={sortedProductPlans} loading={isLoading} />
    </ProductInfoLayout>
  )
}
