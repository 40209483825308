import tw from 'twin.macro'
import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { CgTrash, CgCopy, CgMoreAlt } from 'react-icons/cg'
import { FiMove } from 'react-icons/fi'
import * as Popover from '@radix-ui/react-popover'

import { useSavePartsMutation, useUpdatePartMutation } from '../programApi'

import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { partsCopied, partSelected, partsMultiSelected } from 'modules/Programs/programSlice'
import { Button } from 'common/components/Button/Button'
import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import { useRefsControl } from 'common/components/RefsControl/WorkoutRefsControl/useRefsControl'
import { PartPopoverActions } from './PartPopoverActions'

export function PartHeader(props) {
  const {
    part,
    partIdx,
    createAlert,
    isPartSelected,
    setIsPartSelectedState,
    workout,
    attributes,
    listeners,
    orgId,
    setActivePartIdx,
    isEditing,
  } = props
  const [isMorePopoverOpen, setIsMorePopoverOpen] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const dispatch = useDispatch()
  const [saveParts] = useSavePartsMutation()

  const handleCheckboxClick = (e) => {
    e.stopPropagation()

    if (!e.shiftKey) {
      handleSelectPart({
        part,
        workoutId: workout.id,
        dispatch,
        isPartSelected,
        setIsPartSelectedState,
      })
    } else if (e.shiftKey) {
      dispatch(partsMultiSelected({ workoutId: workout.id, partIdx, part }))
    }
  }

  if (!deleteConfirmation) {
    return (
      <div css={tw`flex items-center mb-3`} data-testid='partHeaderContainer'>
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          checked={isPartSelected ? true : false}
          value={isPartSelected ? 'on' : 'off'}
          type='checkbox'
          readOnly
          tabIndex={-1}
        />
        <PartTitle
          partIdx={partIdx}
          partTitle={part.name}
          orgId={orgId}
          workoutId={workout.id}
          partType={part.type}
          setActivePartIdx={setActivePartIdx}
          isEditing={isEditing}
        />
        <ActionsContainer
          data-testid='partActionContainer'
          id='partcontainer-actions'
          css={[isMorePopoverOpen && tw`flex`]}
        >
          {/* Do not render tooltip for DragOverlay */}
          {attributes ? (
            <Tooltip content='Drag'>
              <FiMove
                {...attributes}
                {...listeners}
                className='cursor-move w-3.5 h-3.5 text-gray-500 hover:text-tGreen'
                tabIndex={-1}
              />
            </Tooltip>
          ) : (
            <FiMove className='cursor-move w-3.5 h-3.5 text-gray-500 hover:text-tGreen' />
          )}
          <Tooltip content='Copy 1 block'>
            <CgCopy
              data-testid='copyPartIcon'
              className='cursor-pointer ml-2 w-4 h-4 text-gray-500 hover:text-tGreen'
              onClick={(e) => handleCopyPart(e)}
            />
          </Tooltip>
          <Tooltip content='Delete'>
            <CgTrash
              className='cursor-pointer ml-1.5 w-[18px] h-[18px] text-gray-500 hover:text-tRed'
              onClick={(e) => {
                e.stopPropagation()
                setDeleteConfirmation(true)
              }}
            />
          </Tooltip>

          <div id='part-actions' className='flex items-center'>
            <Popover.Root open={isMorePopoverOpen} onOpenChange={setIsMorePopoverOpen}>
              <Popover.Trigger
                onClick={(e) => {
                  e.stopPropagation()
                  setIsMorePopoverOpen(true)
                }}
                tabIndex={-1}
                className='cursor-pointer text-gray-500 hover:text-tGreen'
              >
                <CgMoreAlt className='ml-1 w-5 h-5' />
              </Popover.Trigger>
              <Popover.Content
                className='bg-white px-2 py-2 rounded-md shadow-xl border-2 border-gray-300'
                align='end'
                alignOffset={-7}
              >
                <PartPopoverActions orgId={orgId} part={part} />
              </Popover.Content>
            </Popover.Root>
          </div>
        </ActionsContainer>
      </div>
    )
  }

  return (
    <div css={[tw`flex items-center justify-between mb-3`, isEditing && tw`mt-2`]}>
      <div className='text-tBlack leading-none text-sm font-medium'>Delete?</div>
      <div className='flex items-center'>
        <Button
          size='sm'
          variant='secondary'
          css={tw`text-xs bg-transparent ml-2 py-1 whitespace-nowrap`}
          onClick={(e) => {
            e.stopPropagation()
            setDeleteConfirmation(false)
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          size='sm'
          variant='danger'
          css={tw`text-xs ml-2 py-1`}
          onClick={(e) => {
            e.stopPropagation()
            handleDeletePart({
              partToDelete: part,
              workout,
              saveParts,
              createAlert,
              dispatch,
              isPartSelected,
              setIsPartSelectedState,
              orgId,
            })
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  )

  function handleCopyPart(e) {
    e.stopPropagation()

    dispatch(partsCopied({ parts: [part] }))
    createAlert({ text: 'Block copied!', type: 'success' })
  }
}

const ActionsContainer = tw.div`hidden items-center absolute z-10 top-4 right-2
py-1 px-1.5 rounded-md bg-white border-[1px] border-gray-100 shadow`

const Checkbox = tw.input`
  cursor-pointer
  rounded-sm
  w-3.5 h-3.5 mr-2
  border-gray-300
  text-tGreen
  focus:ring-0 hover:border-tGreen
  disabled:cursor-not-allowed disabled:border-gray-300 disabled:opacity-40
`

const handleSelectPart = ({ part, workoutId, dispatch, isPartSelected, setIsPartSelectedState }) => {
  setIsPartSelectedState(!isPartSelected)

  dispatch(
    partSelected({
      part,
      workoutId,
    })
  )
}

const handleDeletePart = async ({
  partToDelete,
  workout,
  saveParts,
  createAlert,
  dispatch,
  isPartSelected,
  setIsPartSelectedState,
  orgId,
}) => {
  const updatedParts = workout.parts.filter((part) => part.id !== partToDelete.id)
  await saveParts({ orgId, workoutId: workout.id, parts: updatedParts })

  if (isPartSelected) {
    handleSelectPart({
      part: partToDelete,
      workoutId: workout.id,
      dispatch,
      isPartSelected,
      setIsPartSelectedState,
    })
  }

  createAlert({ text: 'Block deleted!', type: 'success' })
}

function PartTitle(props) {
  const { partIdx, partTitle, orgId, workoutId, partType, setActivePartIdx, isEditing } = props

  const inputRef = useRef()

  const partLetter = String.fromCharCode(65 + partIdx)

  const defaultTitle = partTitle ? partLetter : `Block ${partLetter}`
  const [titleText, setTitleText] = useState(partTitle ? partTitle : defaultTitle)

  const { setActiveInput } = useRefsControl()

  const [updatePart] = useUpdatePartMutation()

  const debouncedTitle = useDebounce(titleText, 250)
  useDebouncedTextMutation({
    stateText: titleText,
    dbText: partTitle ? partTitle : defaultTitle,
    mutation: updatePart,
    debouncedStateText: debouncedTitle,
    mutationArgs: {
      orgId,
      workoutId,
      partIdx,
      part: {
        name: debouncedTitle,
      },
    },
  })

  if (partType === 'rest') {
    return (
      <div className='w-2/3 flex items-baseline'>
        <h4 className='text-sm font-medium whitespace-nowrap truncate capitalize'>{partTitle}</h4>
      </div>
    )
  }

  return (
    <div className='flex-1 flex items-baseline'>
      <input
        ref={inputRef}
        maxLength={25}
        onChange={(e) => setTitleText(e.target.value)}
        onFocus={() => {
          setActivePartIdx(partIdx)
          setActiveInput(inputRef)
        }}
        value={titleText}
        placeholder='Enter Block Title'
        css={partTitleInputClasses}
        className={`text-ellipsis bg-inherit ${isEditing ? 'cursor-auto' : 'cursor-pointer'}`}
      />
    </div>
  )
}
const partTitleInputClasses = tw`w-3/4 font-medium text-sm focus:ring-0 focus:outline-none p-1 py-1.5 capitalize w-full
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded
`
