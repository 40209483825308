import { Amplify } from 'aws-amplify'
import jwt_decode from 'jwt-decode'

export function startAWS({ fetchNewToken }) {
  const awsconfig = {
    Auth: {
      identityPoolId: 'us-west-1:48be6879-8f54-420c-a3e2-f346ac14c1b9',
      region: 'us-west-1',
      refreshHandlers: {
        [process.env.AUTH_DOMAIN]: async () => {
          const { idToken } = await fetchNewToken()
          const { exp } = jwt_decode(idToken)

          return new Promise((resolve, reject) => {
            const data = {
              token: idToken, // the token from the provider
              expires_at: exp * 1000, // the timestamp when the token expires (in milliseconds)
            }
            resolve(data)
          })
        },
      },

      //Amazon Cognito User Pool ID and Client Id. TRYBE DOES NOT USE THIS CURRENTLY BUT THROWS CONFIG ERROR IF NOT INCLUDED
      userPoolId: 'us-west-1_B68t88F7w',
      userPoolWebClientId: '6oi3lp5jcq4qi1du2kenje5sjs',
    },
    Storage: {
      AWSS3: {
        bucket: process.env.S3_UPLOAD_BUCKET,
        region: 'us-west-1',
      },
      customPrefix: {
        public: 'creator-uploads/',
        protected: 'protected/',
        private: 'private/', //Amplify only allows 3 pre-configured folder pathways into a bucket. Replaced public with creator-uploads so lambda function can look for prefix "creator-uploads" in the upload bucket
      },
    },
  }
  Amplify.configure(awsconfig)
}
