import tw from 'twin.macro'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { CgOptions } from 'react-icons/cg'

import { useCustomization } from 'common/contexts/Customization/useCustomization'
import TagInput from 'common/components/Tag/TagInput'
import FilterCheckbox from 'modules/ExerciseLibrary/Filter/components/FilterCheckbox'
import MediaFilter from 'modules/ExerciseLibrary/Filter/components/MediaFilter'
import LevelFilter from 'modules/ExerciseLibrary/Filter/components/LevelFilter'
import CueFilter from 'modules/ExerciseLibrary/Filter/components/CueFilter'
import VideoFilter from './components/VideoFilter'
import { buttonBase, buttonVariants, buttonSizes } from 'common/components/Button/Button'

import { initialFilterState } from 'modules/ExerciseLibrary/Filter/constants'
import { useListenExercisesQuery } from '../exerciseLibraryApi'

export function Filter({ coachOrgId, filters, setFilters }) {
  const { exVidOrientation } = useCustomization()

  const activeFilterCount = Object.values(filters).reduce((acc, filter) => (filter === null ? acc : acc + 1), 0)

  const { data: exData } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  const tagsList = exData?.isFiller || exData === undefined ? [] : exData.tagsList

  const handleClearFilters = () => {
    setFilters(initialFilterState)
  }

  return (
    <Popover.Root>
      <Popover.Trigger
        css={[
          buttonBase,
          buttonVariants.tertiary,
          buttonSizes.lg,
          tw`relative px-4 ml-4 md:ml-6 ring-white`,
          activeFilterCount > 0 && tw`ring-tGreen`,
        ]}
      >
        {activeFilterCount > 0 && <FilterCounter>{activeFilterCount}</FilterCounter>}
        <CgOptions className='w-6 h-6 mr-0 md:mr-2' />
        <span className='hidden md:inline'>Filters</span>
      </Popover.Trigger>
      <Popover.Content className='bg-white px-3 py-3 mt-2 rounded-xl w-56 shadow-md' aria-label='Filters'>
        <div className='flex justify-between items-center mb-2'>
          <button
            className='text-tGreen rounded-lg px-2 py-1 hover:bg-tGreen hover:bg-opacity-10'
            onClick={handleClearFilters}
          >
            Clear
          </button>
          <h4 className='font-bold text-tBlack'>Filters</h4>
          <Popover.Close className='text-tGreen font-bold rounded-lg px-2 py-1 hover:bg-tGreen hover:bg-opacity-10 transition-all'>
            Done
          </Popover.Close>
        </div>
        <div className='divide-y divide-gray-200'>
          {exVidOrientation === 'portrait' ? (
            <FilterCheckbox name='video' filters={filters} setFilters={setFilters}>
              {filters.video && <VideoFilter filters={filters} setFilters={setFilters} />}
            </FilterCheckbox>
          ) : (
            <FilterCheckbox name='media' filters={filters} setFilters={setFilters}>
              {filters.media && <MediaFilter filters={filters} setFilters={setFilters} />}
            </FilterCheckbox>
          )}
          <FilterCheckbox name='level' filters={filters} setFilters={setFilters}>
            {filters.level && <LevelFilter filters={filters} setFilters={setFilters} />}
          </FilterCheckbox>
          <FilterCheckbox name='cues' filters={filters} setFilters={setFilters}>
            {filters.cues && <CueFilter filters={filters} setFilters={setFilters} />}
          </FilterCheckbox>
          <FilterCheckbox name='tags' filters={filters} setFilters={setFilters}>
            {filters.tags && (
              <div className='flex items-center pt-2'>
                <TagInput
                  activeTags={filters.tags}
                  tagsList={tagsList}
                  setActiveTags={(tags) => {
                    setFilters({ ...filters, tags })
                  }}
                  isCreateInput={false}
                />
              </div>
            )}
          </FilterCheckbox>
          <FilterCheckbox name='recently-created' filters={filters} setFilters={setFilters} />
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

const FilterCounter = tw.span`
  absolute inline-flex items-center justify-center -right-1 -top-1 leading-none text-sm text-white w-5 h-5 bg-tGreen rounded-full
`
