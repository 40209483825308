import tw from 'twin.macro'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { useSaveBlockMutation } from '../programApi'

export function PartPopoverActions({ orgId, part }) {
  const { createAlert } = useAlert()
  const [saveBlock] = useSaveBlockMutation()
  return (
    <>
      <Popover.Arrow offset={12} className='fill-gray-300' />
      <Popover.Close asChild={true}>
        <PopoverBtn disabled={false} onClick={() => handleSaveBlock({ orgId, part, saveBlock, createAlert })}>
          Save block
        </PopoverBtn>
      </Popover.Close>
    </>
  )
}

const PopoverBtn = tw.button`
  flex items-center bg-white hover:bg-gray-300 hover:bg-opacity-30
  text-sm text-tBlack transition-all rounded-md justify-start
  font-medium px-2 py-2 w-full disabled:cursor-not-allowed
  `

const handleSaveBlock = async ({ orgId, part, saveBlock, createAlert }) => {
  const { error } = await saveBlock({ orgId, part })
  if (error?.message) {
    createAlert({ text: error.message, type: 'danger' })
  } else {
    createAlert({ text: 'Block is saved!', type: 'success' })
  }
}
