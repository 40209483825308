import { globalApi, listenFirebase, removeFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { updateItemContentTags } from 'modules/ContentTags/contentTagUtils'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'

export const programGroupApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenProgramGroups: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/programGroups`, api)
      },
    }),
    listenCoachPrograms: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/programs`, api)
      },
    }),
    listenPrimaryUIColor: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/styles/colorScheme/primaryUIColor`, api)
      },
    }),
    listenProgramGroupImg: build.query({
      queryFn: () => {
        return { data: false }
      },
      onCacheEntryAdded: async ({ coachOrgId, programGroupId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/programGroups/${programGroupId}/previewImg`, api)
      },
    }),
    removeProgramGroup: build.mutation({
      queryFn: async ({ coachOrgId, programGroupKey, programGroup }) => {
        await updateItemContentTags({
          orgId: coachOrgId,
          item: programGroup,
          contentId: programGroupKey,
          contentType: 'programGroup',
          updateType: 'remove',
        })
        const { data, error } = await removeFirebase(`coachOrgs/${coachOrgId}/programGroups/${programGroupKey}`)
        await updateTabRowsOnDelete({ coachOrgId, itemIds: [programGroupKey] })
        return { data, error }
      },
    }),
    setProgramGroup: build.mutation({
      queryFn: async ({ coachOrgId, programGroupKey, programGroup }) => {
        const { data, error } = await setFirebase(
          `coachOrgs/${coachOrgId}/programGroups/${programGroupKey}`,
          programGroup
        )
        return { data, error }
      },
    }),
    updateProgramGroup: build.mutation({
      queryFn: async ({ coachOrgId, programGroupKey, programGroup }) => {
        const { data, error } = await updateFirebase(
          `coachOrgs/${coachOrgId}/programGroups/${programGroupKey}`,
          programGroup
        )
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenProgramGroupsQuery,
  useListenCoachProgramsQuery,
  useListenPrimaryUIColorQuery,
  useRemoveProgramGroupMutation,
  useSetProgramGroupMutation,
  useListenProgramGroupImgQuery,
  useUpdateProgramGroupMutation,
} = programGroupApi
