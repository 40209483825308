import { globalApi, updateFirebase, setFirebase, listenFirebase } from 'modules/App/globalApi'

export const accountApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenProfile: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ userId }, api) => {
        listenFirebase(`/users/${userId}/profile`, api)
      },
    }),
    updateProfile: build.mutation({
      queryFn: async ({ userId, profile }) => {
        const { data, error } = await updateFirebase(`users/${userId}/profile`, profile)
        return { data, error }
      },
    }),
    listenCoachOrgLogo: build.query({
      queryFn: () => ({}),
      onCacheEntryAdded: async (coachOrgId, api) => {
        listenFirebase(`/coachOrgs/${coachOrgId}/productInfo/logo`, api)
      },
    }),
    setCoachLogo: build.mutation({
      queryFn: async ({ coachOrgId, logo }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/productInfo/logo`, logo)
        return { data, error }
      },
    }),
    listenCoachOrgName: build.query({
      queryFn: () => ({}),
      onCacheEntryAdded: async (coachOrgId, api) => {
        listenFirebase(`/coachOrgs/${coachOrgId}/orgName`, api)
      },
    }),
    setCoachOrgName: build.mutation({
      queryFn: async ({ userId, coachOrgId, orgName }) => {
        const { data: orgNameData, error: orgNameError } = await setFirebase(`coachOrgs/${coachOrgId}/orgName`, orgName)

        const { data: productNameData, error: productNameError } = await setFirebase(
          `coachOrgs/${coachOrgId}/productInfo/productName`,
          orgName
        )

        const { data: userOrgNameData, error: userOrgNameError } = await setFirebase(
          `/users/${userId}/metadata/coachOrgName`,
          orgName
        )

        const error =
          orgNameError || productNameError || userOrgNameError
            ? { orgNameError, productNameError, userOrgNameError }
            : false

        return {
          data: { orgNameData, productNameData, userOrgNameData },
          error,
        }
      },
    }),
    listenCoachName: build.query({
      queryFn: () => ({}),
      onCacheEntryAdded: async (coachOrgId, api) => {
        listenFirebase(`/coachOrgs/${coachOrgId}/coachName`, api)
      },
    }),
    setCoachName: build.mutation({
      queryFn: async ({ userId, coachOrgId, coachName }) => {
        const { data: coachNameData, error: coachNameError } = await setFirebase(
          `coachOrgs/${coachOrgId}/coachName`,
          coachName
        )
        const { data: userCoachNameData, error: userCoachNameError } = await setFirebase(
          `/users/${userId}/metadata/coachName`,
          coachName
        )

        const error = coachNameError || userCoachNameError ? { coachNameError, userCoachNameError } : false
        return { data: { coachNameData, userCoachNameData }, error }
      },
    }),
    listenCoachOrgPrimaryUIColor: build.query({
      queryFn: () => ({}),
      onCacheEntryAdded: async (coachOrgId, api) => {
        listenFirebase(`/coachOrgs/${coachOrgId}/styles/colorScheme/primaryUIColor`, api)
      },
    }),
    setCoachOrgPrimaryUIColor: build.mutation({
      queryFn: async ({ coachOrgId, primaryUIColor }) => {
        const { data, error: coachColorErr } = await setFirebase(
          `coachOrgs/${coachOrgId}/styles/colorScheme/primaryUIColor`,
          primaryUIColor
        )

        const error = coachColorErr ? coachColorErr : false
        return { data, error }
      },
    }),
  }),
})

export const {
  useListenProfileQuery,
  useUpdateProfileMutation,
  useListenCoachOrgLogoQuery,
  useSetCoachLogoMutation,
  useListenCoachOrgNameQuery,
  useSetCoachOrgNameMutation,
  useListenCoachNameQuery,
  useSetCoachNameMutation,
  useListenCoachOrgPrimaryUIColorQuery,
  useSetCoachOrgPrimaryUIColorMutation,
} = accountApi
