import { globalApi, listenFirebase, removeFirebase, setFirebase, updateFirebase } from 'modules/App/globalApi'
import { updateItemContentTags } from 'modules/ContentTags/contentTagUtils'
import { updateTabRowsOnDelete } from 'modules/Layout/utils/layoutUtils'

export const videoLibraryApi = globalApi.injectEndpoints({
  endpoints: (build) => ({
    listenCoachVideos: build.query({
      queryFn: () => ({ data: { isLoading: true } }),
      onCacheEntryAdded: async ({ coachOrgId }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/videos`, api)
      },
    }),
    listenCoachVideo: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, videoKey }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/videos/${videoKey}`, api)
      },
    }),
    listenVideoExisting: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, videoKey }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/videos/${videoKey}/video`, api)
      },
    }),
    listenVideoPreviewImg: build.query({
      queryFn: () => {
        return { data: { isLoading: true } }
      },
      onCacheEntryAdded: async ({ coachOrgId, videoKey }, api) => {
        listenFirebase(`coachOrgs/${coachOrgId}/videos/${videoKey}/previewImg`, api)
      },
    }),
    setVideo: build.mutation({
      queryFn: async ({ coachOrgId, videoKey, video }) => {
        const { data, error } = await setFirebase(`coachOrgs/${coachOrgId}/videos/${videoKey}`, video)
        return { data, error }
      },
    }),
    updateVideo: build.mutation({
      queryFn: async ({ coachOrgId, videoKey, video }) => {
        const { data, error } = await updateFirebase(`coachOrgs/${coachOrgId}/videos/${videoKey}`, video)
        return { data, error }
      },
    }),
    removeVideo: build.mutation({
      queryFn: async ({ coachOrgId, videoKey, video }) => {
        const { data, error } = await removeFirebase(`coachOrgs/${coachOrgId}/videos/${videoKey}`)
        await updateItemContentTags({
          orgId: coachOrgId,
          item: video,
          contentId: videoKey,
          contentType: 'video',
          updateType: 'remove',
        })
        await updateTabRowsOnDelete({ coachOrgId, itemIds: [videoKey] })
        return { data, error }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useListenCoachVideosQuery,
  useListenCoachVideoQuery,
  useListenVideoExistingQuery,
  useListenVideoPreviewImgQuery,
  useSetVideoMutation,
  useUpdateVideoMutation,
  useRemoveVideoMutation,
} = videoLibraryApi
