import tw from 'twin.macro'
import React, { useState } from 'react'
import { CgAdd, CgSearch } from 'react-icons/cg'
import * as Popover from '@radix-ui/react-popover'

import { useSetTabContentMutation } from 'modules/Layout/layoutApi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { buttonBase, buttonVariants, buttonSizes } from 'common/components/Button/Button'
import { ScrollArea } from 'common/components/ScrollArea/ScrollArea'
import { NavigationActions } from './components/NavigationActions'
import { ItemTypeSelectors } from './components/ItemTypeSelectors'
import { ProgramGroupSelection } from './components/ProgramGroupSelection'
import { WorkoutSelection } from './components/WorkoutSelection'
import { ProgramSelection } from './components/ProgramSelection'
import { VideoSelection } from './components/VideoSelection'
import { availableItemTypes } from '../../constants/constants'
import { cardContainerBaseStyles } from '../styles'
import { existsInCurrentLayout } from './utils'

export function AddCard({ coachOrgId, currentRow, currentTabContent, tabId }) {
  const { createAlert } = useAlert()
  const [previousItem, setPreviousItem] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  const [saveTabContent] = useSetTabContentMutation()

  const cleanup = () => {
    setCurrentItem(null)
    setPreviousItem(null)
    setSearchQuery('')
  }

  const handleAdd = async (currentItem) => {
    const updatedTabContent = currentTabContent.map((item) => {
      if (item.id === currentRow.id) {
        const items = currentRow.items ? [currentItem, ...currentRow.items] : [currentItem]
        const updateditem = { ...currentRow, items }

        return updateditem
      }

      return item
    })

    let itemTypeReadable = currentItem.type.toLowerCase()
    if (itemTypeReadable === 'programgroup') {
      itemTypeReadable = 'series'
    }

    await saveTabContent({ coachOrgId, tabContent: updatedTabContent, tabId })
    createAlert({ text: `${itemTypeReadable} added`, type: 'success' })
  }

  const handleBack = () => {
    if (!previousItem) {
      cleanup()
      return
    }

    setSearchQuery('')
    setCurrentItem(previousItem[previousItem.length - 1])
    const removeLastItem = (arr) => arr.filter((_, index) => index !== arr.length - 1)
    setPreviousItem((state) => (state.length === 1 ? null : removeLastItem(state)))
  }

  const handleNext = (item) => {
    setSearchQuery('')
    setPreviousItem((state) => (state ? [...state, item] : [item]))

    const availableTypeValues = Object.values(availableItemTypes)
    const newTypeIndex = availableTypeValues.indexOf(item.type) + 1
    const newType = availableTypeValues[newTypeIndex]
    setCurrentItem({ type: newType, id: null })
  }

  const handleSelection = ({ id, programId = null, type = null }) => {
    setCurrentItem({ id, programId, type: type ? type : currentItem.type })
  }

  return (
    <div css={[cardContainerBaseStyles, tw`items-center justify-center bg-offWhite`]} data-testid='add-card'>
      <Popover.Root onOpenChange={() => cleanup()}>
        <Popover.Trigger css={[buttonBase, buttonVariants.secondary, buttonSizes.md]}>
          Add item <CgAdd className='w-5 h-5 ml-2' />
        </Popover.Trigger>
        <Popover.Anchor className='absolute top-[-10px] left-6 inset-x-0' />
        <Popover.Content
          className='bg-white px-3 py-3 rounded-xl w-80 shadow-2xl z-30 border-2 border-gray-300'
          aria-label='add-card-content'
        >
          <NavigationActions
            previousItem={previousItem}
            currentItem={currentItem}
            handleBack={handleBack}
            coachOrgId={coachOrgId}
          />
          {!currentItem ? (
            <ItemTypeSelectors setCurrentItem={setCurrentItem} />
          ) : (
            <div className='flex flex-col'>
              <div className='flex items-center text-tBlack mb-2'>
                <CgSearch className='w-4 h-4' />
                <input
                  type='text'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder='Search...'
                  className='border-0 text-sm ml-2 bg-transparent placeholder:text-tBlack placeholder:text-opacity-50 focus:ring-0 p-0'
                  autoComplete='off'
                  aria-label='search'
                />
              </div>
              <ScrollArea css={tw`max-h-80`} id='addcard-scrollarea'>
                <div className='flex flex-col'>
                  <ItemSelectionRender
                    currentRow={currentRow}
                    currentItem={currentItem}
                    previousItem={previousItem}
                    handleSelection={handleSelection}
                    searchQuery={searchQuery}
                    handleNext={handleNext}
                  />
                </div>
              </ScrollArea>
              {currentItem.id && (
                <Popover.Close
                  css={[buttonBase, buttonVariants.primary, buttonSizes.md, tw`mt-3`]}
                  disabled={existsInCurrentLayout({
                    currentRow,
                    itemId: currentItem.id,
                    itemType: currentItem.type,
                  })}
                  onClick={() => handleAdd(currentItem)}
                  aria-label='Add selected item'
                >
                  Add item <CgAdd className='w-5 h-5 ml-2' />
                </Popover.Close>
              )}
            </div>
          )}
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

const ItemSelectionRender = (props) => {
  const isSingleVideoWorkout = props.currentItem.type === availableItemTypes.VIDEO && props.currentItem.programId

  if (props.currentItem.type === availableItemTypes.PROGRAMGROUP) {
    return <ProgramGroupSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.PROGRAM) {
    return <ProgramSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.WORKOUT || isSingleVideoWorkout) {
    return <WorkoutSelection {...props} />
  }

  if (props.currentItem.type === availableItemTypes.VIDEO) {
    return <VideoSelection {...props} />
  }

  return null
}
