import tw, { styled } from 'twin.macro'
import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Combobox } from '@headlessui/react'
import * as Popover from '@radix-ui/react-popover'
import { useForm, FormProvider } from 'react-hook-form'
import { FiMove } from 'react-icons/fi'
import { TbVideoOff } from 'react-icons/tb'

import { useListenExercisesQuery, useSetExerciseMutation } from 'modules/ExerciseLibrary/exerciseLibraryApi'
import { useListenExistingItemDraftsQuery } from 'modules/Uploads/uploadApi'
import { useSetWorkoutExerciseMutation } from 'modules/Programs/programApi'

import { getReadableExId } from 'modules/Programs/utils/exerciseDomain'

import { getSuggestedExercises } from 'common/components/ExerciseInputList/utils'

import { useDebounce } from 'common/hooks/useDebounce'
import { useDebouncedTextMutation } from 'common/hooks/useDebouncedTextMutation'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { UploadInput } from 'common/components/UploadInput/UploadInput'
import { Spinner } from 'common/components/Spinner/Spinner'
import { isUploadingAssets } from 'common/utils/fileUploading/uploadUtils'
import { ExerciseActions } from './ExerciseActions'
import { getStandardizedName } from 'common/utils/stringUtils'
import { useEventListener } from 'common/hooks/useEventListener'
import { useCustomization } from 'common/contexts/Customization/useCustomization'
import { ExerciseSuggestions } from './ExerciseSuggestions'

export function ExerciseNameInput({
  setActiveExIdx,
  setActivePartIdx,
  workoutId,
  partIdx,
  exIdx,
  exercise,
  coachOrgId,
  instrRef,
  onFocus,
  getNameInputRef,
  editExContRef,
  isEditingWkt,
  isSelected,
  setShowTimerActions,
  attributes,
  listeners,
}) {
  const { workoutId: isLargeView } = useParams()
  const { exVidOrientation } = useCustomization()
  const exNameInputRef = useRef()

  const { data: exData = {}, isLoading } = useListenExercisesQuery({ coachOrgId, exVidOrientation })
  const { exercises = {}, exNameToId = {}, exIdHasVideo = {} } = exData
  const exLoading = exData.isFiller || isLoading

  const orgExName = exercises?.[exercise.id]?.name
  const exName = orgExName || exercise.name
  const [exNameInputValue, setExNameInputValue] = useState(exName || '')
  const [exerciseId, setExerciseId] = useState(exercise.id || '')

  const [isVidPopoverOpen, setIsVidPopoverOpen] = useState(false)

  const [actionsVisible, setActionsVisible] = useState(true)

  const [setWorkoutExMutation] = useSetWorkoutExerciseMutation({
    fixedCacheKey: isLargeView ? 'shared-largeview-set-exercise' : null,
  })

  // If exercise name was changed in exercise library, change it in program
  // Effect changes exNameInputValue and debouncedTextMutation runs
  const exNamesDiffer = getExNamesDiffer(exercise.name, orgExName)
  useEffect(() => {
    if (!exLoading && exNamesDiffer) {
      setExNameInputValue(exName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exLoading, exNamesDiffer])

  // If exercise was deleted in exercise library, reset exercise id
  const exDeletedInLibrary = exerciseId && !exLoading && !exData.exercises[exerciseId]
  useEffect(() => {
    if (exDeletedInLibrary) {
      setWorkoutExMutation({
        orgId: coachOrgId,
        workoutId,
        partIdx,
        exIdx,
        exercise: {
          ...exercise,
          id: '',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exDeletedInLibrary])

  const debExNameInputValue = useDebounce(exNameInputValue, 200)
  useDebouncedTextMutation({
    dbText: getStandardizedName(exercise.name),
    mutation: setWorkoutExMutation,
    debouncedStateText: getStandardizedName(debExNameInputValue),
    mutationArgs: {
      orgId: coachOrgId,
      workoutId,
      partIdx,
      exIdx,
      exercise: {
        ...exercise,
        name: debExNameInputValue,
        id: exerciseId,
      },
    },
  })

  const exHasVideo = exIdHasVideo[exercise.id]

  const suggestedExercises = getSuggestedExercises(exercises, exNameInputValue) || []

  const isExNameInputFocused = exNameInputRef.current === document.activeElement

  const onMouseMove = () => {
    if (isExNameInputFocused) {
      setActionsVisible(true)
    }
  }

  useEventListener('mousemove', onMouseMove, editExContRef?.current, { passive: true })

  const onMouseLeave = () => {
    if (isExNameInputFocused) {
      setActionsVisible(false)
    }
  }

  useEventListener('mouseleave', onMouseLeave, editExContRef?.current, { passive: true })

  return (
    <div className='w-full ml-1'>
      <Combobox
        value={{ exerciseId }}
        onChange={(exId) => handleExSelect({ exId, exName: exercises?.[exId]?.name })}
        nullable
      >
        <div className='relative flex'>
          <Combobox.Input
            placeholder='Exercise name'
            autoComplete='off'
            displayValue={() => exNameInputValue}
            onChange={handleInputChange}
            onFocus={() => {
              onFocus()
              setActionsVisible(false)
            }}
            onBlur={() => {
              setActionsVisible(true)
            }}
            css={exInputClasses}
            className={`text-ellipsis bg-inherit ${isEditingWkt ? 'cursor-auto' : 'cursor-pointer'}`}
            ref={(e) => {
              exNameInputRef.current = e
              getNameInputRef(e)
            }}
          />
          {actionsVisible && (
            <ActionsContainer
              id='editexercise-actions-container'
              isExNameInputFocused={isExNameInputFocused}
              isSelected={isSelected}
              isEditing={isEditingWkt}
            >
              <div id='editexercise-actions' css={[tw`hidden mr-1.5 only:mr-0`, isVidPopoverOpen && tw`flex`]}>
                <Tooltip content='Drag within block' triggerClasses='flex'>
                  <button
                    {...attributes}
                    {...listeners}
                    onMouseDown={(e) => {
                      // make sure actions reappear if dragging starts
                      // need timeout because onMouseLeave that hides actions would run after
                      setTimeout(() => {
                        setActionsVisible(true)
                        setShowTimerActions(true)
                      }, 500)
                      listeners.onMouseDown(e)
                    }}
                    tabIndex={-1}
                  >
                    <FiMove className='cursor-move w-3.5 h-3.5 my-[3px] text-gray-500 hover:text-tGreen' />
                  </button>
                </Tooltip>
              </div>
              {exLoading && isEditingWkt && (
                <div className='flex items-center justify-center'>
                  <Spinner className='w-4 h-4 text-gray-200' fillClassName='fill-gray-500' />
                </div>
              )}
              {!exLoading && isEditingWkt && (
                <div id='editexercise-actions' css={[tw`flex invisible`, isVidPopoverOpen && tw`flex visible`]}>
                  <ExerciseActions exercise={exercise} workoutId={workoutId} partIdx={partIdx} orgId={coachOrgId} />
                </div>
              )}
              {!exLoading && !exHasVideo && (
                <UploadVideo
                  exName={exName}
                  exercise={exercise}
                  coachOrgId={coachOrgId}
                  handleSetWorkoutEx={(exId) => handleSetWorkoutEx(exId)}
                  isEditingWkt={isEditingWkt}
                  isVidPopoverOpen={isVidPopoverOpen}
                  setIsVidPopoverOpen={setIsVidPopoverOpen}
                  exVidOrientation={exVidOrientation}
                />
              )}
            </ActionsContainer>
          )}
        </div>
        {exNameInputValue && suggestedExercises?.length > 0 && (
          <ExerciseSuggestions
            suggestedExercises={suggestedExercises}
            exVidOrientation={exVidOrientation}
            containerClasses={isLargeView ? '!w-96 !top-9' : '!top-9'}
          />
        )}
      </Combobox>
    </div>
  )

  function getExNamesDiffer(wktExName, orgExName) {
    const wktExNameStd = getStandardizedName(wktExName)
    const orgExNameStd = getStandardizedName(orgExName)
    return wktExNameStd !== orgExNameStd
  }

  function handleInputChange(e) {
    setActionsVisible(false)

    const name = e.target.value
    setExNameInputValue(name)

    const stdExName = getStandardizedName(name)
    const exId = exNameToId[stdExName] || ''

    setExerciseId(exId)

    setActivePartIdx(partIdx)
    setActiveExIdx(exIdx)
  }

  function handleExSelect({ exId, exName }) {
    // Selecting suggestion with 'enter' btn click would work without timeout.
    // Timeout is needed because selecting suggestion with mouse click focuses exercise input.
    if (exId && exName) {
      setTimeout(() => {
        instrRef.current.focus()
      }, 100)
    }

    setExNameInputValue(exName ? exName : '')
    setExerciseId(exId ? exId : '')
  }

  function handleSetWorkoutEx(exId) {
    setWorkoutExMutation({
      orgId: coachOrgId,
      workoutId,
      partIdx,
      exIdx,
      exercise: {
        ...exercise,
        name: debExNameInputValue,
        id: exId,
      },
    })
  }
}

const ActionsContainer = styled.div(({ isExNameInputFocused, isEditing, isSelected }) => [
  tw`absolute z-20 right-0 top-[1px] flex items-center py-1 px-1.5 rounded-md border-[1px] bg-white border-white`,
  isExNameInputFocused && tw`border-gray-200 shadow-sm`,
  !isEditing && isSelected && tw`bg-transparent border-transparent`,
])

const exInputClasses = tw`font-medium text-sm focus:ring-0 p-1 py-1.5 capitalize w-full
border-none overflow-hidden whitespace-nowrap placeholder:text-gray-400
hover:bg-gray-100 hover:bg-opacity-70 focus:bg-gray-100 focus:bg-opacity-70 rounded
`

function UploadVideo({
  exName,
  exercise,
  coachOrgId,
  handleSetWorkoutEx,
  isEditingWkt,
  isVidPopoverOpen,
  setIsVidPopoverOpen,
  exVidOrientation,
}) {
  const isExNameEmpty = !exName || exName === ''

  const { current: exerciseId } = useRef(exercise.id ? exercise.id : getReadableExId(exercise.name))

  const { data: assetDrafts } = useListenExistingItemDraftsQuery({ coachOrgId, id: exerciseId })
  const isUploading = isUploadingAssets(assetDrafts)

  const [setExercise] = useSetExerciseMutation()

  const defaultValues = {
    video: '',
  }

  const methods = useForm({
    defaultValues,
  })

  const {
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = methods

  if (isExNameEmpty && !isEditingWkt) {
    return (
      <Tooltip content='Name required to upload video' triggerClasses='flex items-center justify-center cursor-pointer'>
        <button tabIndex={-1} className='cursor-not-allowed' data-testid='addVideoBtn' disabled={true}>
          <TbVideoOff
            className={`w-[18px] h-[18px] text-red-300 my-[1px] ${isEditingWkt ? 'ml-1.5' : ''}`}
            data-testid='addVideoIcon'
          />
        </button>
      </Tooltip>
    )
  }

  if (!isExNameEmpty) {
    return (
      <Tooltip
        content={isUploading ? 'Video uploading' : 'Upload video'}
        triggerClasses='flex items-center justify-center cursor-pointer'
      >
        <Popover.Root open={isVidPopoverOpen} onOpenChange={setIsVidPopoverOpen}>
          <Popover.Trigger
            asChild={true}
            onClick={(e) => {
              e.stopPropagation()
              setIsVidPopoverOpen(true)
            }}
          >
            <button
              tabIndex={-1}
              className='flex items-center'
              onClick={(e) => e.stopPropagation()}
              data-testid='addVideoBtn'
            >
              {isUploading ? (
                <Spinner className={`w-4 h-4 text-gray-100 my-[1px] ${isEditingWkt ? 'ml-2' : ''}`} />
              ) : (
                <TbVideoOff
                  className={`w-[18px] h-[18px] text-red-400 my-[1px] ${isEditingWkt ? 'ml-1.5' : ''}`}
                  data-testid='addVideoIcon'
                />
              )}
            </button>
          </Popover.Trigger>
          <Popover.Content
            align='end'
            alignOffset={-7}
            sideOffset={6}
            className='bg-white px-4 py-4 rounded-xl w-96 shadow-2xl border-2 border-gray-300'
            onClick={(e) => e.stopPropagation()} // To prevent closing of the popover because of focusing logic
          >
            <Popover.Arrow offset={12} className='fill-gray-300' />
            <div className='flex justify-between mb-7'>
              <h4 className='font-bold text-tBlack capitalize self-center'>{exName ? exName : 'Untitled exercise'}</h4>
              <Popover.Close css={popoverBtnClasses}>Close</Popover.Close>
            </div>
            <FormProvider {...methods}>
              <form>
                <UploadInput
                  label='Upload video'
                  name='video'
                  id={exerciseId}
                  coachOrgId={coachOrgId}
                  register={register}
                  setValue={setValue}
                  uploadType='update-exercise-video'
                  setError={setError}
                  clearErrors={clearErrors}
                  onUpload={handleChange}
                  showPreview={false}
                  fileType='video'
                  orientation={exVidOrientation}
                />
                {errors?.video ? <InputError>{errors.video.message}</InputError> : null}
              </form>
            </FormProvider>
            {isUploading && (
              <p className='text-xs text-gray-500 mt-4'>
                * Feel free to close this box while the video is being uploaded.
              </p>
            )}
          </Popover.Content>
        </Popover.Root>
      </Tooltip>
    )
  }

  return null

  function handleChange() {
    handleSetWorkoutEx(exerciseId)
    setExercise({
      coachOrgId,
      exerciseKey: exerciseId,
      exercise: { name: exercise.name },
    })
  }
}

const popoverBtnClasses = tw`
  text-tGreen rounded-lg px-2 py-1
  hover:bg-tGreen hover:bg-opacity-10 transition-all whitespace-nowrap ml-4 self-start
`

const InputError = tw.p`flex text-xs mt-1 text-tRed`
