import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { CgMathPlus } from 'react-icons/cg'

import { closestCenter, DndContext, DragOverlay, useSensors, useSensor } from '@dnd-kit/core'
import { dropAnimation, MouseSensor, TouchSensor } from 'common/utils/dndUtils'
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers'

import { useSetTabContentMutation, useUpdateTabsMutation } from 'modules/Layout/layoutApi'

import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Sortable } from 'common/components/Sortable/Sortable'
import { Tooltip } from 'common/components/Tooltip/Tooltip'
import { Tab } from './components/Tab'
import { TagDragOverlay } from './components/TabDragOverlay'

import { getIsTabSelected, getNewTab, getShowTabSeparator, togglePreceedingTabSeparatorVisibility } from './utils'
import { MAX_TAB_COUNT } from '../../constants/constants'
import { useWindowSize } from 'common/hooks/useWindowSize'

export function Tabs({ tabEntries, coachOrgId }) {
  const { tabId: tabIdParam } = useParams()
  const navigate = useNavigate()
  const { createAlert } = useAlert()
  const [width] = useWindowSize()

  const [updateTabs] = useUpdateTabsMutation()
  const [saveTabContent] = useSetTabContentMutation()

  const tabIds = tabEntries.map(([tabId]) => tabId)

  const [activeDragTab, setActiveDragTab] = useState(null)
  const [indexDragIsOver, setIndexDragIsOver] = useState(null)

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  )
  const handleDragStart = ({ active }) => {
    const [activeTabId, activeTab] = tabEntries.find(([tabId]) => active.id === tabId)
    navigate(`/layout/${activeTabId}`)
    setActiveDragTab({ id: activeTabId, tab: activeTab })
  }

  const handleDragEnd = async (event) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = tabEntries.findIndex(([tabId]) => tabId === active.id)
      const newIndex = tabEntries.findIndex(([tabId]) => tabId === over.id)
      const updatedTabEntries = arrayMove(tabEntries, oldIndex, newIndex)

      let tabsUpdate = {}
      updatedTabEntries.forEach(([tabId], idx) => {
        tabsUpdate[`${tabId}/tabIdx`] = idx
      })
      await updateTabs({ coachOrgId, tabs: tabsUpdate })
      setActiveDragTab(null)
      setIndexDragIsOver(null)
    }
  }

  const handleDragOver = ({ over }) => {
    //Hides VectorTabLeft or VectorTabRight if is draggin to first or last index
    const overIndex = over?.data?.current?.sortable?.index
    setIndexDragIsOver(overIndex)
  }

  const tabCapExceeded = tabEntries.length === MAX_TAB_COUNT

  const handleAddTab = async () => {
    const newTabIdx = tabEntries.length
    const { tabId, newTab } = getNewTab(newTabIdx)
    const tabsUpdate = {
      [tabId]: newTab,
    }
    await updateTabs({ coachOrgId, tabs: tabsUpdate })
    navigate(`/layout/${tabId}`)
  }

  const handleDeleteTab = async ({ deleteTabId, deleteTab }) => {
    let tabsUpdate = {}
    const isRemovingFirstTab = deleteTab.tabIdx === 0
    const isRemovingLastTab = deleteTab.tabIdx === tabEntries.length - 1

    if (isRemovingFirstTab) {
      tabEntries.forEach(([tabId, tab]) => {
        if (tabId === deleteTabId) {
          tabsUpdate[tabId] = null
        } else {
          tabsUpdate[tabId] = { ...tab, tabIdx: tab.tabIdx - 1 }
        }
      })
    } else if (isRemovingLastTab) {
      tabsUpdate[deleteTabId] = null
    } else {
      tabEntries.forEach(([tabId, tab]) => {
        if (tabId === deleteTabId) {
          tabsUpdate[tabId] = null
        } else if (tab.tabIdx > deleteTab.tabIdx) {
          tabsUpdate[tabId] = { ...tab, tabIdx: tab.tabIdx - 1 }
        }
      })
    }

    await updateTabs({ coachOrgId, tabs: tabsUpdate })
    await saveTabContent({ coachOrgId, tabContent: null, tabId: deleteTabId })
    navigate('/layout')
    createAlert({ text: 'Tab deleted!', type: 'success' })
  }

  return (
    <div className='flex w-full rounded-t-lg overflow-x-auto md:overflow-visible'>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <SortableContext items={tabIds} strategy={horizontalListSortingStrategy}>
          {tabEntries.map(([tabId, tab], idx) => {
            const showTabSeparator = getShowTabSeparator({ tab, tabEntries, tabId, tabIdParam })
            return (
              <Sortable
                key={tabId}
                id={tabId}
                style={{
                  maxWidth: width < 768 ? '40%' : '20%',
                  width: width < 768 ? '40%' : `${(1 / tabEntries.length) * 100}%`,
                }}
                className='flex'
                draggingClasses='opacity-50 rounded-t-lg'
                onMouseEnter={() => togglePreceedingTabSeparatorVisibility({ tabEntries, idx, toggle: 'hide' })}
                onMouseLeave={() => togglePreceedingTabSeparatorVisibility({ tabEntries, idx, toggle: 'show' })}
                disabled={{
                  draggable: tabEntries.length === 1 ? true : false,
                  droppable: tabEntries.length === 1 ? true : false,
                }}
                withHandle={true}
              >
                <Tab
                  key={tabId}
                  tabId={tabId}
                  tab={tab}
                  showTabSeparator={showTabSeparator}
                  toggleSeparatorVisibility={(toggle) =>
                    togglePreceedingTabSeparatorVisibility({ tabEntries, idx, toggle })
                  }
                  handleDelete={() => handleDeleteTab({ deleteTabId: tabId, deleteTab: tab })}
                  isOnlyTab={tabEntries.length === 1}
                  coachOrgId={coachOrgId}
                />
              </Sortable>
            )
          })}
        </SortableContext>
        <DragOverlay
          zIndex={10}
          dropAnimation={dropAnimation}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        >
          {activeDragTab && (
            <TagDragOverlay
              activeDragTab={activeDragTab}
              isTabSelected={getIsTabSelected({ tab: activeDragTab.tab, tabId: activeDragTab.id, tabIdParam })}
              indexDragIsOver={indexDragIsOver}
            />
          )}
        </DragOverlay>
      </DndContext>
      {!tabCapExceeded && (
        <Tooltip content='Add tab' triggerClasses='self-center mx-1'>
          <button
            className='text-gray-500 p-[5px] rounded-full hover:bg-gray-300 transition-all'
            onClick={handleAddTab}
            data-testid='addTab'
          >
            <CgMathPlus className='w-5 h-5' />
          </button>
        </Tooltip>
      )}
    </div>
  )
}
