import React from 'react'
import tw from 'twin.macro'
import { format } from 'date-fns'

import { Button } from 'common/components/Button/Button'
import { selectButtonActiveStyles, selectButtonBaseStyles } from 'modules/Layout/components/AddCard/styles'

export default function SavedBlocksListItem({ block, selectedBlock, setSelectedBlock }) {
  return (
    <Button
      onClick={() => setSelectedBlock(block)}
      variant='secondary'
      size='md'
      css={[selectButtonBaseStyles, tw`mr-0`, block.id === selectedBlock?.id && selectButtonActiveStyles]}
    >
      <div className='flex-1'>
        <span className='flex'>{block.name || 'Block'}</span>
        <span className='flex text-xxs text-gray-400'>
          Saved at {format(block.savedAt, 'MMMM d')} at {format(block.savedAt, 'h:mm a')}
        </span>
      </div>
    </Button>
  )
}
