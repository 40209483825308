import React from 'react'
import { CgImage, CgMic } from 'react-icons/cg'
import { TbVideo } from 'react-icons/tb'

import { allMediaTypes } from 'modules/ExerciseLibrary/Filter/constants'

function MediaFilter({ setFilters, filters }) {
  const handleMediaChange = (e) => {
    setFilters({
      ...filters,
      media: {
        ...filters.media,
        [e.target.name]: !filters.media[e.target.name],
      },
    })
  }

  return (
    <div className='flex items-center ml-6'>
      {allMediaTypes.map((media) => (
        <div key={media} className='flex items-center ml-3 first:ml-0 last:ml-2'>
          <input
            onChange={handleMediaChange}
            name={media}
            id={media}
            type='checkbox'
            className='sr-only'
            aria-label={media}
          />
          <label className='cursor-pointer' htmlFor={media}>
            <MediaIcon media={media} active={filters?.media[media]} />
          </label>
        </div>
      ))}
    </div>
  )
}

const MediaIcons = {
  video: TbVideo,
  thumbnail: CgImage,
  audio: CgMic,
}

function MediaIcon({ media, active }) {
  const Icon = MediaIcons[media]
  return <Icon className={`inline-flex w-6 h-6 text-tGreen ${active ? 'text-tGreen' : 'text-tGray-light'}`} />
}

export default MediaFilter
