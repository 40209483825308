import React, { memo } from 'react'
import tw, { css } from 'twin.macro'
import * as Popover from '@radix-ui/react-popover'
import { HexColorInput, HexColorPicker } from 'react-colorful'

import { inputBase } from 'common/components/Input/Input'

const Label = tw.label`inline-flex cursor-pointer font-semibold text-tBlack`
const colorPopoverStyles = css`
  .react-colorful {
    ${tw`w-32 h-32`}
  }

  .react-colorful__hue {
    ${tw`h-3`}
  }

  .react-colorful__hue-pointer,
  .react-colorful__saturation-pointer {
    ${tw`w-4 h-4`}
  }
`

export const ColorInput = memo(({ watchColor, setValue, onChange, label, inputCSS, containerClasses }) => (
  <div className={`my-2 ${containerClasses ? containerClasses : ''}`}>
    {label && (
      <Label htmlFor='color' tw='mb-1'>
        {label}
      </Label>
    )}
    <div className='flex items-center'>
      <Popover.Root>
        <Popover.Trigger type='button' aria-label='Color Picker Button'>
          <div
            className='cursor-pointer w-7 h-7 rounded-md ring-1 ring-gray-300 ring-offset-2 hover:ring-tGreen'
            style={{ backgroundColor: watchColor }}
          />
        </Popover.Trigger>
        <Popover.Content css={[colorPopoverStyles, tw`mt-4 shadow-md rounded-xl`]} aria-label='Color Picker Content'>
          <HexColorPicker
            color={watchColor}
            onChange={(color) => {
              if (setValue) {
                setValue('color', color)
                return
              }
              onChange(color)
            }}
          />
        </Popover.Content>
      </Popover.Root>
      <HexColorInput
        id='color'
        name='color'
        type='text'
        color={watchColor}
        onChange={(color) => {
          if (setValue) {
            setValue('color', color)
            return
          }
          onChange(color)
        }}
        css={inputCSS ? inputCSS : [inputBase, tw`ml-4 w-24`]}
      />
    </div>
  </div>
))
