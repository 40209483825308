import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'
import React, { useState, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useAuth from 'modules/Auth/AuthContext'
import { getAuth, signOut } from 'firebase/auth'
import { Auth } from 'aws-amplify'
import { CgArrowRight, CgChevronLeft, CgChevronRight } from 'react-icons/cg'

import {
  useSetCoachNameMutation,
  useSetCoachOrgNameMutation,
  useSetCoachOrgPrimaryUIColorMutation,
} from 'modules/Account/accountApi'

import { createUID } from 'common/utils/createUID'
import { useAlert } from 'common/components/Alert/hooks/useAlert'
import { Button } from 'common/components/Button/Button'
import { useEventListener } from 'common/hooks/useEventListener'
import { questions } from './templates/newOrgQuestions'
import { Progress } from './components/Progress'
import { Question, transition, variants } from './components/Question'
import { initFirebaseData, initFirebaseCoach } from './utils/initFirebaseData'

function NewCoachOrgQuestionnaire() {
  const { createAlert } = useAlert()
  const navigate = useNavigate()
  const auth = getAuth()

  const { userId, userProfile, logout } = useAuth()
  const { current: coachOrgId } = useRef(createUID())

  const [setCoachOrgName] = useSetCoachOrgNameMutation()
  const [setCoachName] = useSetCoachNameMutation()
  const [setCoachOrgPrimaryUIColor] = useSetCoachOrgPrimaryUIColorMutation()

  const [questionStep, setQuestionStep] = useState(null)
  const [isStepForwards, setIsStepForwards] = useState(true)
  const [error, setError] = useState(null)
  const [isInitError, setIsInitError] = useState(false)
  const [loading, setLoading] = useState(false)

  const isFirstQuestion = questionStep === 0
  const isLastQuestion = questionStep === questions.length - 1
  const questStarted = questionStep !== null
  const questFinished = questionStep + 1 > questions.length
  const totalQuestions = questions.length

  const percentComplete = questStarted ? ((questionStep + 1) / totalQuestions) * 100 : 0

  const inputRef = useRef()

  const [answers, setAnswers] = useState({
    coachName: '',
    orgName: '',
    exVidOrientation: 'portrait',
    color: '#1EC7A9',
  })

  const handleBack = () => {
    setIsStepForwards(false)
    const prevStep = questionStep - 1
    setQuestionStep(prevStep)
    setError(null)
  }

  const handleNext = async () => {
    setIsStepForwards(true)
    const questionKey = questions[questionStep].questionKey
    const answerValue = answers[questionKey]
    const nextStep = questionStep + 1

    setAnswers({ ...answers, [questionKey]: answerValue.trim() })

    if (answerValue.trim().length === 0) {
      setError('Answer is required')
      inputRef?.current?.focus()
    } else if (answerValue.trim().length > 0 && isLastQuestion) {
      setLoading(true)
      const initFirebaseDataRes = await initFirebaseData({
        coachOrgId,
        userId,
        profile: userProfile,
        exVidOrientation: answers.exVidOrientation,
      })
      const setCoachOrgNameRes = await setCoachOrgName({ userId, coachOrgId, orgName: answers.orgName })
      const setCoachNameRes = await setCoachName({ userId, coachOrgId, coachName: answers.coachName })
      const setCoachOrgPrimaryUIColorRes = await setCoachOrgPrimaryUIColor({
        coachOrgId,
        primaryUIColor: answers.color,
      })
      setLoading(false)

      if (
        initFirebaseDataRes?.error ||
        setCoachOrgNameRes?.error ||
        setCoachNameRes?.error ||
        setCoachOrgPrimaryUIColorRes?.error
      ) {
        setIsInitError(true)
        createAlert({ text: 'Setup error occured', type: 'danger' })
        return
      }

      createAlert({ text: 'Setup complete!', type: 'success' })

      setQuestionStep(nextStep)
    } else if (answerValue.trim().length > 0) {
      setQuestionStep(nextStep)
    }
  }

  const handleFinish = async () => {
    setLoading(true)
    const initCoachRes = await initFirebaseCoach({ coachOrgId, userId, profile: userProfile })
    setLoading(false)
    if (initCoachRes?.error) {
      setIsInitError(true)
      createAlert({ text: 'Setup error occured', type: 'danger' })
      return
    }
    navigate('/')
  }

  const handleEnter = (e) => {
    if (loading) {
      return
    }

    if (e.key === 'Enter' && !questStarted) {
      setQuestionStep(0)
    } else if (e.key === 'Enter' && !questFinished) {
      handleNext()
    } else if (e.key === 'Enter' && questFinished) {
      handleFinish()
    }
  }

  useEventListener('keydown', handleEnter)

  if (isInitError) {
    return (
      <div className='flex min-h-screen w-full bg-offWhite px-16 py-12'>
        <div className='max-w-xl m-auto w-full'>
          <h1 className='font-bold text-xl'>
            Sorry, an error occurred. Please try refreshing or if it continues, email support@trybe.do.
          </h1>
          <div className='flex items-center mt-6'>
            <Button size='md' onClick={() => window.location.reload()} data-testid='nextBtn'>
              Refresh
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col min-h-screen w-full bg-offWhite px-16 py-12'>
      <Progress percentComplete={percentComplete} />
      <AnimatePresence exitBeforeEnter custom={isStepForwards}>
        {!questStarted && (
          <motion.div
            variants={variants}
            custom={isStepForwards}
            initial='enter'
            animate='center'
            exit='exit'
            transition={transition}
            className='max-w-xl m-auto w-full'
          >
            <h1 className='font-bold text-xl mb-6'>To get your app started, please answer a few short questions.</h1>
            <div className='flex flex-col'>
              <div className='flex items-center mt-6'>
                <Button size='md' onClick={() => setQuestionStep(0)} data-testid='nextBtn'>
                  Start <CgArrowRight className='ml-2 w-5 h-5' />
                </Button>
                <div className='ml-2 text-sm text-gray-400'>
                  or press <strong>Enter</strong>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {questStarted &&
          questions.map((questionData, idx) =>
            idx === questionStep ? (
              <Question
                key={questionData.questionKey}
                questionData={questionData}
                answers={answers}
                setAnswers={setAnswers}
                error={error}
                setError={setError}
                inputRef={inputRef}
                handleNext={handleNext}
                loading={loading}
                isLastQuestion={isLastQuestion}
                isStepForwards={isStepForwards}
              />
            ) : null
          )}
        {questFinished && (
          <motion.div
            variants={variants}
            custom={isStepForwards}
            initial='enter'
            animate='center'
            exit='exit'
            transition={transition}
            className='max-w-xl m-auto w-full'
          >
            <h1 className='font-bold text-xl mb-6'>Good job! Your initial setup is completed.</h1>
            <div className='flex flex-col'>
              <div className='flex items-center mt-6'>
                <Button size='md' onClick={() => handleFinish()} data-testid='nextBtn'>
                  Go to dashboard <CgArrowRight className='ml-2 w-5 h-5' />
                </Button>
                <div className='ml-2 text-sm text-gray-400'>
                  or press <strong>Enter</strong>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {questStarted && !questFinished && (
        <div className='absolute -translate-x-1/2 left-1/2 bottom-6 flex items-center'>
          <Button size='sm' css={tw`mr-2`} disabled={isFirstQuestion} onClick={handleBack} data-testid='prevQuestBtn'>
            <CgChevronLeft className='w-5 h-5 mr-1' />
          </Button>
          <Button size='sm' disabled={isLastQuestion} onClick={handleNext} data-testid='nextQuestBtn'>
            <CgChevronRight className='w-5 h-5 mr-1' />
          </Button>
        </div>
      )}
      <div className='absolute right-6 bottom-6'>
        <Button
          variant='tertiary'
          size='md'
          onClick={async () => {
            await signOut(auth)
            await Auth.signOut()
            logout({ returnTo: window.location.origin })
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}

export default NewCoachOrgQuestionnaire
